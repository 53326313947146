import {
  CHANGE_DISCLAIMER,
  CHANGE_DISCLAIMERS_PAGE,
  CHANGE_DISCLAIMERS_PAGER,
  CHANGE_DISCLAIMER_FAIL,
  CHANGE_DISCLAIMER_SUCCESS,
  CLEAR_DISCLAIMER,
  DELETE_DISCLAIMER,
  DELETE_DISCLAIMER_FAIL,
  DELETE_DISCLAIMER_SUCCESS,
  GET_DISCLAIMER,
  GET_DISCLAIMERS,
  GET_DISCLAIMERS_FAIL,
  GET_DISCLAIMERS_SUCCESS,
  GET_DISCLAIMER_FAIL,
  GET_DISCLAIMER_SUCCESS
} from 'constants/index';

const initial = {
  data: [],
  loading: false,
  error: '',
  message: '',
  page: 1,
  perPage: 10,
  count: 1,
  disclaimer: {}
};

const disclaimers = (
  state = initial,
  action: { type: string; payload: any }
) => {
  switch (action.type) {
    case GET_DISCLAIMERS:
      return { ...state, loading: true };
    case GET_DISCLAIMERS_SUCCESS:
      return {
        ...state,
        data: action.payload.data,
        count: action.payload.meta_attributes
          ? action.payload.meta_attributes.total_count
          : 1,
        loading: false
      };
    case GET_DISCLAIMERS_FAIL:
      return { ...state, error: action.payload, loading: false };
    case 'CLEAR_ERROR':
      return { ...state, error: '', message: '' };
    case DELETE_DISCLAIMER:
      return { ...state, loading: true };
    case DELETE_DISCLAIMER_SUCCESS:
      return { ...state, message: action.payload.message, loading: false };
    case DELETE_DISCLAIMER_FAIL:
      return { ...state, error: action.payload, loading: false };
    case CHANGE_DISCLAIMER:
      return { ...state, loading: true };
    case CHANGE_DISCLAIMER_SUCCESS:
      return { ...state, message: action.payload.message, loading: false };
    case CHANGE_DISCLAIMER_FAIL:
      return { ...state, error: action.payload, loading: false };
    case GET_DISCLAIMER:
      return { ...state, loading: true };
    case GET_DISCLAIMER_SUCCESS:
      return { ...state, disclaimer: action.payload.data, loading: false };
    case GET_DISCLAIMER_FAIL:
      return { ...state, error: action.payload, loading: false };
    case CHANGE_DISCLAIMERS_PAGER:
      return { ...state, perPage: action.payload };
    case CHANGE_DISCLAIMERS_PAGE:
      return { ...state, page: action.payload };
    case CLEAR_DISCLAIMER:
      return { ...state, disclaimer: {} };
    default:
      return state;
  }
};

export default disclaimers;
