import {
  GET_SUBSCRIPTIONS,
  GET_SUBSCRIPTIONS_FAIL,
  GET_SUBSCRIPTIONS_SUCCESS,
  CHANGE_SUBSCRIPTIONS_PAGER,
  CHANGE_SUBSCRIPTIONS_PAGE,
  CLEAR_SUBSCRIPTION
} from '../constants';

const initial = {
  data: [],
  loading: false,
  error: '',
  message: '',
  page: 1,
  perPage: 10,
  count: 1,
  subscription: {}
};

const subscriptions = (
  state = initial,
  action: { type: string; payload: any }
) => {
  switch (action.type) {
    case GET_SUBSCRIPTIONS:
      return { ...state, loading: true };
    case GET_SUBSCRIPTIONS_SUCCESS:
      return {
        ...state,
        data: action.payload.data,
        count: action.payload.meta_attributes
          ? action.payload.meta_attributes.total_count
          : 1,
        loading: false
      };
    case GET_SUBSCRIPTIONS_FAIL:
      return { ...state, error: action.payload, loading: false };
    case 'CLEAR_ERROR':
      return { ...state, error: '', message: '' };
    case CHANGE_SUBSCRIPTIONS_PAGER:
      return { ...state, perPage: action.payload };
    case CHANGE_SUBSCRIPTIONS_PAGE:
      return { ...state, page: action.payload };
    case CLEAR_SUBSCRIPTION:
      return { ...state, subscription: {} };
    default:
      return state;
  }
};

export default subscriptions;
