export const CREATE_SUBSCRIPTIONS = 'CREATE_SUBSCRIPTIONS';
export const CREATE_SUBSCRIPTIONS_SUCCESS = 'CREATE_SUBSCRIPTIONS_SUCCESS';
export const CREATE_SUBSCRIPTIONS_FAIL = 'CREATE_SUBSCRIPTIONS_FAIL';
export const GET_CARDS = 'GET_CARDS';
export const GET_CARDS_SUCCESS = 'GET_CARDS_SUCCESS';
export const GET_CARDS_FAIL = 'GET_CARDS_FAIL';
export const DELETE_CARD = 'DELETE_CARD';
export const DELETE_CARD_SUCCESS = 'DELETE_CARD_SUCCESS';
export const DELETE_CARD_FAIL = 'DELETE_CARD_FAIL';
export const GET_SUBSCRIPTION = 'GET_SUBSCRIPTION';
export const GET_SUBSCRIPTION_SUCCESS = 'GET_SUBSCRIPTION_SUCCESS';
export const GET_SUBSCRIPTION_FAIL = 'GET_SUBSCRIPTION_FAIL';
export const UPDATE_PLAN = 'UPDATE_PLAN';
export const UPDATE_PLAN_SUCCESS = 'UPDATE_PLAN_SUCCESS';
export const UPDATE_PLAN_FAIL = 'UPDATE_PLAN_FAIL';
export const CREATE_CARD = 'CREATE_CARD';
export const CREATE_CARD_SUCCESS = 'CREATE_CARD_SUCCESS';
export const CREATE_CARD_FAIL = 'CREATE_CARD_FAIL';
export const CANCEL_SUBSCRIPTION = 'CANCEL_SUBSCRIPTION';
export const CANCEL_SUBSCRIPTION_SUCCESS = 'CANCEL_SUBSCRIPTION_SUCCESS';
export const CANCEL_SUBSCRIPTION_FAIL = 'CANCEL_SUBSCRIPTION_FAIL';
