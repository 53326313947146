import { FORM_EDIT_DATA } from 'actions';
import { apiService } from 'middleware/api';
import { Status } from '../../types';
import {
  DELETE_TASKS_STATUS,
  GET_TASK_STATUSES,
  UPDATE_TASKS_STATUS
} from '../constants';

export const onGetTaskStatuses = (page = 1, perPage = 10, keyword = '') => {
  const endPoint = `task_statuses?page=${page}&per_page=${perPage}&keyword=${keyword}`;
  const method = 'get';
  return apiService(endPoint, method, GET_TASK_STATUSES);
};

export const onGetSingleTaskStatus = (id: string) => {
  const endPoint = `task_statuses/${id}`;
  const method = 'get';
  return apiService(endPoint, method, FORM_EDIT_DATA);
};

export const onChangeStatus = (id: number, data: Status) => {
  const endPoint = `task_statuses/${id}`;
  const method = 'put';
  return apiService(endPoint, method, UPDATE_TASKS_STATUS, data);
};

export const onDeleteTaskStatus = (id: string) => {
  const endPoint = `task_statuses/${id}`;
  const method = 'delete';
  return apiService(endPoint, method, DELETE_TASKS_STATUS);
};
