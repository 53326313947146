import React from 'react';
import { Button, makeStyles, Popover, Typography } from '@material-ui/core';
import { MouseNChangeEvent } from './types';

const PopConfirmation = ({
  onClickHandler,
  anchorEl,
  setAnchorEl,
  title = 'Are you sure?',
  okText = 'Yes',
  cancelText = 'No'
}: PopConfirmationProps) => {
  const classes = useStyles();

  const confirmHandler = (e: MouseNChangeEvent) => {
    e.stopPropagation();
    anchorElToggle(e);
    onClickHandler();
  };

  const anchorElToggle = (e: MouseNChangeEvent) => {
    e.stopPropagation();
    setAnchorEl(null);
  };

  return (
    <Popover
      className={classes.pop}
      open={Boolean(anchorEl)}
      anchorEl={anchorEl}
      onClose={anchorElToggle}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      transformOrigin={{ vertical: 'top', horizontal: 'center' }}
    >
      <>
        <Typography className={classes.typography}>{title}</Typography>
        <div className={classes.buttons}>
          <Button className={classes.yesBtn} onClick={confirmHandler}>
            {okText}
          </Button>
          <Button onClick={anchorElToggle} color='primary'>
            {cancelText}
          </Button>
        </div>
      </>
    </Popover>
  );
};

export default React.memo(PopConfirmation);

const useStyles = makeStyles(theme => ({
  yesBtn: { color: theme.palette.error.dark, cursor: 'pointer' },
  typography: { padding: '10px 20px', fontSize: 16, maxWidth: 300 },
  pop: { textAlign: 'center' },
  buttons: { marginBottom: 5 }
}));

interface PopConfirmationProps {
  onClickHandler: () => void;
  anchorEl: null;
  setAnchorEl: (anchorEl: null) => void;
  title?: string;
  okText?: string;
  cancelText?: string;
}
