import {
  GET_QUOTES,
  DELETE_QUOTE,
  CHANGE_QUOTES_PAGER,
  CHANGE_QUOTES_PAGE,
  CHANGE_QUOTE_STATUS,
  GET_QUOTE,
  CLEAR_QUOTE
} from 'constants/index';
import { FORM_EDIT_DATA } from 'actions';
import { apiService } from 'middleware/api';
import { QuoteStatus } from '../../types/index';
export const onGetQuotes = (
  page = 1,
  perPage = 10,
  keyword = '',
  companyId = '',
  status = ''
) => {
  const endPoint = `quotes.json?page=${page}&per_page=${perPage}&keyword=${keyword}&company_id=${companyId}&status=${status}`;
  const method = 'get';
  return apiService(endPoint, method, GET_QUOTES);
};

export const onGetQuote = (id: number) => {
  const endPoint = `quotes/${id}.json`;
  const method = 'get';
  return apiService(endPoint, method, FORM_EDIT_DATA);
};

export const onDeleteQuote = (id: number) => {
  const endPoint = `quotes/${id}.json`;
  const method = 'delete';
  return apiService(endPoint, method, DELETE_QUOTE);
};
export const onChangeQuoteStatus = (
  id: number,
  data: { status: QuoteStatus }
) => {
  const endPoint = `quotes/${id}`;
  const method = 'put';
  return apiService(endPoint, method, CHANGE_QUOTE_STATUS, data);
};

export const onChangeQuotesPerPage = (perPage: number) => ({
  type: CHANGE_QUOTES_PAGER,
  payload: perPage
});

export const onChangeQuotesPage = (page: number) => ({
  type: CHANGE_QUOTES_PAGE,
  payload: page
});

export const onGetQuoteDetails = (id: number) => {
  const endPoint = `quotes/${id}.json`;
  const method = 'get';
  return apiService(endPoint, method, GET_QUOTE);
};

export const onClearQuote = () => ({ type: CLEAR_QUOTE });
