import React from 'react';
import { Route, Redirect, RouteProps } from 'react-router-dom';
import { connect } from 'react-redux';

const PrivateRoute = ({
  component,
  render,
  auth,
  ...rest
}: PrivateRouteProps) => {
  const Component = component ?? render;
  return (
    <Route
      {...rest}
      render={props =>
        auth.isAuth ? (
          <Component {...rest} auth={auth} />
        ) : (
          <Redirect to={{ pathname: '/', state: { from: props.location } }} />
        )
      }
    />
  );
};

const mapStateToProps = ({ auth }: Auth) => ({ auth });

export default connect(mapStateToProps, {})(PrivateRoute);

interface Auth {
  auth: { isAuth: boolean };
}

interface PrivateRouteProps extends Auth, RouteProps {
  component?: any;
}
