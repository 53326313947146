import React, { useEffect, ReactElement } from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';

const ScrollToTop = ({ location, children }: ScrollerProps) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);
  return <>{children}</>;
};
export default withRouter(ScrollToTop);

interface ScrollerProps extends RouteComponentProps {
  children: ReactElement;
}
