import {
  FormControl,
  FormHelperText,
  Grid,
  TextField
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { onGetUsers } from 'actions';
import AsyncSearch from 'app/Common/AsyncSearch';
import CustomAlertMsg from 'app/Common/CustomAlertMsg';
import CustomDatePicker from 'app/Common/CustomDatePicker';
import CustomDialog from 'app/Common/CustomDialog';
import FormButtons from 'app/Common/FormButtons';
import { useForm } from 'app/Common/Hooks/useForm';
import InputField from 'app/Common/InputFields';
import RichTextEditor from 'app/Common/RichTextEditor';
import { Users } from 'app/Dashboard/Admin-Settings/Users/types';
import { onGetTaskServices } from 'app/Dashboard/Setup/TaskServices/redux/actions';
import { Services } from 'app/Dashboard/Setup/TaskServices/types';
import { taskFields } from 'assets/static/forms/taskFields';
import { useFormik } from 'formik';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
  dateHH_MMFormat,
  dateYMDFormat,
  getTZ
} from 'utils/common/dateYMDFormat';
import { taskValidation } from 'validate/taskValidation';
import { Task, TasksFormProps } from '../types';

const TasksForm = ({
  form,
  closeForm,
  data,
  projectId,
  currentTaskRow,
  StatusList,
  StatusListLoading
}: TasksFormProps) => {
  const task = data as Task;
  const formHook = useForm();
  const dispatch = useDispatch();
  const [userId, setUserId] = useState('');
  const [taskServiceId, setTaskServiceId] = useState('');
  const [statusId, setStatusId] = useState<number>();

  const [description, setDescription] = useState(task.description ?? '');
  const [error, setError] = useState<null | string>(null);
  const [serviceValidationError, setServiceValidationError] = useState<
    null | string
  >(null);
  const [dueDate, setDueDate] = useState<Date | null | string>(
    dateYMDFormat(task.due_date ? new Date(task.due_date) : new Date())
  );

  const {
    users: { data: users, loading: userLoading },
    taskServices: { data: services, loading: servicesLoading }
  } = useSelector((state: { users: Users; taskServices: Services }) => state);
  const { id } = task;
  const { companyId } = useParams<{
    companyId: string;
  }>();
  const formik = useFormik({
    initialValues: {
      title: task.title ?? '',
      estimated_hour: task.estimated_hour ?? 0
    },
    validationSchema: taskValidation,
    onSubmit: values => {
      setDescription(description === '<p><br></p>' ? '' : description);
      if (!(task?.task_service_id || taskServiceId))
        return setServiceValidationError('Service is required');
      setServiceValidationError(null);
      if (!description.length || description === '<p><br></p>')
        return setError('Description is required');
      setError(null);

      const data = {
        ...values,
        description,
        project_id:
          task?.project?.id ?? currentTaskRow?.project?.id ?? projectId,
        company_id:
          task?.company?.id ?? currentTaskRow?.company?.id ?? companyId,
        assignee_id: (userId || task?.assignee?.id) ?? '',
        due_date: dueDate
          ? `${dueDate} ${dateHH_MMFormat(new Date(), true)} ${getTZ(
              new Date()
            )}`
          : '',
        parent_task_id: task?.parent_task_id ?? currentTaskRow?.id ?? '',
        child_task_id:
          task?.child_task_id ?? currentTaskRow?.child_task_id ?? '',
        task_service_id: (taskServiceId || task?.task_service_id) ?? '',
        task_status_id: statusId
      };
      id
        ? formHook.onSubmitPut(`tasks/${id}.json`, data)
        : formHook.onSubmitPost(`tasks`, data);
    }
  });
  const closeAlertHandle = () => {
    if (formHook.status === 'error') return;
    closeForm(true);
  };
  const updatedUsers = users.map(user => {
    user.name = `${user.first_name} ${user.last_name}`;
    return user;
  });
  const currentAssignee = task.assignee?.first_name
    ? `${task.assignee?.first_name ?? ''} ${task.assignee?.last_name ?? ''}`
    : task.assignee?.first_name;
  const asyncFields = [
    {
      label: !userId ? currentAssignee ?? 'Select Assignee' : 'Select Assignee',
      data: updatedUsers ?? [],
      loading: userLoading,
      getter: onGetUsers,
      setter: setUserId,
      error: null
    },
    {
      label: !taskServiceId
        ? task.task_service?.name ?? '* Select Service'
        : '',
      data: services,
      loading: servicesLoading,
      getter: onGetTaskServices,
      setter: setTaskServiceId,
      error: serviceValidationError
    }
  ];

  return (
    <>
      <CustomAlertMsg
        type={formHook.status}
        message={formHook.message}
        onClose={closeAlertHandle}
      />
      <CustomDialog
        open={form}
        closeHandle={() => {
          if (formHook.loading) return;
          closeForm(false);
        }}
        loading={formHook.loading}
        heading={id ? 'Update Task' : 'Create New Task'}
        footer='hide'
      >
        <Grid
          container
          spacing={2}
          component='form'
          onSubmit={formik.handleSubmit}
          className='mt10'
        >
          {taskFields.map(({ value, label, type }, index) => (
            <InputField
              formik={formik}
              value={value}
              label={label}
              key={value}
              type={type}
              autoFocus={index === 0}
              fullWidth={true}
            />
          ))}
          {asyncFields.map(
            ({ label, data, loading, getter, setter, error }, index) => (
              <Grid item sm={6} key={index}>
                <FormControl fullWidth>
                  <AsyncSearch
                    data={data}
                    getHandle={(page, perPage, keyword) =>
                      dispatch(getter(page, perPage, keyword))
                    }
                    selectHandle={setter}
                    loading={loading}
                    label={label}
                    disabled={formHook.loading}
                  />
                  <FormHelperText error>{error} </FormHelperText>
                </FormControl>
              </Grid>
            )
          )}
          <CustomDatePicker
            title='Due Date'
            value={dueDate}
            setter={setDueDate}
            clearer={e => {
              e.stopPropagation();
              setDueDate(null);
            }}
            withTZ={false}
          />
          <Grid item sm={6}>
            <Autocomplete
              disabled={false}
              id='taskStatus'
              defaultValue={data.task_status}
              options={StatusList}
              getOptionLabel={option =>
                StatusListLoading ? 'Loading...' : option.name
              }
              onChange={(e, v, r) => setStatusId(v?.id as number)}
              renderInput={params => (
                <TextField
                  {...params}
                  label={'Select Status'}
                  variant='standard'
                />
              )}
            />
          </Grid>

          <RichTextEditor
            description={description}
            setDescription={setDescription}
            title='* Description'
          />
          <FormHelperText error className='ml10'>
            {error}
          </FormHelperText>
          <FormButtons
            id={id}
            loading={formHook.loading}
            onCancel={() => closeForm(false)}
          />
        </Grid>
      </CustomDialog>
    </>
  );
};

export default React.memo(TasksForm);
