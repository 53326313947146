import {
  Checkbox,
  Collapse,
  IconButton,
  makeStyles,
  TableBody,
  TableCell,
  TableRow
} from '@material-ui/core';
import { KeyboardArrowDown, KeyboardArrowUp } from '@material-ui/icons';
import React from 'react';
import { dateYMDFormat } from 'utils/common/dateYMDFormat';
import { objectFieldIterator } from '../../../utils/common/objectFieldIterator';
import { DynamicObject, MouseNChangeEvent } from '../types';
import { CustomTableBodyProps } from '../types/customTable';

const CustomTableBody = ({
  rows,
  selectedRows = [],
  isSelection,
  loading,
  columns,
  actions,
  changeSelectedRows,
  rowClickHandle,
  collaspe,
  setCollaspe,
  collaspeView
}: CustomTableBodyProps) => {
  const classes = useStyles();
  const onRow = (e: MouseNChangeEvent, row: { id: number }) => {
    if (e.target.type === 'checkbox') {
      if (!changeSelectedRows) return;
      const newSelected: Array<DynamicObject> = [...selectedRows];
      if (e.target.checked) {
        newSelected.push(row);
      } else {
        newSelected.forEach((val, index) => {
          if (val.id === row.id) newSelected.splice(index, 1);
        });
      }
      changeSelectedRows(newSelected);
    } else !loading && e.target.id && rowClickHandle && rowClickHandle(e, row);
  };

  return (
    <TableBody>
      {rows.map((row, rowIndex) => {
        const rowId = `table-checkbox-${rowIndex}`;
        const isItemSelected = !!selectedRows.filter(
          selected => selected.id === row.id
        ).length;
        return (
          <>
            <TableRow
              key={row.id}
              className={rowClickHandle ? classes.tableRow : ''}
              id={row.id?.toString()}
              hover
              onClick={e => onRow(e, row)}
              role='checkbox'
              aria-checked={isItemSelected}
              selected={isItemSelected}>
              {isSelection && (
                <TableCell padding='checkbox'>
                  <Checkbox
                    checked={isItemSelected}
                    disabled={loading}
                    inputProps={{ 'aria-labelledby': rowId }}
                  />
                </TableCell>
              )}
              {setCollaspe && (
                <TableCell>
                  <IconButton
                    size='small'
                    onClick={() =>
                      setCollaspe(row.id === collaspe ? undefined : row.id)
                    }>
                    {row.id === collaspe ? (
                      <KeyboardArrowUp />
                    ) : (
                      <KeyboardArrowDown />
                    )}
                  </IconButton>
                </TableCell>
              )}
              {columns.map(column => (
                <TableCell id={rowId} key={column} className='space tableCell'>
                  {column.includes('+') || column.includes('.') ? (
                    column
                      .split('+')
                      .map((col: string) => objectFieldIterator(col, row) + ' ')
                  ) : column.includes('date:') ? (
                    dateYMDFormat(row[column.replace(/date:/g, '')])
                  ) : row[column]?.length > 40 ? (
                    <div className='cellInner'>{row[column]}</div>
                  ) : (
                    row[column]
                  )}
                </TableCell>
              ))}
              {actions && (
                <TableCell
                  align='center'
                  id={row.id.toString()}
                  className={classes.actionsColumn}>
                  <div className={classes.actionBox}>
                    {!row.disabled && actions(row)}
                  </div>
                </TableCell>
              )}
            </TableRow>
            {collaspe === row.id && collaspeView && (
              <TableRow>
                <TableCell
                  className={classes.collaspeCell}
                  colSpan={columns.length + 1 + ~~!!actions + ~~!!isSelection}>
                  <Collapse
                    in={collaspe === row.id}
                    timeout='auto'
                    unmountOnExit>
                    {collaspeView(row)}
                  </Collapse>
                </TableCell>
              </TableRow>
            )}
          </>
        );
      })}
    </TableBody>
  );
};

export default CustomTableBody;

const useStyles = makeStyles(({ palette }) => ({
  tableRow: { cursor: 'pointer' },
  actionsColumn: { minWidth: 85 },
  collaspeCell: {
    paddingLeft: 0,
    paddingRight: '0 !important',
    paddingBottom: 16
  },
  actionBox: { display: 'flex', justifyContent: 'center' }
}));
