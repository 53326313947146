import React from 'react';
import {
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from '@material-ui/core';

import { WorkLogsListProps } from '../types';
import Heading from 'app/Common/Heading';

const WorkLogsList = ({ data }: WorkLogsListProps) => {
  const classes = useStyles();
  const head = Array.from(
    new Set(
      Object.values(data)
        .map(v => Object.keys(v))
        .reduce((a, v) => [...a, ...v], [])
    )
  );

  const body = Object.values(data);
  if (!Object.values(data).length) return <Heading>No Worklog Found</Heading>;
  return (
    <>
      <TableContainer component={Paper}>
        <Table style={{ width: 'max-content' }}>
          <TableHead>
            <TableRow>
              {head.map(head => (
                <TableCell className={classes.border} key={head}>
                  <strong className={classes.colHead}>{head}</strong>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {body.map((row, index) => (
              <TableRow key={index}>
                {head.map((item, index) => (
                  <TableCell
                    className={classes.border}
                    key={index}
                    component='th'
                    scope='row'
                  >
                    <div className={classes.column}>
                      {typeof row[item] === 'string'
                        ? row[item]
                        : row[item]?.duration}
                    </div>
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default React.memo(WorkLogsList);

const useStyles = makeStyles(({ palette: { info } }) => ({
  border: { border: '1px solid #e2e2e2' },
  column: { display: 'flex', justifyContent: 'space-between' },
  colHead: { textTransform: 'capitalize' },
  icon: { color: info.dark, cursor: 'pointer' }
}));
