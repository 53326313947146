import React from 'react';

import CustomTable from 'app/Common/CustomTable';
import { TemplateTasksListProps } from '../types';
import ListDeleteButton from 'app/Common/ListDeleteButton';
import ListEditButton from 'app/Common/ListEditButton';

const TemplateTasksList = ({
  data,
  loading,
  onDeleteTemplateTask,
  page,
  perPage,
  count,
  changePerPageHandle,
  changePage
}: TemplateTasksListProps) => {
  return (
    <CustomTable
      rows={data}
      headItems={['Title', 'Service']}
      columns={['title', 'service']}
      loading={loading}
      perPage={perPage}
      page={page}
      count={count}
      isScroller
      changePageHandle={(e, page) => changePage(page)}
      changePerPageHandle={perPage => {
        changePage(1);
        changePerPageHandle(perPage);
      }}
      actions={() => [
        <ListEditButton loading={loading} name='projectTemplate' />,
        <ListDeleteButton
          loading={loading}
          onClickHandler={onDeleteTemplateTask}
        />
      ]}
    />
  );
};

export default React.memo(TemplateTasksList);
