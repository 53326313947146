import { setCurrentUser } from 'app/Auth/redux/actions';

export const authenticateUser = (store: { dispatch: Function }) => {
  if (localStorage.token && localStorage.user) {
    const token = window.localStorage.getItem('token') || '';
    const user = JSON.parse(window.localStorage.getItem('user') || '{}');
    const authData = { data: JSON.parse(token), user: user };
    store.dispatch(setCurrentUser(authData));
  }
};
