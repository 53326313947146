import React from 'react';
import { Button, makeStyles, Snackbar } from '@material-ui/core';
import { Alert } from '@material-ui/lab';

const CustomNotification = ({
  type,
  duration,
  onOk,
  onCancel,
  cancelText,
  okText,
  description
}: CustomNotificationProps) => {
  const classes = useStyles();
  return (
    <Snackbar
      autoHideDuration={duration}
      open={true}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
    >
      <Alert
        className={classes.alert}
        severity={type}
        action={
          <>
            <Button
              color='primary'
              size='small'
              variant='contained'
              onClick={onOk}
            >
              {okText}
            </Button>

            <Button
              onClick={onCancel}
              size='small'
              className={classes.cancelBtn}
            >
              {cancelText}
            </Button>
          </>
        }
      >
        {description}
      </Alert>
    </Snackbar>
  );
};
export default React.memo(CustomNotification);

const useStyles = makeStyles(() => ({
  alert: {
    padding: 20,
    boxShadow: `0 1px 5px 0 rgba(0,0,0,.2), 0 2px 2px 0 rgba(0,0,0,.14), 0 3px 1px -2px rgba(0,0,0,.12)`
  },
  cancelBtn: {
    background: 'darkred',
    color: '#fff',
    marginLeft: 5,
    '&:hover': { background: 'darkred' }
  }
}));

interface CustomNotificationProps {
  type: 'info' | 'error' | 'success';
  duration?: number;
  onOk: () => void;
  onCancel: () => void;
  cancelText: string;
  okText: string;
  description: string;
}
