import React, { useState } from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  Typography
} from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';

import { useStyles } from '../../styles';
import { AppliedTaxesType, AppliedTaxesProps } from '../../types';

const AppliedTaxes = ({ quoteTaxes }: AppliedTaxesProps) => {
  const taxes = quoteTaxes as AppliedTaxesType;
  const classes = useStyles();
  const [expanded, setExpanded] = useState(true);
  if (!taxes.length) return null;
  return (
    <Grid>
      <Accordion
        className='mt5'
        expanded={expanded}
        onChange={(e, expanded) => setExpanded(expanded)}
      >
        <AccordionSummary expandIcon={<ExpandMore />}>
          <Typography className={classes.heading}>Applied Taxes</Typography>
        </AccordionSummary>
        <AccordionDetails className={classes.taxList}>
          {taxes.map(tax => (
            <Typography key={tax.id.toString()} className='pb5'>
              {tax.name}
              <strong className='rit'>{tax.rate}%</strong>
            </Typography>
          ))}
        </AccordionDetails>
      </Accordion>
    </Grid>
  );
};

export default React.memo(AppliedTaxes);
