import { useEditForm } from 'app/Common/Hooks/useEditForm';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import CustomAlertMsg from 'app/Common/CustomAlertMsg';
import { ExpenseCategoriesProps } from '../types';
import TopBar from './Topbar';
import List from './List';
import Form from './Form';
import {
  onChangeCategoriesPage,
  onChangeCategoriesPerPage,
  onDeleteExpenseCategory,
  onGetExpenseCategories,
  onGetExpenseCategory
} from '../redux/actions';

const ExpenseCategory = () => {
  const dispatch = useDispatch();
  const { data, loading, error, message, page, perPage, count } = useSelector(
    ({ expenseCategories }: ExpenseCategoriesProps) => expenseCategories
  );
  const { id, formData, clearFormData, editLoading } = useEditForm(
    'expenseCategory',
    onGetExpenseCategory,
    () => setForm(true)
  );
  const [form, setForm] = useState(false);
  const [searchText, setSearchText] = useState('');

  const loadData = useCallback(() => {
    dispatch(onGetExpenseCategories(page, perPage, searchText));
  }, [dispatch, perPage, page, searchText]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  const closeFormHandle = (loadList: boolean) => {
    loadList && loadData();
    id && clearFormData();
    setForm(false);
  };

  const handleSearch = (text: string) => {
    setSearchText(text);
    dispatch(onChangeCategoriesPage(1));
  };

  return (
    <>
      <CustomAlertMsg
        type={error.length ? 'error' : 'success'}
        message={error || message}
        onClose={() => {
          message.length && loadData();
        }}
      />
      <TopBar
        loading={loading || (editLoading && !form)}
        setForm={setForm}
        setSearchText={handleSearch}
      />
      <List
        data={data}
        loading={loading || (editLoading && !form)}
        page={page}
        perPage={perPage}
        count={count}
        changePage={page => dispatch(onChangeCategoriesPage(page))}
        changePerPageHandle={perPage => {
          dispatch(onChangeCategoriesPage(1));
          dispatch(onChangeCategoriesPerPage(perPage));
        }}
        onDeleteCategory={id => dispatch(onDeleteExpenseCategory(id))}
      />
      {form && (
        <Form
          form={form}
          closeForm={closeFormHandle}
          expenseCategory={formData}
          onGetExpenseCategory={id => dispatch(onGetExpenseCategory(id))}
          loading={loading}
        />
      )}
    </>
  );
};

export default ExpenseCategory;
