import {
  GET_PROJECT_STATUSES,
  DELETE_PROJECT_STATUS,
  CHANGE_PROJECT_STATUSES_PAGER,
  CHANGE_PROJECT_STATUSES_PAGE
} from 'constants/index';
import { apiService } from 'middleware/api';
import { FORM_EDIT_DATA } from 'actions';

export const onGetProjectStatuses = (page = 1, perPage = 10, keyword = '') => {
  const endPoint = `project_statuses.json?page=${page}&per_page=${perPage}&keyword=${keyword}`;
  const method = 'get';
  return apiService(endPoint, method, GET_PROJECT_STATUSES);
};

export const onGetProjectStatus = (key: string) => {
  const endPoint = `project_statuses/${key}.json`;
  const method = 'get';
  return apiService(endPoint, method, FORM_EDIT_DATA);
};

export const onDeleteProjectStatus = (key: string) => {
  const endPoint = `project_statuses/${key}.json`;
  const method = 'delete';
  return apiService(endPoint, method, DELETE_PROJECT_STATUS);
};

export const onChangeProjectStatusesPerPage = (perPage: number) => ({
  type: CHANGE_PROJECT_STATUSES_PAGER,
  payload: perPage
});
export const onChangeProjectStatusesPage = (page: number) => ({
  type: CHANGE_PROJECT_STATUSES_PAGE,
  payload: page
});
