import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  Button,
  ClickAwayListener,
  Paper,
  Popper,
  Grow,
  MenuItem,
  MenuList,
  Avatar,
  Divider
} from '@material-ui/core';

import { logout } from 'actions';
import { useStyles } from '../../styles/topbar';
import { stringToColor } from 'utils/common/stringToColor';

const UserMenu = ({ userName = '', role = 'sales_rep' }) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef<HTMLButtonElement>(null);
  const dispatch = useDispatch();
  const { push } = useHistory();
  const handleToggle = () => setOpen(prevOpen => !prevOpen);
  const handleClose = (event: React.MouseEvent<EventTarget>, link?: string) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    )
      return;

    setOpen(false);
    link && push(link);
  };

  function handleListKeyDown(event: React.KeyboardEvent) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    }
  }
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current!.focus();
    }
    prevOpen.current = open;
  }, [open]);

  const menuRoutes = [
    { name: 'Customization', link: '/admin-setup/theme' }
  ] as { name: string; link: string }[];

  const adminRoutes = [
    { name: 'Setup', link: `/admin-setup/${btoa('0')}` },
    { name: 'Admin Settings', link: `/admin/${btoa('1')}` }
  ];
  const salesRepRoutes = [
    { name: 'Expense Category', link: '/expense-category' }
  ];

  if (role === 'admin') {
    menuRoutes.unshift(...adminRoutes);
  } else {
    menuRoutes.unshift(...salesRepRoutes);
  }

  const stringAvatar = (name: string) => {
    return {
      sx: {
        bgcolor: stringToColor(name)
      },
      children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`
    };
  };
  return (
    <div>
      <Button
        ref={anchorRef}
        aria-haspopup='true'
        color='primary'
        variant='contained'
        className='no-shadow'
        onClick={handleToggle}
      >
        <Avatar
          className={classes.avatar + ' mr5'}
          {...stringAvatar(userName)}
        />
        <span>{userName}</span>
      </Button>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === 'bottom' ? 'center top' : 'center bottom'
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList
                  autoFocusItem={open}
                  onKeyDown={handleListKeyDown}
                  className={classes.list}
                >
                  {(role === 'admin' || role === ' sales_rep') && (
                    <span>
                      {menuRoutes.map(({ name, link }) => (
                        <MenuItem
                          key={name}
                          onClick={e => handleClose(e, link)}
                        >
                          {name}
                        </MenuItem>
                      ))}
                    </span>
                  )}
                  <Divider />
                  <MenuItem onClick={() => dispatch(logout())}>Logout</MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </div>
  );
};

export default UserMenu;
