import React, { useContext, useCallback, useEffect, useState } from 'react';
import clsx from 'clsx';
import {
  AppBar,
  Toolbar,
  Typography,
  IconButton,
  Grid,
  Popover,
  Badge,
  ListItem,
  useMediaQuery
} from '@material-ui/core';
import {
  Menu as MenuIcon,
  Notifications as NotificationsIcon
} from '@material-ui/icons';

import { AppbarProps } from '../../types';
import { Context } from '../../index';
import { useStyles } from '../../styles/topbar';
import OrganizationsMenu from './OrganizationsMenu';
import UserMenu from './UserMenu';
import { useDispatch, useSelector } from 'react-redux';
import {
  onGetNotifications,
  onGetReadNotifications
} from 'app/Dashboard/Notfications/redux/actions';
import { NotificationsProps } from 'app/Dashboard/Notfications/types';
import { onGetReminders } from 'app/Dashboard/Reminders/redux/actions';
import {
  onGetCards,
  onGetSubscription
} from 'app/Dashboard/StripeCard/redux/actions';
import { getTimeInterval } from 'app/Common/GetTimeInterval';
import InfiniteScroll from 'react-infinite-scroll-component';
import Loader from 'app/Common/CustomTable/Loader';

const Appbar = ({ drawerOpen, setOpen, user }: AppbarProps) => {
  const classes = useStyles();
  const { pageTitle } = useContext(Context);
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );
  const [refetchEnd] = useState(true);

  const dispatch = useDispatch();

  const { data, page, perPage, loading } = useSelector(
    ({ notifications }: NotificationsProps) => notifications
  );

  const subscriptionPlan = useCallback(() => {
    const stripeId = user.current_tenant?.stripe_subscription?.id;
    if (stripeId) {
      dispatch(onGetSubscription(stripeId));
    }
  }, [dispatch, user.current_tenant]);

  useEffect(() => {
    dispatch(onGetCards());
    subscriptionPlan();
  }, [dispatch, subscriptionPlan]);

  var isNotViewed = data?.some((item: any) => !item.is_viewed);

  const loadData = useCallback(() => {
    dispatch(onGetNotifications(page, perPage));
  }, [dispatch, page, perPage]);

  useEffect(() => {
    loadData();
    const res = setInterval(() => {
      loadData();
    }, 30000);

    return () => {
      clearInterval(res);
    };
  }, [loadData]);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    dispatch(onGetReadNotifications());
    loadData();
  };

  const handleClose = () => {
    setAnchorEl(null);
    loadData();
    dispatch(onGetReminders(1, 10));
  };
  const fetchData = () => {
    dispatch(onGetNotifications(page + 1, perPage));
  };

  const isMobile = useMediaQuery('(max-width:500px)');

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <>
      <AppBar
        position='fixed'
        className={clsx(classes.appBar, {
          [classes.appBarShift]: drawerOpen
        })}
      >
        <Toolbar className={classes.toolbar}>
          <div className={classes.infoBar}>
            {!isMobile && (
              <IconButton
                color='inherit'
                onClick={() => setOpen(!drawerOpen)}
                edge='start'
                className={classes.menuButton}
              >
                <MenuIcon />
              </IconButton>
            )}
            <Typography variant='h6' noWrap>
              {pageTitle}
            </Typography>
          </div>

          <div className={classes.menu}>
            {user?.role !== 'hr' && (
              <IconButton
                color='inherit'
                edge='start'
                onClick={handleClick}
                className={classes.menuButton}
              >
                {isNotViewed ? (
                  <Badge color='error' variant='dot'>
                    <NotificationsIcon />
                  </Badge>
                ) : (
                  <NotificationsIcon />
                )}
              </IconButton>
            )}

            <OrganizationsMenu />
            <UserMenu
              userName={`${user.first_name} ${user.last_name}`}
              role={user.role}
            />
          </div>
        </Toolbar>
      </AppBar>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
      >
        <div id='scrollableDiv' style={{ height: 350 }}>
          <InfiniteScroll
            dataLength={data.length}
            next={fetchData}
            hasMore={!refetchEnd}
            loader={<Loader loading={true} noData={!data.length && loading} />}
            scrollableTarget='scrollableDiv'
            style={{ overflow: 'hidden' }}
          >
            {data.filter(el => !el.is_viewed).length ? (
              data
                .filter(el => !el.is_viewed)
                .map(notification => (
                  <Grid container spacing={2}>
                    <Grid item>
                      <ListItem
                        className={classes.listNotifications}
                        key={notification.id}
                      >
                        {notification.message}
                      </ListItem>
                      <Typography className={classes.time}>
                        {getTimeInterval(new Date(notification.scheduled_at))}{' '}
                      </Typography>
                    </Grid>
                  </Grid>
                ))
            ) : (
              <Typography className={classes.notice}>
                No new Notifications Yet!
              </Typography>
            )}
          </InfiniteScroll>
        </div>
      </Popover>
    </>
  );
};

export default Appbar;

export interface PlansProps {
  plans: Array<PlansInterface>;
}

export interface PlansInterface {
  planData: {
    id: number;
    stripe_plan_id: string;
    nickname: string;
    active: boolean;
    currency: string;
    interval: string;
    amount: number;
  };
}
