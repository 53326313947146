import ReCAPTCHA from 'react-google-recaptcha';
import React, { useEffect, useRef } from 'react';

const RECAPTCHA_SITE_KEY = process.env.REACT_APP_RECAPTCHA_SITE_KEY;

const RecaptchaField = ({
  name,
  label,
  rules,
  error,
  setError,
  onChange,
  ...rest
}) => {
  const recaptchaRef = useRef(null);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const resetRecaptcha = () => {
    if (recaptchaRef.current) {
      recaptchaRef.current.reset();
      setError(false);
    }
  };
  useEffect(() => {
    error && resetRecaptcha();
  }, [error, resetRecaptcha]);

  return (
    <div style={{ width: '100%', height: '100px' }}>
      <ReCAPTCHA
        sitekey={RECAPTCHA_SITE_KEY}
        ref={recaptchaRef}
        onChange={onChange}
        {...rest}
      />
    </div>
  );
};
export default RecaptchaField;
