import {
  CHANGE_SUBSCRIPTION_ITEMS_PAGE,
  CHANGE_SUBSCRIPTION_ITEMS_PAGER,
  GET_SUBSCRIPTION_ITEMS,
  GET_SUBSCRIPTION_ITEMS_FAIL,
  GET_SUBSCRIPTION_ITEMS_SUCCESS
} from '../constants';

const initial = {
  data: [],
  loading: false,
  error: '',
  message: '',
  page: 1,
  perPage: 10,
  count: 1
};

const subscriptionItems = (
  state = initial,
  action: { type: string; payload: any }
) => {
  switch (action.type) {
    case GET_SUBSCRIPTION_ITEMS:
      return { ...state, loading: true };
    case GET_SUBSCRIPTION_ITEMS_SUCCESS:
      return { ...state, data: action.payload.data, loading: false };
    case GET_SUBSCRIPTION_ITEMS_FAIL:
      return { ...state, error: action.payload, loading: false };
    case CHANGE_SUBSCRIPTION_ITEMS_PAGER:
      return { ...state, perPage: action.payload };
    case CHANGE_SUBSCRIPTION_ITEMS_PAGE:
      return { ...state, page: action.payload };
    case 'CLEAR_ERROR':
      return { ...state, error: '', message: '' };
    default:
      return state;
  }
};

export default subscriptionItems;
