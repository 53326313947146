import {
  GET_NOTES,
  DELETE_NOTE,
  CHANGE_NOTES_PAGER,
  CHANGE_NOTES_PAGE
} from 'constants/index';
import { apiService } from 'middleware/api';
import { FORM_EDIT_DATA } from 'actions';

export const onGetNotes = (
  companyId: number,
  page = 1,
  perPage = 10,
  keyword = ''
) => {
  const endPoint = `notes?company_id=${companyId}&page=${page}&per_page=${perPage}&keyword=${keyword}`;
  const method = 'get';
  return apiService(endPoint, method, GET_NOTES);
};

export const onGetNote = (id: string) => {
  const endPoint = `notes/${id}.json`;
  const method = 'get';
  return apiService(endPoint, method, FORM_EDIT_DATA);
};

export const onDeleteNote = (id: string) => {
  const endPoint = `notes/${id}.json`;
  const method = 'delete';
  return apiService(endPoint, method, DELETE_NOTE);
};

export const onChangeNotesPerPage = (perPage: number) => ({
  type: CHANGE_NOTES_PAGER,
  payload: perPage
});

export const onChangeNotesPage = (page: number) => ({
  type: CHANGE_NOTES_PAGE,
  payload: page
});
