import React, { useState } from 'react';
import { Delete as DeleteIcon } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';

import { MouseNChangeEvent } from './types';
import PopConfirmation from './PopConfirmation';

const ListDeleteButton = ({
  loading,
  onClickHandler,
  disabled,
  message,
  size = 'default'
}: ListDeleteProps) => {
  const isDisable = loading || disabled || false;
  const classes = useStyles(isDisable);
  const [anchorEl, setAnchorEl] = useState(null);
  const [id, setId] = useState(null);

  return (
    <span>
      <DeleteIcon
        className={classes.delete}
        fontSize={size}
        key='deleteIcon'
        onClick={(e: MouseNChangeEvent) => {
          e.stopPropagation();
          if (isDisable) return null;
          setAnchorEl(e.currentTarget);
          setId(e.target.parentNode.parentNode.parentNode.parentNode.id);
        }}
      />
      <PopConfirmation
        anchorEl={anchorEl}
        setAnchorEl={setAnchorEl}
        onClickHandler={() => onClickHandler(id ?? '')}
        title={message}
      />
    </span>
  );
};

export default React.memo(ListDeleteButton);

const useStyles = makeStyles(theme => ({
  delete: {
    color: isDisable =>
      isDisable ? theme.palette.grey[500] : theme.palette.error.dark,
    cursor: isDisable => (isDisable ? 'not-allowed' : 'pointer')
  }
}));

interface ListDeleteProps {
  loading: boolean;
  onClickHandler: (key: string) => void;
  disabled?: boolean;
  message?: string;
  size?: 'default' | 'small' | 'large';
}
