import React from 'react';
import { Button, Grid } from '@material-ui/core';
import { Add } from '@material-ui/icons';

import ListSearcher from 'app/Common/ListSearcher';
import { ExpenseCategoriesTopbarProps } from '../types';

const ExpenseCategoriesTopbar = ({
    loading,
    setForm,
    setSearchText
}: ExpenseCategoriesTopbarProps) => {

    return (
        <Grid
            container
            className='mt10 mb10 pr5 pl5'
            justify='space-between'
            alignItems='flex-end'
        >
            <div>
                <ListSearcher searchTextHandle={setSearchText} loading={loading} />
            </div>
            <Button
                color='primary'
                variant='contained'
                disabled={loading}
                onClick={() => setForm(true)}
            >
                <Add /> New Expense Category
      </Button>
        </Grid>
    );
};

export default React.memo(ExpenseCategoriesTopbar);
