import {
  GET_CONTACTS,
  GET_CONTACTS_FAIL,
  GET_CONTACTS_SUCCESS,
  DELETE_CONTACT,
  DELETE_CONTACT_FAIL,
  DELETE_CONTACT_SUCCESS,
  CHANGE_CONTACTS_PAGER,
  CHANGE_CONTACTS_PAGE,
  GET_ALL_CONTACTS,
  GET_ALL_CONTACTS_FAIL,
  GET_ALL_CONTACTS_SUCCESS,
  CHANGE_LIST_PAGE,
  ADD_EXISTING_CONTACT,
  ADD_EXISTING_CONTACT_SUCCESS,
  ADD_EXISTING_CONTACT_FAIL
} from '../constants';

const initial = {
  data: [],
  loading: false,
  error: '',
  message: '',
  page: 1,
  perPage: 10,
  count: 1,
  listData: [],
  listPage: 1,
  listCount: 1
};

const contacts = (state = initial, action: { type: string; payload: any }) => {
  switch (action.type) {
    case GET_CONTACTS:
      return { ...state, loading: true };
    case GET_CONTACTS_SUCCESS:
      return {
        ...state,
        data: action.payload.data,
        count: action.payload.meta_attributes
          ? action.payload.meta_attributes.total_count
          : 1,
        loading: false
      };
    case GET_CONTACTS_FAIL:
      return { ...state, error: action.payload, loading: false };
    case 'CLEAR_ERROR':
      return { ...state, error: '', message: '' };
    case DELETE_CONTACT:
      return { ...state, loading: true };
    case DELETE_CONTACT_SUCCESS:
      return { ...state, message: action.payload.message, loading: false };
    case DELETE_CONTACT_FAIL:
      return { ...state, error: action.payload, loading: false };
    case CHANGE_CONTACTS_PAGER:
      return { ...state, perPage: action.payload };
    case CHANGE_CONTACTS_PAGE:
      return { ...state, page: action.payload };
    case GET_ALL_CONTACTS:
      return { ...state, loading: true };
    case GET_ALL_CONTACTS_SUCCESS:
      return {
        ...state,
        listData: action.payload.data,
        listCount: action.payload.meta_attributes
          ? action.payload.meta_attributes.total_count
          : 1,
        loading: false
      };
    case GET_ALL_CONTACTS_FAIL:
      return { ...state, error: action.payload, loading: false };
    case CHANGE_LIST_PAGE:
      return { ...state, listPage: action.payload };
    case ADD_EXISTING_CONTACT:
      return { ...state, loading: true };
    case ADD_EXISTING_CONTACT_SUCCESS:
      return {
        ...state,
        message: 'Contact Added Successfully',
        loading: false
      };
    case ADD_EXISTING_CONTACT_FAIL:
      return { ...state, error: action.payload, loading: false };
    default:
      return state;
  }
};

export default contacts;
