import {
  GET_USERS,
  GET_USERS_FAIL,
  GET_USERS_SUCCESS,
  DELETE_USER,
  DELETE_USER_FAIL,
  DELETE_USER_SUCCESS,
  CHANGE_USERS_PAGER,
  CHANGE_USERS_PAGE,
  UPDATE_USER_ROLE,
  UPDATE_USER_ROLE_FAIL,
  UPDATE_USER_ROLE_SUCCESS,
  CHANGE_USER_PERMISSION,
  CHANGE_USER_PERMISSION_SUCCESS,
  CHANGE_USER_PERMISSION_FAIL,
  GET_USER_BY_EMAIL_SUCCESS,
  GET_USER_BY_EMAIL_FAIL,
  GET_USER_BY_EMAIL,
  GET_USER_LIMIT,
  GET_USER_LIMIT_SUCCESS,
  GET_USER_LIMIT_FAIL
} from 'constants/index';

const initial = {
  data: [],
  loading: false,
  error: '',
  message: '',
  page: 1,
  perPage: 10,
  count: 1,
  user: [],
  usercount: {}
};

const users = (state = initial, action: { type: string; payload: any }) => {
  switch (action.type) {
    case GET_USERS:
      return { ...state, loading: true };
    case GET_USERS_SUCCESS:
      return {
        ...state,
        data: action.payload.data,
        count: action.payload.meta_attributes
          ? action.payload.meta_attributes.total_count
          : 1,
        loading: false
      };
    case GET_USERS_FAIL:
      return { ...state, error: action.payload, loading: false };
    case 'CLEAR_ERROR':
      return { ...state, error: '', message: '' };
    case DELETE_USER:
      return { ...state, loading: true };
    case DELETE_USER_SUCCESS:
      return { ...state, message: action.payload.message, loading: false };
    case DELETE_USER_FAIL:
      return { ...state, error: action.payload, loading: false };
    case UPDATE_USER_ROLE:
      return { ...state, loading: true };
    case UPDATE_USER_ROLE_SUCCESS:
      return { ...state, message: action.payload.message, loading: false };
    case UPDATE_USER_ROLE_FAIL:
      return { ...state, error: action.payload, loading: false };
    case CHANGE_USER_PERMISSION:
      return { ...state, loading: true };
    case CHANGE_USER_PERMISSION_SUCCESS:
      return { ...state, message: action.payload.message, loading: false };
    case CHANGE_USER_PERMISSION_FAIL:
      return { ...state, error: action.payload, loading: false };
    case CHANGE_USERS_PAGER:
      return { ...state, perPage: action.payload };
    case CHANGE_USERS_PAGE:
      return { ...state, page: action.payload };

    case GET_USER_BY_EMAIL:
      return { ...state, loading: true };
    case GET_USER_BY_EMAIL_SUCCESS:
      return { ...state, loading: false, user: action.payload.data };
    case GET_USER_BY_EMAIL_FAIL:
      return { ...state, error: action.payload };
    case GET_USER_LIMIT:
      return { ...state, loading: true };

    case GET_USER_LIMIT_SUCCESS:
      return {
        ...state,
        usercount: action.payload.data,
        count: action.payload.meta_attributes
          ? action.payload.meta_attributes.total_count
          : 1,
        loading: false
      };
    case GET_USER_LIMIT_FAIL:
      return { ...state, error: action.payload, loading: false };
    case 'CLEAR_USER':
      return { ...state, user: [] };

    default:
      return state;
  }
};

export default users;
