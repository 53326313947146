import { CLEAR_SUBSCRIPTION } from 'app/Dashboard/Subscriptions/redux/constants';
import {
  CREATE_SUBSCRIPTIONS,
  CREATE_SUBSCRIPTIONS_FAIL,
  CREATE_SUBSCRIPTIONS_SUCCESS,
  GET_CARDS,
  GET_CARDS_SUCCESS,
  GET_CARDS_FAIL,
  DELETE_CARD,
  DELETE_CARD_FAIL,
  DELETE_CARD_SUCCESS,
  GET_SUBSCRIPTION,
  GET_SUBSCRIPTION_FAIL,
  GET_SUBSCRIPTION_SUCCESS,
  UPDATE_PLAN,
  UPDATE_PLAN_SUCCESS,
  UPDATE_PLAN_FAIL,
  CREATE_CARD,
  CREATE_CARD_FAIL,
  CREATE_CARD_SUCCESS,
  CANCEL_SUBSCRIPTION,
  CANCEL_SUBSCRIPTION_SUCCESS,
  CANCEL_SUBSCRIPTION_FAIL
} from '../constants';

const initial = {
  data: [],
  loading: false,
  error: '',
  message: '',
  cardData: [],
  cardLoading: false,
  cardError: '',
  cardMessage: '',
  subscriptionData: {},
  subscriptionError: '',
  subscriptionMessage: '',
  subscriptionLoading: '',
  cancelSubscriptionData: {},
  cancelSubscriptionError: '',
  cancelSubscriptionMessage: '',
  cancelSubscriptionLoading: ''
};

const planSubscriptions = (
  state = initial,
  action: { type: string; payload: any }
) => {
  switch (action.type) {
    case CREATE_SUBSCRIPTIONS:
      return { ...state, loading: true };
    case CREATE_SUBSCRIPTIONS_SUCCESS:
      return {
        ...state,
        subscriptionData: action.payload.data,
        message: action.payload.message,
        count: action.payload.meta_attributes
          ? action.payload.meta_attributes.total_count
          : 1,
        loading: false
      };
    case CREATE_SUBSCRIPTIONS_FAIL:
      return { ...state, error: action.payload, loading: false };
    case GET_SUBSCRIPTION:
      return { ...state, subscriptionLoading: true };
    case GET_SUBSCRIPTION_SUCCESS:
      return {
        ...state,
        subscriptionData: action.payload.data,
        subscriptionMessage: action.payload.message,
        subscriptionLoading: false
      };
    case GET_SUBSCRIPTION_FAIL:
      return {
        ...state,
        subscriptionError: action.payload,
        subscriptionLoading: false
      };
    case GET_CARDS:
      return { ...state, cardLoading: true };
    case GET_CARDS_SUCCESS:
      return {
        ...state,
        cardData: action.payload.data,
        cardMessage: action.payload.message,
        cardLoading: false
      };
    case GET_CARDS_FAIL:
      return { ...state, cardError: action.payload, cardLoading: false };
    case DELETE_CARD:
      return { ...state, cardLoading: true };
    case DELETE_CARD_SUCCESS:
      return {
        ...state,
        cardMessage: action.payload.message,
        cardLoading: false
      };
    case DELETE_CARD_FAIL:
      return { ...state, cardError: action.payload, cardLoading: false };
    case UPDATE_PLAN:
      return { ...state, loading: true };
    case UPDATE_PLAN_SUCCESS:
      return {
        ...state,
        data: action.payload.data,
        message: action.payload.message,
        loading: false
      };
    case UPDATE_PLAN_FAIL:
      return { ...state, error: action.payload, loading: false };
    case CREATE_CARD:
      return { ...state, loading: true };
    case CREATE_CARD_SUCCESS:
      return {
        ...state,
        data: action.payload.data,
        message: action.payload.message,
        loading: false
      };
    case CREATE_CARD_FAIL:
      return { ...state, error: action.payload, loading: false };
    case CANCEL_SUBSCRIPTION:
      return { ...state, cancelSubscriptionLoading: true };
    case CANCEL_SUBSCRIPTION_SUCCESS:
      return {
        ...state,
        cancelSubscriptionData: action.payload.data,
        cancelSubscriptionMessage: action.payload.message,
        cancelSubscriptionLoading: false
      };
    case CANCEL_SUBSCRIPTION_FAIL:
      return {
        ...state,
        cancelSubscriptionError: action.payload,
        cancelSubscriptionLoading: false
      };
    case 'CLEAR_ERROR':
      return {
        ...state,
        error: '',
        message: '',
        cardErrod: '',
        cardMessage: ''
      };
    case CLEAR_SUBSCRIPTION:
      return initial;
    default:
      return state;
  }
};

export default planSubscriptions;
