import React, { useEffect, useState } from 'react';
import {
  FormControl,
  FormHelperText,
  makeStyles,
  TextField,
  Typography
} from '@material-ui/core';
import { useLocation, useParams } from 'react-router-dom';

import CustomDialog from 'app/Common/CustomDialog';
import { useForm } from 'app/Common/Hooks/useForm';
import SignaturePad from './SignaturePad';
import { paramsToObject } from 'utils/common/paramsToObject';

const QuoteSubmitForm = ({ formType, setFormType }: QuoteSubmitFormProps) => {
  const [reason, setReason] = useState('');
  const [error, setError] = useState<null | string>(null);
  const { token } = useParams<{ token: string }>();
  const { search } = useLocation();
  const { e } = paramsToObject(search);
  const { txtCenter } = useStyles();

  const formHook = useForm();
  const isAccepted = formType === 'accept';
  const submitQuote = (file?: string) => {
    if (isAccepted && !file?.length) {
      setError('Please mark your signature.');
      setTimeout(() => setError(null), 3000);
      return;
    }
    if (!isAccepted && !reason.trim().length) {
      setError('Please write down the reason for rejection.');
      setTimeout(() => setError(null), 3000);
      return;
    }
    setError(null);
    isAccepted
      ? formHook.onSubmitPost(`external/quotes/approve`, {
          file,
          token,
          email_to: e
        })
      : formHook.onSubmitPost(`external/quotes/reject`, {
          reason,
          token,
          email_to: e
        });
  };
  useEffect(() => {
    if (formHook.status === 'success') {
      window.close();
      setFormType('');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formHook.status]);
  return (
    <CustomDialog
      loading={formHook.loading}
      open={true}
      closeHandle={() => setFormType('')}
      heading={isAccepted ? 'Signature' : 'Reason'}
      okHandle={submitQuote}
      okButtonText='Submit'
      footer={isAccepted ? 'hide' : undefined}
    >
      <FormControl fullWidth>
        <FormHelperText error>{error}</FormHelperText>
        {isAccepted && (
          <Typography className={txtCenter}>
            Please use mouse to draw signature
          </Typography>
        )}
        {isAccepted ? (
          <SignaturePad
            submitQuote={submitQuote}
            closeHandle={() => setFormType('')}
          />
        ) : (
          <TextField
            placeholder='Please write a reason for reject the quote'
            multiline
            fullWidth
            rows={3}
            variant='outlined'
            onChange={e => setReason(e.target.value)}
          />
        )}
      </FormControl>
    </CustomDialog>
  );
};

export default QuoteSubmitForm;

const useStyles = makeStyles({
  txtCenter: { textAlign: 'center', textDecoration: 'underline' }
});

interface QuoteSubmitFormProps {
  formType: string;
  setFormType: (type: '') => void;
}
