import {
  ACCEPT_ORGANIZATION_REQUEST,
  ACCEPT_ORGANIZATION_REQUEST_SUCCESS,
  ACCEPT_ORGANIZATION_REQUEST_FAIL,
  REJECT_ORGANIZATION_REQUEST,
  REJECT_ORGANIZATION_REQUEST_SUCCESS,
  REJECT_ORGANIZATION_REQUEST_FAIL
} from 'constants/index';

const initialSatte = { loading: false, error: '', message: '' };

const acceptInvite = (
  state = initialSatte,
  action: { type: string; payload: any }
) => {
  switch (action.type) {
    case ACCEPT_ORGANIZATION_REQUEST:
      return { ...state, loading: true };
    case ACCEPT_ORGANIZATION_REQUEST_SUCCESS:
      return { ...state, loading: false, message: action.payload.message };
    case ACCEPT_ORGANIZATION_REQUEST_FAIL:
      return { ...state, loading: false, error: action.payload };
    case REJECT_ORGANIZATION_REQUEST:
      return {
        ...state,
        loading: true
      };
    case REJECT_ORGANIZATION_REQUEST_SUCCESS:
      return {
        ...state,
        loading: false,
        message: action.payload?.message
      };
    case REJECT_ORGANIZATION_REQUEST_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload
      };
    case 'CLEAR_ERROR':
      return { ...state, error: '', message: '' };
    default:
      return state;
  }
};

export default acceptInvite;
