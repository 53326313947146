import { createStore, compose, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import logger from 'redux-logger';
import { persistStore, persistReducer } from 'redux-persist';
import persistConfig from './presistConfig';

import reducer from './reducers';

export const composeEnhancers =
  (window && (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;

  const persistedReducer = persistReducer(persistConfig, reducer);

export const store = createStore(
  persistedReducer,
  process.env.NODE_ENV !== 'production'
    ? composeEnhancers(applyMiddleware(thunk, logger))
    : composeEnhancers(applyMiddleware(thunk))
);


export const persistor = persistStore(store);