import {
  DELETE_ATTACHMENT,
  DELETE_ATTACHMENT_SUCCESS,
  DELETE_ATTACHMENT_FAIL
} from '../constants';

const initial = {
  loading: false,
  message: '',
  error: ''
};

const attachments = (
  state = initial,
  action: { type: string; payload: any }
) => {
  switch (action.type) {
    case DELETE_ATTACHMENT:
      return { ...state, loading: true };
    case DELETE_ATTACHMENT_SUCCESS:
      return { ...state, message: action.payload.message, loading: false };
    case DELETE_ATTACHMENT_FAIL:
      return { ...state, error: action.payload, loading: false };
    case 'CLEAR_ERROR':
      return { ...state, error: '', message: '' };
    default:
      return state;
  }
};
export default attachments;
