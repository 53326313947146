export const GET_WORK_LOGS = 'GET_WORK_LOGS';
export const GET_WORK_LOGS_SUCCESS = 'GET_WORK_LOGS_SUCCESS';
export const GET_WORK_LOGS_FAIL = 'GET_WORK_LOGS_FAIL';
export const DELETE_WORK_LOG = 'DELETE_WORK_LOG';
export const DELETE_WORK_LOG_SUCCESS = 'DELETE_WORK_LOG_SUCCESS';
export const DELETE_WORK_LOG_FAIL = 'DELETE_WORK_LOG_FAIL';
export const CHANGE_WORK_LOGS_PAGER = 'CHANGE_WORK_LOGS_PAGER';
export const CHANGE_WORK_LOGS_PAGE = 'CHANGE_WORK_LOGS_PAGE';

export const GET_REPORT = 'GET_REPORT';
export const GET_REPORT_SUCCESS = 'GET_REPORT_SUCCESS';
export const GET_REPORT_FAIL = 'GET_REPORT_FAIL';

export const EXPORT_WORKLOGS = 'EXPORT_WORKLOGS';
export const EXPORT_WORKLOGS_SUCCESS = 'EXPORT_WORKLOGS_SUCCESS';
export const EXPORT_WORKLOGS_FAIL = 'EXPORT_WORKLOGS_FAIL';
