import React from 'react';
import { ListItem } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Skeleton } from '@material-ui/lab';

const InfoItem = ({ label, value, loading }: InfoItemProps) => {
  const classes = useStyles();
  return (
    <ListItem className={classes.list}>
      <label className={classes.listLabel}>{label}</label>
      {loading ? (
        <Skeleton variant='text' width='100%' />
      ) : (
        <span className='break'>{value}</span>
      )}
    </ListItem>
  );
};

const useStyles = makeStyles(() => ({
  list: { flexDirection: 'column', alignItems: 'flex-start' },
  listLabel: { color: '#afafaf' }
}));

export default React.memo(InfoItem);

interface InfoItemProps {
  label: string;
  value: string;
  loading: boolean;
}
