import {
  CHANGE_SUBSCRIPTIONS_PAGE,
  CHANGE_SUBSCRIPTIONS_PAGER,
  CLEAR_SUBSCRIPTION,
  GET_SUBSCRIPTIONS
} from '../constants';
import { apiService } from 'middleware/api';

export const onGetSubscriptions = (
  page = 1,
  perPage = 10,
  keyword = '',
  companyId: string,
  fromDate: string,
  toDate: string
) => {
  const endPoint = `nlt_subscriptions.json?page=${page}&per_page=${perPage}&keyword=${keyword}&company_id=${companyId}&start_date=${fromDate}&end_date=${
    toDate ||
    `${new Date().getFullYear()}-${
      new Date(fromDate).getMonth() + 1
    }-${new Date(
      new Date().getFullYear(),
      new Date(fromDate).getMonth() + 1,
      0
    ).getDate()}`
  }`;
  const method = 'get';
  return apiService(endPoint, method, GET_SUBSCRIPTIONS);
};

export const onChangeSubscriptionsPerPage = (perPage: number) => ({
  type: CHANGE_SUBSCRIPTIONS_PAGER,
  payload: perPage
});

export const onChangeSubscriptionsPage = (page: number) => ({
  type: CHANGE_SUBSCRIPTIONS_PAGE,
  payload: page
});

export const onClearSubscription = () => ({ type: CLEAR_SUBSCRIPTION });
