import React from 'react';
import { Button, Grid, makeStyles } from '@material-ui/core';

import { FormButtonProps } from './types';

const FormButtons = ({
  loading,
  onCancel,
  id,
  submitBtnText,
  closeBtnText,
  submitBtnDisable = false
}: FormButtonProps) => {
  const classes = useStyles();
  return (
    <Grid container justify='flex-end' className='mt50 mb10 mr5'>
      <Button
        type='submit'
        disabled={loading || submitBtnDisable}
        className={classes.button}
      >
        {submitBtnText || (id ? 'Update' : 'Create')}
      </Button>
      <Button
        onClick={onCancel}
        color='primary'
        disabled={loading}
        className={classes.button}
      >
        {closeBtnText || 'Close'}
      </Button>
    </Grid>
  );
};

export default React.memo(FormButtons);

const useStyles = makeStyles(theme => ({
  button: { color: theme.palette.info.dark }
}));
