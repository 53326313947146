import {
  Button,
  List,
  ListItem,
  ListItemText,
  makeStyles
} from '@material-ui/core';
import {
  Assignment as AssignmentIcon,
  Business as CompanyIcon,
  CardMembership as SubscriptionIcon,
  DeveloperBoard,
  Home,
  PlaylistAddCheck as WorklogIcon,
  Receipt as QuoteIcon,
  Timer as TimeCard,
  Toys as ProductIcon,
  Work as WorkIcon,
  NotificationsActive as ReminderIcon,
  Description as SecurityFormIcon
} from '@material-ui/icons';
import { OrganizationsProps } from 'app/Dashboard/Organizations/types';
import React from 'react';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { AuthProps } from 'types';

const isProduction = window.location.host === 'nlstack.com';

const SidebarItems = () => {
  const classes = useStyles();
  const { current } = useSelector(
    ({ organizations }: OrganizationsProps) => organizations
  );

  const { user } = useSelector(({ auth }: AuthProps) => auth);

  const sidebarLinks = [
    {
      name: 'Dashboard',
      link: '/dashboard',
      icon: <Home className={classes.icon} />,
      isEnable: user?.role === 'admin' || user?.role === 'sales_rep'
    },
    {
      name: 'Products',
      link: '/products',
      icon: <ProductIcon className={classes.icon} />,
      isEnable: user?.role === 'admin' || user?.role === 'sales_rep'
    },
    {
      name: 'Companies',
      link: '/companies',
      icon: <CompanyIcon className={classes.icon} />,
      isEnable: user?.role === 'admin' || user?.role === 'sales_rep'
    },
    {
      name: 'Projects',
      link: '/projects',
      icon: <WorkIcon className={classes.icon} />,
      isEnable: user?.role === 'admin' || user?.role === 'sales_rep'
    },
    {
      name: 'Tasks',
      link: '/tasks',
      icon: <AssignmentIcon className={classes.icon} />,
      isEnable:
        !isProduction && (user?.role === 'admin' || user?.role === 'sales_rep')
    },
    {
      name: 'KanBan',
      link: '/kanban',
      icon: <DeveloperBoard className={classes.icon} />,
      isEnable: user?.role === 'admin' || user?.role === 'sales_rep'
    },
    {
      name: 'Work Logs',
      link: '/worklogs',
      icon: <WorklogIcon className={classes.icon} />,
      isEnable:
        !isProduction && (user?.role === 'admin' || user?.role === 'sales_rep')
    },
    {
      name: 'Time Cards',
      link: '/timecards',
      icon: <TimeCard className={classes.icon} />,
      isEnable:
        !isProduction && (user?.role === 'admin' || user?.role === 'sales_rep')
    },
    {
      name: 'Quotes',
      link: '/quotes',
      icon: <QuoteIcon className={classes.icon} />,
      isEnable: user?.role === 'admin' || user?.role === 'sales_rep'
    },
    {
      name: 'Reminders',
      link: '/reminders',
      icon: <ReminderIcon className={classes.icon} />,
      isEnable: user?.role === 'admin' || user?.role === 'sales_rep'
    },
    {
      name: 'New Hire ',
      link: '/newHireAutomation',
      icon: <WorklogIcon className={classes.icon} />,
      isEnable: user.role === 'hr'
    }
  ];
  current?.nlt_subscription_enable &&
    user?.role === 'admin' &&
    sidebarLinks.push({
      name: 'Cyber Security Forms',
      link: '/cyberSecurityFormsListing',
      icon: <SecurityFormIcon className={classes.icon} />,
      isEnable: !!current?.nlt_subscription_enable
    });
  current?.nlt_subscription_enable &&
    user?.role !== 'hr' &&
    sidebarLinks.push({
      name: 'Subscriptions',
      link: '/subscriptions',
      icon: <SubscriptionIcon className={classes.icon} />,
      isEnable: !!current?.nlt_subscription_enable
    });

  return (
    <List className={classes.list}>
      {sidebarLinks
        .filter(({ isEnable }) => isEnable)
        .map(({ name, link, icon }, index) => (
          <NavLink
            key={index}
            to={link}
            className={classes.link}
            activeClassName={classes.active}
          >
            <ListItem className={classes.listItem}>
              <Button
                color='secondary'
                variant='contained'
                fullWidth
                startIcon={icon}
                className={classes.btn}
              >
                <ListItemText primary={name} />
              </Button>
            </ListItem>
          </NavLink>
        ))}
    </List>
  );
};

export default React.memo(SidebarItems);

const useStyles = makeStyles(
  ({ palette: { secondary, primary }, spacing }) => ({
    list: { paddingTop: 0, paddingBottom: 0 },
    listItem: { padding: 0 },
    link: {
      textDecoration: 'none',
      display: 'flex',
      alignItems: 'center',
      borderBottom: `1px solid ${secondary.light}`
    },
    btn: { padding: spacing(1, 3), boxShadow: 'none', textAlign: 'left' },
    icon: { marginRight: spacing(3), fontSize: '24px !important' },
    active: {
      fontWeight: 'bold',
      background: primary.dark,
      '& $btn': { background: primary.main, borderRadius: 0 }
    }
  })
);
