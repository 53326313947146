import axios from 'axios';
import { LOGOUT } from 'app/Auth/redux/constants';

type methodType = 'get' | 'post' | 'put' | 'delete';

export const apiService =
  (
    endPoint: string,
    method: methodType,
    type: string,
    body?: {},
    isFormData = false,
    queryHeaders = {}
  ) =>
  async (dispatch: any) => {
    const BASE_URL = process.env.REACT_APP_API_URL;
    const API_URL = `${BASE_URL}${endPoint}`;
    const token = window.localStorage.getItem('token');
    try {
      dispatch({ type });
      let headers = queryHeaders || { 'content-type': 'application/json' };

      if (token && token !== null) {
        const user = JSON.parse(token);
        headers = { ...headers, ...user };
      }
      if (isFormData) {
        // @ts-ignore
        headers['content-type'] = `multipart/form-data`;
      }
      const response = await axios({
        method,
        url: API_URL,
        data: body,
        headers
      });

      const res: Response = response.data;
      if (res.status === 'error') {
        throw Error(
          Array.isArray(res.errors)
            ? res.errors[0]
            : res.errors.full_messages[0]
        );
      }
      if (res.success === false || res.errors) {
        if (Array.isArray(res.error)) {
          throw Error(res.error[0]);
        }
        if (res.errors) {
          throw Error(
            Array.isArray(res.errors)
              ? res.errors[0]
              : res.errors.full_messages[0]
          );
        }
        if (res.message) {
          throw Error(res.message);
        }
      }
      dispatch({ type: `${type}_SUCCESS`, payload: res });
    } catch (error) {
      dispatch({
        type: `${type}_FAIL`,
        payload:
          typeof error.response !== 'undefined'
            ? error.response.data.errors
              ? error.response.data.errors.full_messages
                ? error.response.data.errors.full_messages[0]
                : error.response.data.errors[0]
              : error.response.status >= 400
              ? error.response.statusText
              : error.response.data.error[0]
            : typeof error.message === 'string'
            ? error.message
            : 'Failed to fetch'
      });
      if (error?.response?.status === 401) {
        localStorage.clear();
        dispatch({ type: LOGOUT });
      }
    }
  };

interface Response {
  status: string;
  error: string;
  errors: Array<string> | { full_messages: [string] };
  message: string;
  data: {};
  success?: boolean;
  queryHeaders?: {};
}
