import { CREATE_SUBSCRIPTIONS_SUCCESS } from 'app/Dashboard/StripeCard/redux/constants';
import {
  CLEAR_SUBSCRIPTION,
  GET_SUBSCRIPTIONS_SUCCESS
} from 'app/Dashboard/Subscriptions/redux/constants';
import {
  LOGIN_ATTEMPT,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGIN,
  LOGOUT,
  SIGNUP,
  SIGNUP_FAIL,
  SIGNUP_SUCCESS,
  FORGET,
  RESET,
  FORGET_SUCCESS,
  FORGET_FAIL,
  RESET_SUCCESS,
  RESET_FAIL,
  ACCEPT,
  ACCEPT_SUCCESS,
  ACCEPT_FAIL,
  SHOW_INVITE,
  SHOW_INVITE_SUCCESS,
  SHOW_INVITE_FAIL,
  GET_ORGANIZATIONS_SUCCESS,
  INVITED_USER,
  INVITED_USER_SUCCESS,
  INVITED_USER_FAIL,
  SET_ORG,
  SET_USER_TZ,
  DISCONNECT_MS_TOKEN_SUCCESS,
  SEND_MS_TOKEN_SUCCESS
} from 'constants/index';

const initial = {
  isAuth: false,
  user: {},
  data: {},
  loading: false,
  error: '',
  message: '',
  isInvitedUser: false
};

const auth = (state = initial, action: { type: string; payload: any }) => {
  switch (action.type) {
    case LOGIN:
      return { ...state, loading: true };
    case LOGIN_ATTEMPT:
      return { ...state, loading: true };
    case LOGIN_SUCCESS:
      return {
        ...state,
        data: action.payload.data,
        user: action.payload.user,
        loading: false,
        isAuth: true
      };
    case LOGIN_FAIL:
      return { ...state, error: action.payload, loading: false, isAuth: false };
    case LOGOUT:
      return { ...state, data: {}, isAuth: false };

    case SIGNUP:
      return { ...state, loading: true };
    case SIGNUP_SUCCESS:
      return { ...state, loading: false, message: 'User created successfully' };
    case SIGNUP_FAIL:
      return { ...state, error: action.payload, loading: false };

    case FORGET:
      return { ...state, loading: true };
    case FORGET_SUCCESS:
      return {
        ...state,
        loading: false,
        message: 'An email has been sent for reset password'
      };
    case FORGET_FAIL:
      return { ...state, error: action.payload, loading: false };

    case RESET:
      return { ...state, loading: true };
    case RESET_SUCCESS:
      return {
        ...state,
        loading: false,
        message: 'Password reset successfully'
      };
    case RESET_FAIL:
      return { ...state, error: action.payload, loading: false };

    case ACCEPT:
      return { ...state, loading: true };
    case ACCEPT_SUCCESS:
      return {
        ...state,
        loading: false,
        message: 'Invite Accept successfully. Please Login'
      };
    case ACCEPT_FAIL:
      return { ...state, error: action.payload, loading: false };

    case SHOW_INVITE:
      return { ...state, loading: true };
    case SHOW_INVITE_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload.data
      };
    case SHOW_INVITE_FAIL:
      return { ...state, error: action.payload, loading: false };

    case GET_ORGANIZATIONS_SUCCESS:
      window.localStorage.setItem(
        'org',
        JSON.stringify(action.payload.organizations)
      );
      return { ...state, organizations: action.payload.organizations };
    case 'CLEAR_ERROR':
      return { ...state, error: '' };
    case 'CLEAR_AUTH':
      return { ...state, error: '', message: '' };
    case INVITED_USER:
      return { ...state, loading: true };
    case INVITED_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        isInvitedUser: !action.payload.data.length
      };
    case INVITED_USER_FAIL:
      return { ...state, loading: false, error: action.payload };
    case GET_SUBSCRIPTIONS_SUCCESS:
      return {
        ...state,
        user: { ...state.user, stripe_subscription_id: action.payload.data.id }
      };
    case SET_ORG:
      return {
        ...state,
        data: {
          ...state.data,
          ...action.payload?.authData
        },
        user: {
          ...state.user,
          current_tenant: action.payload.data?.organization,
          role: 'admin'
        }
      };
    case CLEAR_SUBSCRIPTION: {
      return {
        ...state,
        user: {
          ...state.user,
          stripe_subscription_id: '',
          current_tenant: {
            // @ts-ignore
            ...state.user.current_tenant,
            stripe_subscription: {}
          }
        }
      };
    }
    case CREATE_SUBSCRIPTIONS_SUCCESS:
      return {
        ...state,
        user: {
          ...state.user,
          current_tenant: {
            // @ts-ignore
            ...state.user.current_tenant,
            stripe_subscription: action.payload.data
          }
        }
      };
    case SET_USER_TZ:
      return { ...state, user: { ...state.user, time_zone: action.payload } };
    case DISCONNECT_MS_TOKEN_SUCCESS:
      return {
        ...state,
        user: {
          ...state.user,
          current_tenant: {
            // @ts-ignore
            ...state.user.current_tenant,
            has_ms_integration: false
          }
        }
      };
    case SEND_MS_TOKEN_SUCCESS:
      return {
        ...state,
        user: {
          ...state.user,
          current_tenant: {
            // @ts-ignore
            ...state.user.current_tenant,
            has_ms_integration: true
          }
        }
      };
    default:
      return state;
  }
};

export default auth;
