import React, { useCallback } from 'react';
import { Tooltip, Button } from '@material-ui/core';
import { useDropzone } from 'react-dropzone';
import { CloudUpload as CloudUploadIcon } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';

const MultifilesUploader = ({
  files,
  uploadHandler,
  text,
  tooltip,
  className,
  fileType
}: MultifilesUploaderProps) => {
  const onDrop = useCallback(
    uploadFiles => {
      uploadHandler(uploadFiles[0]);
    },
    // eslint-disable-next-line
    [files]
  );
  const classes = useStyles();
  const {
    getRootProps,
    getInputProps,
    open: dropzoneOpen
  } = useDropzone({
    onDrop,
    noClick: true,
    noKeyboard: true,
    accept: fileType ?? ['image/*', '.pdf']
  });

  return (
    <div
      {...getRootProps({
        className: `dropzone ${className ? className : classes.dropZone}`
      })}
    >
      <input {...getInputProps()} />
      <Tooltip title={tooltip || 'Attach Files'}>
        <Button
          size='small'
          color='primary'
          variant='contained'
          className={className ? className : classes.dropZoneBtn}
          onClick={dropzoneOpen}
          aria-label='attachments'
          startIcon={<CloudUploadIcon />}
        >
          {text || 'Upload'}
        </Button>
      </Tooltip>
    </div>
  );
};

export default MultifilesUploader;

const useStyles = makeStyles(theme => ({
  dropZone: { display: 'inline-block', paddingLeft: 8, paddingRight: 8 },
  dropZoneBtn: { marginTop: 16, marginRight: 5 }
}));

interface MultifilesUploaderProps {
  files?: Array<{ [key: string]: any }>;
  uploadHandler: Function;
  text: string;
  tooltip?: string;
  fileType?: string[];
  className?: string;
}
