import {
  GET_QUOTEITEMS,
  GET_QUOTEITEMS_FAIL,
  GET_QUOTEITEMS_SUCCESS,
  DELETE_QUOTEITEM,
  DELETE_QUOTEITEM_FAIL,
  DELETE_QUOTEITEM_SUCCESS,
  CHANGE_QUOTEITEMS_PAGER,
  CHANGE_QUOTEITEMS_PAGE,
  PRINT_QUOTE,
  PRINT_QUOTE_SUCCESS,
  PRINT_QUOTE_FAIL
} from 'constants/index';

const initial = {
  data: [],
  loading: false,
  error: '',
  message: '',
  page: 1,
  perPage: 10,
  count: 1,
  printFile: ''
};

const quoteItems = (
  state = initial,
  action: { type: string; payload: any }
) => {
  switch (action.type) {
    case GET_QUOTEITEMS:
      return { ...state, loading: true };
    case GET_QUOTEITEMS_SUCCESS:
      return {
        ...state,
        data: action.payload.data,
        count: action.payload.meta_attributes
          ? action.payload.meta_attributes.total_count
          : 1,
        loading: false
      };
    case GET_QUOTEITEMS_FAIL:
      return { ...state, error: action.payload, loading: false };
    case 'CLEAR_ERROR':
      return { ...state, error: '', message: '', printFile: '' };
    case DELETE_QUOTEITEM:
      return { ...state, loading: true };
    case DELETE_QUOTEITEM_SUCCESS:
      return { ...state, message: action.payload.message, loading: false };
    case DELETE_QUOTEITEM_FAIL:
      return { ...state, error: action.payload, loading: false };
    case PRINT_QUOTE:
      return { ...state, loading: true };
    case PRINT_QUOTE_SUCCESS:
      return {
        ...state,
        message: action.payload.message,
        loading: false,
        printFile: action.payload.data
      };
    case PRINT_QUOTE_FAIL:
      return { ...state, error: action.payload, loading: false };
    case CHANGE_QUOTEITEMS_PAGER:
      return { ...state, perPage: action.payload };
    case CHANGE_QUOTEITEMS_PAGE:
      return { ...state, page: action.payload };
    default:
      return state;
  }
};

export default quoteItems;
