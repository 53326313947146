import { apiService } from '../../middleware/api';

export const FORM_SUBMIT = 'FORM_SUBMIT';
export const FORM_SUBMIT_SUCCESS = 'FORM_SUBMIT_SUCCESS';
export const FORM_SUBMIT_FAIL = 'FORM_SUBMIT_FAIL';
export const CLEAR_FORM = 'CLEAR_FORM';

export const onFormSubmit = (
  endPoint: string,
  method: 'post' | 'put' = 'post',
  data: {},
  formData = false
) => {
  return apiService(endPoint, method, FORM_SUBMIT, data, formData);
};

export const onClearForm = () => ({ type: CLEAR_FORM });
