import { FORM_EDIT_DATA } from 'actions';
import { apiService } from 'middleware/api';
import {
  ADD_EXISTING_CONTACT,
  CHANGE_CONTACTS_PAGE,
  CHANGE_CONTACTS_PAGER,
  CHANGE_LIST_PAGE,
  DELETE_CONTACT,
  GET_ALL_CONTACTS,
  GET_CONTACTS
} from '../constants';

export const onGetContacts = (
  companyId: number,
  page = 1,
  perPage = 10,
  keyword = '',
  email = ''
) => {
  const endPoint = `companies/${companyId}/contacts.json?page=${page}&per_page=${perPage}&keyword=${keyword}&email=${email}`;
  const method = 'get';
  return apiService(endPoint, method, GET_CONTACTS);
};

export const onGetContact = (id: string) => {
  const endPoint = `contacts/${id}.json`;
  const method = 'get';
  return apiService(endPoint, method, FORM_EDIT_DATA);
};

export const onDeleteContact = (id: string) => {
  const endPoint = `contacts/${id}.json`;
  const method = 'delete';
  return apiService(endPoint, method, DELETE_CONTACT);
};

export const onChangeContactsPerPage = (perPage: number) => ({
  type: CHANGE_CONTACTS_PAGER,
  payload: perPage
});

export const onChangeContactsPage = (page: number) => ({
  type: CHANGE_CONTACTS_PAGE,
  payload: page
});

export const onGetAllContacts = (
  companyId: number,
  page = 1,
  perPage = 10,
  keyword = ''
) => {
  const endPoint = `companies/${companyId}/contacts.json?page=${page}&per_page=${perPage}&keyword=${keyword}&type=all`;
  const method = 'get';
  return apiService(endPoint, method, GET_ALL_CONTACTS);
};

export const onChangeListPage = (page: number) => ({
  type: CHANGE_LIST_PAGE,
  payload: page
});

export const onChangeCompany = (companyId: string, id: number) => {
  const endPoint = `contacts/${id}.json?company_id=${companyId}`;
  const method = 'put';
  return apiService(endPoint, method, ADD_EXISTING_CONTACT);
};
