import React from 'react';
import { Edit as EditIcon } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory, useLocation } from 'react-router-dom';

import { MouseNChangeEvent } from './types';

const ListEditButton = ({ loading, name, disabled }: ListEditProps) => {
  const isDisable = loading || disabled || false;
  const { pathname, search } = useLocation();
  const { replace } = useHistory();
  const classes = useStyles(isDisable);
  return (
    <EditIcon
      className={`mr5 ${classes.edit}`}
      key={name}
      onClick={(e: MouseNChangeEvent) => {
        e.stopPropagation();

        if (isDisable) return null;

        replace(
          `${pathname + search}${search.length ? '&' : '?'}edit=${
            e.target.parentNode.parentNode.parentNode.id
          }&formname=${name}`
        );
      }}
    />
  );
};

export default React.memo(ListEditButton);

const useStyles = makeStyles(theme => ({
  edit: {
    color: isDisable =>
      isDisable ? theme.palette.grey[500] : theme.palette.info.dark,
    cursor: isDisable => (isDisable ? 'not-allowed' : 'pointer')
  }
}));

interface ListEditProps {
  loading: boolean;
  name: string;
  disabled?: boolean;
}
