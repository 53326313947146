export const GET_TEMPLATE_TASKS = 'GET_TEMPLATE_TASKS';
export const GET_TEMPLATE_TASKS_SUCCESS = 'GET_TEMPLATE_TASKS_SUCCESS';
export const GET_TEMPLATE_TASKS_FAIL = 'GET_TEMPLATE_TASKS_FAIL';

export const DELETE_TEMPLATE_TASK = 'DELETE_TEMPLATE_TASK';
export const DELETE_TEMPLATE_TASK_SUCCESS = 'DELETE_TEMPLATE_TASK_SUCCESS';
export const DELETE_TEMPLATE_TASK_FAIL = 'DELETE_TEMPLATE_TASK_FAIL';

export const CHANGE_TEMPLATE_TASKS_PAGER = 'CHANGE_TEMPLATE_TASKS_PAGER';
export const CHANGE_TEMPLATE_TASKS_PAGE = 'CHANGE_TEMPLATE_TASKS_PAGE';
