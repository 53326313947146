import { Button, Grid, TextField } from '@material-ui/core';
import { Add } from '@material-ui/icons';
import {
  onExportWorklogs,
  onGetCompanies,
  onGetProjects,
  onGetReport,
  onGetTasks
} from 'actions';
import AsyncSearch from 'app/Common/AsyncSearch';
import CustomDatePicker from 'app/Common/CustomDatePicker';
import CustomDialog from 'app/Common/CustomDialog';
import FieldChangeButton from 'app/Common/FieldChangeButton';
import Tasks from 'app/Dashboard/Tasks/components';
import clsx from 'clsx';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { dateRange } from 'utils/common/dateRange';
import { dateYMDFormat } from 'utils/common/dateYMDFormat';
import { statePropTypes, WorkLogsTopbarProps } from '../types';

const WorkLogsTopbar = ({
  loading,
  setForm,
  taskId,
  projectId,
  companySearchId,
  setProjectSearchId,
  setTaskSearchId,
  setSelectTaskId,
  setCompanySearchId,
  projectSearchId,
  mainListing,
  companyName,
  taskDetail,
  listView,
  setListEndDate,
  listEndDate,
  listViewStartDate,
  weekViewStartDate,
  setListViewStartDate,
  setWeekViewStartDate,
  isCreateWorklog,
  showTasks,
  setShowTasks
}: WorkLogsTopbarProps) => {
  const dispatch = useDispatch();

  const primaryColor = localStorage.getItem('primary_color');

  const { tasks, projects, companies } = useSelector(
    (state: statePropTypes) => state
  );

  const [isExportWorklogs, setIsExportWorklogs] = useState(false);
  const [startDate, setStartDate] = useState<Date | null | string>(
    dateYMDFormat(new Date())
  );
  const [endDate, setEndDate] = useState<Date | null | string>(
    dateYMDFormat(dateRange(new Date(), +7))
  );

  const companySearcher = mainListing && (
    <Grid item sm={3}>
      <AsyncSearch
        data={[{ name: 'Show All', id: -1 }, ...companies.data]}
        getHandle={(page, perPage, keyword) =>
          dispatch(onGetCompanies(page, perPage, keyword))
        }
        selectHandle={e =>
          setCompanySearchId(
            e,
            companies.data.find(v => v.id === Number(e))?.name ?? ''
          )
        }
        loading={loading}
        label={companySearchId ? companyName ?? '' : 'Search'}
        renderOption
      />
    </Grid>
  );
  const taskSearcher = projectId && (
    <Grid item sm={3}>
      <AsyncSearch
        data={tasks.data}
        getHandle={(page, perPage, keyword) =>
          dispatch(onGetTasks(projectId, page, perPage, keyword))
        }
        selectHandle={e => setTaskSearchId(e)}
        loading={loading}
        keyword='title'
        label='Search'
      />
    </Grid>
  );
  const projectSearcher = companySearchId && !projectId && !taskId && (
    <Grid item sm={3} className={clsx([mainListing && 'pl5', 'pr5'])}>
      <AsyncSearch
        data={projects.data}
        getHandle={(page, perPage, keyword) =>
          dispatch(
            onGetProjects(page, perPage, keyword, Number(companySearchId))
          )
        }
        selectHandle={e => setProjectSearchId(e)}
        loading={loading}
        label='Search'
      />
    </Grid>
  );
  const exportItems = [
    { value: 'reports', name: 'Reports', color: '#1976d2', label: 'Reports' },
    { value: 'worklogs', name: 'Worklogs', color: '#1976d2', label: 'Worklogs' }
  ];
  const dates = [
    { title: 'Start date', value: startDate, setter: setStartDate },
    { title: 'End date', value: endDate, setter: setEndDate }
  ];
  return (
    <Grid
      container
      className='mt10 mb10'
      justify='space-between'
      alignItems='flex-end'
    >
      {!isCreateWorklog && (
        <Grid item container md={8}>
          <TextField
            type='date'
            value={listView ? listViewStartDate : weekViewStartDate}
            className='mr5'
            label='Start Date'
            InputLabelProps={{ shrink: true }}
            onChange={e =>
              listView
                ? setListViewStartDate(e.target.value)
                : setWeekViewStartDate(e.target.value)
            }
            inputProps={{ max: listEndDate }}
          />
          <TextField
            type='date'
            value={listEndDate}
            onChange={e => setListEndDate(e.target.value)}
            className='mr5'
            label='End Date'
            disabled={!listView || !listViewStartDate.length}
            inputProps={{ min: listViewStartDate }}
            InputLabelProps={{ shrink: true }}
          />
          {companySearcher}
          {projectSearcher}
          {taskSearcher}
        </Grid>
      )}

      {(taskId ||
        projectId ||
        (mainListing && companySearchId && projectSearchId)) && (
        <Grid item md={isCreateWorklog ? 12 : 4} container justify='flex-end'>
          {projectId && (
            <FieldChangeButton
              loading={loading}
              onChange={({ status }) =>
                status === 'worklogs'
                  ? setIsExportWorklogs(true)
                  : dispatch(onGetReport(projectId))
              }
              field='name'
              title='Select One'
              data={{ value: 'something', name: 'Export' }}
              list={exportItems}
              backgroundColor={primaryColor}
            />
          )}
          <Button
            size='small'
            className='ml5'
            color='primary'
            variant='contained'
            disabled={loading}
            onClick={() =>
              projectId || projectSearchId ? setShowTasks(true) : setForm(true)
            }
          >
            <Add /> Work Log
          </Button>
        </Grid>
      )}
      {showTasks && (
        <CustomDialog
          open={true}
          closeHandle={() => setShowTasks(false)}
          loading={false}
          footer='hide'
          heading='Tasks'
        >
          <Tasks
            listing
            selectTaskId={setSelectTaskId}
            projectSearchId={projectSearchId}
            mainListing={mainListing}
          />
        </CustomDialog>
      )}
      {isExportWorklogs && (
        <CustomDialog
          open={true}
          closeHandle={() => setIsExportWorklogs(false)}
          okButtonText='Export'
          loading={loading}
          okHandle={() => {
            dispatch(onExportWorklogs(projectId, startDate, endDate));
            setIsExportWorklogs(false);
          }}
          heading='Export Worklogs'
        >
          <Grid container spacing={2}>
            {dates.map(val => (
              <Grid item sm={6}>
                <CustomDatePicker
                  isMinDate={false}
                  key={val.title}
                  {...val}
                  fullWidth
                />
              </Grid>
            ))}
          </Grid>
        </CustomDialog>
      )}
    </Grid>
  );
};

export default React.memo(WorkLogsTopbar);
