import * as Yup from 'yup';

export const taskValidation = Yup.object({
  title: Yup.string().required().label('Title'),
  estimated_hour: Yup.string()
    .matches(
      /^[0-9]\d?(?:\.\d{0,2})?$/,
      'Time Estimate cannot have more than 2 decimal places'
    )
    .min(0)
});
