import { makeStyles } from '@material-ui/core/styles';

const drawerWidth = 240;
export const useStyles = makeStyles(theme => ({
  appBar: {
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    })
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      marginLeft: 0
    },
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  menuButton: { marginRight: theme.spacing(1) },
  hide: { display: 'none' },
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingLeft: 8,
    paddingRight: 8
  },
  infoBar: { display: 'flex', alignItems: 'center' },
  avatar: {
    width: 34,
    height: 33
  },
  list: {
    width: 160,
    padding: 0,
    textAlign: 'center',
    '& li': { paddingTop: 8, paddingLeft: 20 }
  },
  orgMenuItem: { minWidth: 100 },
  disableOrg: {
    color: theme.palette.grey[500],
    '& .MuiChip-root': {
      background: theme.palette.error.dark,
      color: '#fff',
      height: 20,
      fontSize: 10,
      marginLeft: 2
    }
  },
  notice: {
    width: 200,
    textAlign: 'center',
    height: 30,
    color: 'grey'
  },
  listNotifications: {
    width: 300,
    textAlign: 'inherit',
    paddingBottom: '2px'
  },
  time: {
    fontSize: '12px',
    color: '#808080d6',
    borderBottom: '1px solid #E2E2E2',
    paddingLeft: '15px',
    paddingBottom: '10px'
  },
  popoverNotification: {
    height: '400px',
    top: '70px'
  },
  alertBar: {
    width: '300px',
    cursor: 'pointer'
  },
  menu: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  planName: { textTransform: 'capitalize' },
  dialog: { width: '100%' }
}));
