import auth from 'app/Auth/redux/reducers/auth';
import acceptInvite from 'app/Dashboard/AcceptInvite/redux/reducers';
import invites from 'app/Dashboard/Admin-Settings/Invites/redux/reducers';
import users from 'app/Dashboard/Admin-Settings/Users/redux/reducers';
import attachments from 'app/Dashboard/Attachments/redux/reducers';
import companies from 'app/Dashboard/Companies/redux/reducers';
import company from 'app/Dashboard/Company/redux/reducers';
import contacts from 'app/Dashboard/Contacts/redux/reducers';
import disclaimers from 'app/Dashboard/Disclaimers/redux/reducers';
import expenses from 'app/Dashboard/Expenses/components/redux/reducers';
import upcomingTasks from 'app/Dashboard/Home/redux/reducers';
import notes from 'app/Dashboard/Notes/redux/reducers';
import organizations from 'app/Dashboard/Organizations/redux/reducers';
import products from 'app/Dashboard/Products/redux/reducers';
import project from 'app/Dashboard/Project/redux/reducers';
import projects from 'app/Dashboard/Projects/redux/reducers';
import quoteItems from 'app/Dashboard/QuoteItems/redux/reducers';
import quotes from 'app/Dashboard/Quotes/redux/reducers';
import expenseCategories from 'app/Dashboard/Setup/Expense-Category/redux/reducers';
import categories from 'app/Dashboard/Setup/Product-Categories/redux/reducers';
import projectStatuses from 'app/Dashboard/Setup/Project-Status/redux/reducers';
import projectTemplates from 'app/Dashboard/Setup/Project-Templates/redux/reducers';
import templateTasks from 'app/Dashboard/Setup/Project-Templates/TemplateTasks/redux/reducers';
import projectTypes from 'app/Dashboard/Setup/Project-Types/redux/reducers';
import taskServices from 'app/Dashboard/Setup/TaskServices/redux/reducers';
import taskStatuses from 'app/Dashboard/Setup/TaskStatus/redux/reducers';
import taxCodes from 'app/Dashboard/Setup/TaxCodes/redux/reducers';
import taxes from 'app/Dashboard/Setup/Taxes/redux/reducers';
import theme from 'app/Dashboard/Setup/ThemeCustomize/redux/reducers';
import subscriptionItems from 'app/Dashboard/SubscriptionItems/redux/reducers';
import subscriptions from 'app/Dashboard/Subscriptions/redux/reducers';
import childTasks from 'app/Dashboard/SubTask/redux/reducers';
import tasks from 'app/Dashboard/Tasks/redux/reducers';
import assigneeTasks from 'app/Dashboard/Tasks/redux/reducers/assigneeTaskReducer';
import timeCard from 'app/Dashboard/Time-Card/redux/reducers';
import workLogs from 'app/Dashboard/WorkLogs/redux/reducers';
import acceptQuote from 'app/UnAuthorize/AcceptQuote/redux/reducers';
import { combineReducers } from 'redux';
import formData from './Hooks/formEditReducer';
import form from './Hooks/formReducer';
import reminders from 'app/Dashboard/Reminders/redux/reducers';
import notifications from 'app/Dashboard/Notfications/redux/reducers';
import plans from 'app/Dashboard/components/Appbar/redux/reducers';
import planSubscriptions from 'app/Dashboard/StripeCard/redux/reducers';
import invoices from 'app/Dashboard/SubscriptionModule/redux/reducers';
import cyberSecurity from 'app/Dashboard/CyberSecurityForm/redux/reducers';
import newhireautomation from 'app/Dashboard/NewHireAutomation/redux/reducers';

const appReducer = combineReducers({
  auth,
  companies,
  form,
  formData,
  organizations,
  company,
  contacts,
  products,
  categories,
  projectTypes,
  projectStatuses,
  users,
  invites,
  notes,
  projects,
  project,
  tasks,
  assigneeTasks,
  workLogs,
  acceptInvite,
  theme,
  taxCodes,
  taxes,
  quotes,
  quoteItems,
  acceptQuote,
  expenseCategories,
  expenses,
  projectTemplates,
  templateTasks,
  childTasks,
  taskServices,
  attachments,
  upcomingTasks,
  subscriptions,
  subscriptionItems,
  timeCard,
  taskStatuses,
  disclaimers,
  reminders,
  notifications,
  plans,
  planSubscriptions,
  invoices,
  cyberSecurity,
  newhireautomation
});

const rootReducer = (state, action) => {
  if (action.type === 'LOGOUT') state = undefined;
  return appReducer(state, action);
};

export default rootReducer;
