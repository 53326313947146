import { FORM_EDIT_DATA } from 'actions';
import { apiService } from 'middleware/api';
import {
  CHANGE_TASK_SERVICES_PAGE,
  CHANGE_TASK_SERVICES_PAGER,
  DELETE_TASK_SERVICE,
  GET_TASK_SERVICES
} from '../constants';

export const onGetTaskServices = (page = 1, perPage = 10, keyword = '') => {
  const endPoint = `task_services.json?page=${page}&per_page=${perPage}&keyword=${keyword}`;
  const method = 'get';
  return apiService(endPoint, method, GET_TASK_SERVICES);
};

export const onChangeServicesPerPage = (perPage: number) => ({
  type: CHANGE_TASK_SERVICES_PAGER,
  payload: perPage
});

export const onChangeServicesPage = (page: number) => ({
  type: CHANGE_TASK_SERVICES_PAGE,
  payload: page
});

export const onGetTaskService = (key: string) => {
  const endPoint = `task_services/${key}.json`;
  const method = 'get';
  return apiService(endPoint, method, FORM_EDIT_DATA);
};

export const onDeleteTaskService = (key: string) => {
  const endPoint = `task_services/${key}.json`;
  const method = 'delete';
  return apiService(endPoint, method, DELETE_TASK_SERVICE);
};
