import React from 'react';
import { useFormik } from 'formik';
import { Grid } from '@material-ui/core';

import { useForm } from 'app/Common/Hooks/useForm';
import CustomDialog from 'app/Common/CustomDialog';
import CustomAlertMsg from 'app/Common/CustomAlertMsg';
import InputField from 'app/Common/InputFields';
import FormButtons from 'app/Common/FormButtons';
import { expenseCategoryFields } from 'assets/static/forms/setup/expenseCategoryFields';
import { categoryValidation } from 'validate/setup/categoryValidation';
import { ExpenseCategoriesFormProps } from '../types';

const CategoriesForm = ({ form, closeForm, expenseCategory }: ExpenseCategoriesFormProps) => {
    const formHook = useForm();
    const { id } = expenseCategory;
    const user = JSON.parse(localStorage.getItem('user') || '')
    const formik = useFormik({
        initialValues: {
            name: expenseCategory.name ?? '',
            description: expenseCategory.description ?? ''
        },
        validationSchema: categoryValidation,
        onSubmit: values => {
            const data = { ...values, organization_id: user?.current_tenant?.id };
            id
                ? formHook.onSubmitPut(`expense_categories/${id}.json`, data)
                : formHook.onSubmitPost(`expense_categories.json`, data);
        }
    });

    const closeAlertHandle = () => {
        if (formHook.status === 'error') return;
        closeForm(true);
    };

    return (
        <>
            <CustomAlertMsg
                type={formHook.status}
                message={formHook.message}
                onClose={closeAlertHandle}
            />
            <CustomDialog
                open={form}
                closeHandle={() => {
                    if (formHook.loading) return;
                    closeForm(false);
                }}
                loading={formHook.loading}
                heading={id ? 'Update Expense Category' : 'Create New Expense Category'}
                footer='hide'
            >
                <Grid
                    container
                    spacing={2}
                    component='form'
                    onSubmit={formik.handleSubmit}
                    className='mt10'
                >
                    {expenseCategoryFields.map(({ value, label }, index) => (
                        <InputField
                            formik={formik}
                            value={value}
                            label={label}
                            key={value}
                            autoFocus={index === 0}
                            fullWidth={true}
                            gridSize={12}
                            multiline={value === 'description' ? true : false}
                        />
                    ))}
                    <FormButtons
                        id={id}
                        loading={formHook.loading}
                        onCancel={() => closeForm(false)}
                    />
                </Grid>
            </CustomDialog>
        </>
    );
};

export default React.memo(CategoriesForm);
