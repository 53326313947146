import React, { ReactElement } from 'react';
import { useDispatch } from 'react-redux';
import { Snackbar, SnackbarContent } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

type anchorOrigin = {
  horizontal: 'center' | 'left' | 'right';
  vertical: 'bottom' | 'top';
};
interface CustomAlertMsgProps {
  message: string;
  onClose?: () => void;
  type: 'error' | 'success';
  placement?: anchorOrigin;
  minWidth?: string;
}

const CustomAlertMsg = ({
  message,
  onClose,
  type,
  placement,
  minWidth
}: CustomAlertMsgProps): ReactElement => {
  const dispatch = useDispatch();
  const useStyles = makeStyles(({ palette: { error, success } }) => ({
    msgBackground: {
      backgroundColor: type === 'error' ? error.main : success.main
    },
    min: {
      minWidth: '146px',
      backgroundColor: type === 'error' ? error.main : success.main,
      display: 'flex',
      justifyContent: 'center'
    }
  }));
  const classes = useStyles();
  const closeHandle = () => {
    onClose && onClose();
    dispatch({ type: 'CLEAR_ERROR' });
  };
  return (
    <Snackbar
      anchorOrigin={placement ?? { vertical: 'top', horizontal: 'center' }}
      open={message ? true : false}
      onClose={closeHandle}
      autoHideDuration={type === 'error' ? 2000 : 1000}
      message={<span>{message}</span>}
      className={minWidth ? classes.min : ''}
    >
      <SnackbarContent
        className={minWidth ? classes.min : classes.msgBackground}
        message={<span>{message}</span>}
      />
    </Snackbar>
  );
};

export default React.memo(CustomAlertMsg);
