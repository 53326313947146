import React, { useState, ReactElement, ChangeEvent } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { TextField, InputAdornment, useMediaQuery } from '@material-ui/core';
import { Search as SearchIcon, Cancel as CancelIcon } from '@material-ui/icons';

const ListSearcher = ({
  searchTextHandle,
  width150,
  loading,
  placeholder,
  width100
}: ListSearcherProps): ReactElement => {
  const [searchText, setSearchText] = useState('');
  const searchHandler = (e: InputEvent) => {
    const { value } = e.target as HTMLInputElement;
    setSearchText(value);
    (value.length === 0 || value.length > 2) &&
      searchTextHandle(e.target.value);
  };
  const clearSearchHandle = () => {
    if (loading) return;
    searchText.length > 2 && searchTextHandle('');
    setSearchText('');
  };
  const classes = useStyles();

  const isMobile = useMediaQuery('(max-width:500px)');

  return (
    <TextField
      label={
        searchText.length && searchText.length < 3 ? 'Min 3 Characters' : ' '
      }
      className={`${
        isMobile ? classes.width100 : width150 ? classes.width150 : ''
      }`}
      value={searchText}
      placeholder={placeholder ? placeholder : 'Search...'}
      onChange={searchHandler}
      InputProps={{
        startAdornment: (
          <InputAdornment position='start'>
            <SearchIcon />
          </InputAdornment>
        ),
        endAdornment: searchText.length ? (
          <InputAdornment position='end'>
            <CancelIcon
              className={classes.pointer}
              fontSize='small'
              color='disabled'
              onClick={clearSearchHandle}
            />
          </InputAdornment>
        ) : null
      }}
    />
  );
};

export default ListSearcher;

const useStyles = makeStyles({
  width150: { width: 150 },
  width100: { width: '100%' },
  pointer: { cursor: 'pointer' }
});

interface ListSearcherProps {
  searchTextHandle: (value: string) => void;
  width150?: boolean;
  width100?: boolean;
  loading: boolean;
  placeholder?: string;
}
type InputEvent = ChangeEvent<HTMLInputElement>;
