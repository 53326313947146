import {
  GET_INVITES,
  GET_INVITES_FAIL,
  GET_INVITES_SUCCESS,
  DELETE_INVITE,
  DELETE_INVITE_FAIL,
  DELETE_INVITE_SUCCESS,
  RE_INVITE,
  RE_INVITE_FAIL,
  RE_INVITE_SUCCESS,
  CHANGE_INVITES_PAGER,
  CHANGE_INVITES_PAGE,
  GET_PENDING_INVITES,
  GET_PENDING_INVITES_FAIL,
  GET_PENDING_INVITES_SUCCESS,
  GET_INVITE_APPROVAL,
  GET_INVITE_APPROVAL_FAIL,
  GET_INVITE_APPROVAL_SUCCESS,
  GET_INVITE_ACCEPT_APPROVAL,
  GET_INVITE_ACCEPT_APPROVAL_FAIL,
  GET_INVITE_ACCEPT_APPROVAL_SUCCESS,
  GET_INVITE_REJECT_APPROVAL,
  GET_INVITE_REJECT_APPROVAL_FAIL,
  GET_INVITE_REJECT_APPROVAL_SUCCESS,
  SEND_MS_TOKEN,
  SEND_MS_TOKEN_SUCCESS,
  SEND_MS_TOKEN_FAIL,
  DISCONNECT_MS_TOKEN,
  DISCONNECT_MS_TOKEN_SUCCESS,
  DISCONNECT_MS_TOKEN_FAIL
} from 'constants/index';

const initial = {
  data: [],
  loading: false,
  error: '',
  message: '',
  page: 1,
  perPage: 10,
  count: 1,
  inviteData: {}
};

const invites = (state = initial, action: { type: string; payload: any }) => {
  switch (action.type) {
    case GET_INVITES:
      return { ...state, loading: true };
    case GET_INVITES_SUCCESS:
      return {
        ...state,
        data: action.payload.data,
        count: action.payload.meta_attributes
          ? action.payload.meta_attributes.total_count
          : 1,
        loading: false
      };
    case GET_INVITES_FAIL:
      return { ...state, error: action.payload, loading: false };
    case 'CLEAR_ERROR':
      return { ...state, error: '', message: '' };
    case DELETE_INVITE:
      return { ...state, loading: true };
    case DELETE_INVITE_SUCCESS:
      return { ...state, message: action.payload.message, loading: false };
    case DELETE_INVITE_FAIL:
      return { ...state, error: action.payload, loading: false };
    case RE_INVITE:
      return { ...state, loading: true };
    case RE_INVITE_SUCCESS:
      return { ...state, message: action.payload.message, loading: false };
    case RE_INVITE_FAIL:
      return { ...state, error: action.payload, loading: false };
    case GET_PENDING_INVITES:
      return { ...state, loading: true };
    case GET_PENDING_INVITES_SUCCESS:
      return {
        ...state,
        data: action.payload.data,
        count: action.payload.meta_attributes
          ? action.payload.meta_attributes.total_count
          : 1,
        loading: false
      };
    case GET_PENDING_INVITES_FAIL:
      return { ...state, error: action.payload, loading: false };
    case GET_INVITE_ACCEPT_APPROVAL:
      return { ...state, loading: true };
    case GET_INVITE_ACCEPT_APPROVAL_SUCCESS:
      return {
        ...state,
        inviteData: action.payload.data,
        count: action.payload.meta_attributes
          ? action.payload.meta_attributes.total_count
          : 1,
        loading: false
      };
    case GET_INVITE_ACCEPT_APPROVAL_FAIL:
      return { ...state, error: action.payload, loading: false };
    case GET_INVITE_APPROVAL:
      return { ...state, loading: true };
    case GET_INVITE_APPROVAL_SUCCESS:
      return {
        ...state,
        data: action.payload.data,
        count: action.payload.meta_attributes
          ? action.payload.meta_attributes.total_count
          : 1,
        loading: false
      };
    case GET_INVITE_APPROVAL_FAIL:
      return { ...state, error: action.payload, loading: false };
    case GET_INVITE_REJECT_APPROVAL:
      return { ...state, loading: true };
    case GET_INVITE_REJECT_APPROVAL_SUCCESS:
      return {
        ...state,
        inviteData: action.payload.data,
        count: action.payload.meta_attributes
          ? action.payload.meta_attributes.total_count
          : 1,
        loading: false
      };
    case GET_INVITE_REJECT_APPROVAL_FAIL:
      return { ...state, error: action.payload, loading: false };

    case CHANGE_INVITES_PAGER:
      return { ...state, perPage: action.payload };
    case CHANGE_INVITES_PAGE:
      return { ...state, page: action.payload };
    case SEND_MS_TOKEN:
      return { ...state, loading: true };
    case SEND_MS_TOKEN_SUCCESS:
      return {
        ...state,
        message: 'Microsoft connected successfully',
        loading: false
      };
    case SEND_MS_TOKEN_FAIL:
      return {
        ...state,
        error: action.payload ?? 'Not a valid code',
        loading: false
      };
    case DISCONNECT_MS_TOKEN:
      return { ...state, loading: true };
    case DISCONNECT_MS_TOKEN_SUCCESS:
      return {
        ...state,
        message: 'Microsoft diconnected successfully',
        loading: false
      };
    case DISCONNECT_MS_TOKEN_FAIL:
      return {
        ...state,
        error: action.payload ?? 'Something went wrong',
        loading: false
      };
    default:
      return state;
  }
};

export default invites;
