export const GET_UPCOMING_TASKS = 'GET_UPCOMING_TASKS';
export const GET_UPCOMING_TASKS_SUCCESS = 'GET_UPCOMING_TASKS_SUCCESS';
export const GET_UPCOMING_TASKS_FAIL = 'GET_UPCOMING_TASKS_FAIL';

export const CHANGE_UPCOMING_TASKS_PAGER = 'CHANGE_UPCOMING_TASKS_PAGER';
export const CHANGE_UPCOMING_TASKS_PAGE = 'CHANGE_UPCOMING_TASKS_PAGE';

export const GET_ACTIVITY_LOGS = 'GET_ACTIVITY_LOGS';
export const GET_ACTIVITY_LOGS_SUCCESS = 'GET_ACTIVITY_LOGS_SUCCESS';
export const GET_ACTIVITY_LOGS_FAIL = 'GET_ACTIVITY_LOGS_FAIL';
