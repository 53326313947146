import {
  GET_TAXES,
  DELETE_TAX,
  CHANGE_TAXES_PAGER,
  CHANGE_TAXES_PAGE,
  UPDATE_TAX_STATUS
} from 'constants/index';
import { apiService } from 'middleware/api';
import { FORM_EDIT_DATA } from 'actions';
import { taxStatusParam } from '../../types';

export const onGetTaxes = (
  page = 1,
  perPage = 10,
  keyword = '',
  activeOnly: boolean
) => {
  const endPoint = `taxes.json?page=${page}&per_page=${perPage}&keyword=${keyword}${
    activeOnly ? `&active=${activeOnly}` : ''
  }`;
  const method = 'get';
  return apiService(endPoint, method, GET_TAXES);
};

export const onGetTax = (id: number) => {
  const endPoint = `taxes/${id}.json`;
  const method = 'get';
  return apiService(endPoint, method, FORM_EDIT_DATA);
};

export const onDeleteTax = (id: number) => {
  const endPoint = `taxes/${id}.json`;
  const method = 'delete';
  return apiService(endPoint, method, DELETE_TAX);
};

export const onChangeTaxesPerPage = (perPage: number) => ({
  type: CHANGE_TAXES_PAGER,
  payload: perPage
});
export const onChangeTaxesPage = (page: number) => ({
  type: CHANGE_TAXES_PAGE,
  payload: page
});
export const onChangeTaxStatus = (id: number, data: taxStatusParam) => {
  const endPoint = `taxes/${id}`;
  const method = 'put';
  return apiService(endPoint, method, UPDATE_TAX_STATUS, data);
};
