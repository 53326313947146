export const GET_COMPANIES = 'GET_COMPANIES';
export const GET_COMPANIES_SUCCESS = 'GET_COMPANIES_SUCCESS';
export const GET_COMPANIES_FAIL = 'GET_COMPANIES_FAIL';
export const DELETE_COMPANY = 'DELETE_COMPANY';
export const DELETE_COMPANY_SUCCESS = 'DELETE_COMPANY_SUCCESS';
export const DELETE_COMPANY_FAIL = 'DELETE_COMPANY_FAIL';
export const CHANGE_COMPANIES_PAGER = 'CHANGE_COMPANIES_PAGER';
export const CHANGE_COMPANIES_PAGE = 'CHANGE_COMPANIES_PAGE';
export const CHANGE_COMPANY_STATUS = 'CHANGE_COMPANY_STATUS';
export const CHANGE_COMPANY_STATUS_SUCCESS = 'CHANGE_COMPANY_STATUS_SUCCESS';
export const CHANGE_COMPANY_STATUS_FAIL = 'CHANGE_COMPANY_STATUS_FAIL';
export const GET_COMPANIES_COUNT = 'GET_COMPANIES_COUNT';
export const GET_COMPANIES_COUNT_SUCCESS = 'GET_COMPANIES_COUNT_SUCCESS';
export const GET_COMPANIES_COUNT_FAIL = 'GET_COMPANIES_COUNT_FAIL';

export const GET_SALES_PERSONS = 'GET_SALES_PERSONS';
export const GET_SALES_PERSONS_SUCCESS = 'GET_SALES_PERSONS_SUCCESS';
export const GET_SALES_PERSONS_FAIL = 'GET_SALES_PERSONS_FAIL';

export const GET_COMPANY_CONTACTS = 'GET_COMPANY_CONTACTS';
export const GET_COMPANY_CONTACTS_SUCCESS = 'GET_COMPANY_CONTACTS_SUCCESS';
export const GET_COMPANY_CONTACTS_FAIL = 'GET_COMPANY_CONTACTS_FAIL';

export const CLEAR_COMPANY_CONTACTS = 'CLEAR_COMPANY_CONTACTS';

export const GET_ACCOUNT_TRANSFER = 'GET_ACCOUNT_TRANSFER';
export const GET_ACCOUNT_TRANSFER_SUCCESS = 'GET_ACCOUNT_TRANSFER_SUCCESS';
export const GET_ACCOUNT_TRANSFER_FAIL = 'GET_ACCOUNT_TRANSFER_FAIL';

export const GET_ACCOUNT_TRANSFER_WITH_STATUS =
  'GET_ACCOUNT_TRANSFER_WITH_STATUS';
export const GET_ACCOUNT_TRANSFER_WITH_STATUS_SUCCESS =
  'GET_ACCOUNT_TRANSFER_WITH_STATUS_SUCCESS';
export const GET_ACCOUNT_TRANSFER_WITH_STATUS_FAIL =
  'GET_ACCOUNT_TRANSFER_WITH_STATUS_FAIL';
