import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { CircularProgress, Switch } from '@material-ui/core';
import parse from 'html-react-parser';

import List from './List';
import TopBar from './Topbar';
import Form from './Form';
import {
  onChangeWorkLogsPerPage,
  onDeleteWorkLog,
  onGetWorkLogs,
  onGetWorkLog,
  onChangeWorkLogsPage,
  onGetTaskDetails
} from 'actions';
import CustomAlertMsg from 'app/Common/CustomAlertMsg';
import { useEditForm } from 'app/Common/Hooks/useEditForm';
import { WorkLogs as WorkLogsType, WorkLogsProps } from '../types';
import { dateRange } from 'utils/common/dateRange';
import { Context } from 'app/Dashboard';
import CustomTable from 'app/Common/CustomTable';
import ListDeleteButton from 'app/Common/ListDeleteButton';

const WorkLogs = ({
  taskId,
  projectId,
  companyId,
  mainListing,
  taskDetail,
  isCreateWorklog,
  closeForm
}: WorkLogsProps) => {
  const { setPageTitle } = useContext(Context);
  mainListing && setPageTitle('Work Logs');

  const params = useParams<{
    companyId: string;
    id: string;
    companyName: string;
  }>();
  const { push } = useHistory();
  const [searchText, setSearchText] = useState('');
  const [form, setForm] = useState(false);
  const [projectSearchId, setProjectSearchId] = useState('');
  const [taskSearchId, setTaskSearchId] = useState('');
  const [selectTaskId, setSelectTaskId] = useState('');
  const [companySearchId] = useState(params.companyId ?? companyId ?? '');
  const [listView, setListView] = useState(!taskId);
  const [weekViewStartDate, setWeekViewStartDate] = useState(
    dateRange(new Date(), -7)
  );
  const [listViewStartDate, setListViewStartDate] = useState('');
  const [listEndDate, setListEndDate] = useState('');
  const [showTasks, setShowTasks] = useState(false);

  const dispatch = useDispatch();
  const { data, loading, error, message, page, perPage, count, listViewData } =
    useSelector(({ workLogs }: { workLogs: WorkLogsType }) => workLogs);
  useEffect(() => {
    selectTaskId && setForm(true);
  }, [selectTaskId]);

  useEffect(() => {
    closeForm && setForm(false);
  }, [closeForm]);

  useEffect(() => {
    !form && setSelectTaskId('');
  }, [form]);
  const { id, formData, clearFormData, editLoading } = useEditForm(
    'workLog',
    onGetWorkLog,
    () => setForm(true)
  );

  const loadData = useCallback(() => {
    taskId && dispatch(onGetTaskDetails(Number(taskId)));
    dispatch(
      onGetWorkLogs(
        `${listView ? listViewStartDate : weekViewStartDate}`,
        listView ? listEndDate : dateRange(new Date(weekViewStartDate), 7),
        page,
        perPage,
        searchText,
        taskId || taskSearchId,
        projectId?.toString() || projectSearchId,
        companySearchId?.toString(),
        mainListing || projectId || companyId ? listView : false
      )
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    dispatch,
    perPage,
    page,
    searchText,
    taskId,
    projectId,
    companySearchId,
    listViewStartDate,
    weekViewStartDate,
    projectSearchId,
    taskSearchId,
    listView,
    listEndDate
  ]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  const closeFormHandle = (loadList: boolean) => {
    loadList && loadData();
    id && clearFormData();
    setForm(false);
    setShowTasks(false);
  };

  const companySearchIdHandle = (companyId: string, companyName: string) => {
    push(`/worklogs/${companyId}/${companyName}`);
  };
  const onCloseAlert = () => message.length && loadData();
  const renderSwitch = () => (
    <span>
      Week View
      <Switch
        onChange={e => setListView(e.target.checked)}
        checked={listView}
      />
      List View
    </span>
  );
  const updateList = listViewData.map(row => {
    row.note = row.description ? parse(row.description) : 'N/A';
    row.cost = row.cost ? row.cost : 'N/A';
    return row;
  });

  return (
    <>
      <CustomAlertMsg
        type={error.length ? 'error' : 'success'}
        message={error || message}
        onClose={onCloseAlert}
      />
      <TopBar
        mainListing={mainListing}
        companyId={companyId}
        companyName={params.companyName}
        taskId={taskId}
        projectId={projectId}
        companySearchId={companySearchId}
        loading={loading || (editLoading && !form)}
        setForm={setForm}
        setSearchText={setSearchText}
        listViewStartDate={listViewStartDate}
        weekViewStartDate={weekViewStartDate}
        setListViewStartDate={setListViewStartDate}
        setWeekViewStartDate={setWeekViewStartDate}
        setProjectSearchId={setProjectSearchId}
        setTaskSearchId={setTaskSearchId}
        setSelectTaskId={setSelectTaskId}
        setCompanySearchId={companySearchIdHandle}
        projectSearchId={projectSearchId}
        taskDetail={taskDetail}
        listView={listView}
        setListEndDate={setListEndDate}
        listEndDate={listEndDate}
        isCreateWorklog={isCreateWorklog}
        showTasks={showTasks}
        setShowTasks={setShowTasks}
      />
      {(mainListing || projectId || companyId) &&
        !isCreateWorklog &&
        renderSwitch()}
      {!isCreateWorklog &&
        (loading ? (
          <CircularProgress />
        ) : listView ? (
          <CustomTable
            rows={updateList}
            headItems={['Project Name', 'Day', 'Hours', 'Cost', 'Description']}
            columns={['project.name', 'date:day', 'duration', 'cost', 'note']}
            loading={false}
            perPage={updateList.length}
            page={1}
            hideSizer
            count={updateList.length}
            actions={row => [
              <ListDeleteButton
                loading={loading}
                onClickHandler={() =>
                  dispatch(onDeleteWorkLog(row.id.toString()))
                }
              />
            ]}
          />
        ) : (
          <List
            data={data}
            loading={loading || (editLoading && !form)}
            page={page}
            perPage={perPage}
            count={count}
            changePage={page => dispatch(onChangeWorkLogsPage(page))}
            changePerPageHandle={perPage => {
              dispatch(onChangeWorkLogsPage(1));
              dispatch(onChangeWorkLogsPerPage(perPage));
            }}
            onDeleteWorkLog={id => dispatch(onDeleteWorkLog(id))}
          />
        ))}

      {(taskId || selectTaskId) && form && (
        <Form
          taskId={taskId || selectTaskId}
          form={form}
          closeForm={closeFormHandle}
          data={formData}
          onGetWorkLog={(id: string) => dispatch(onGetWorkLog(id))}
          loading={loading}
          projectId={params.id ?? projectSearchId}
          taskDetail={taskDetail}
        />
      )}
    </>
  );
};

export default WorkLogs;
