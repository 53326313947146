import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => {
  return {
    content: {
      flexGrow: 1,
      marginTop: 60,
      padding: theme.spacing(1),
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen
      })
    },
    contentShift: {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen
      }),
      marginLeft: 240,
      [theme.breakpoints.down('sm')]: {
        marginLeft: 0
      }
    },
    logout: {
      position: 'absolute',
      top: '7%',
      left: '76%',
      border: '1px solid orange',
      width: '150px',
      height: '45px'
    }
  };
});
