import React from 'react';
import { Divider, Grid, IconButton, makeStyles } from '@material-ui/core';
import { Close } from '@material-ui/icons';

import { DialogHeadProps } from '../types/customDialog';

const DialogHead = ({ closeHandle, heading }: DialogHeadProps) => {
  const classes = useStyles();
  return (
    <Grid>
      <Grid
        container
        justify='space-between'
        alignItems='center'
        className={classes.head}
      >
        <span className={classes.heading}>{heading}</span>
        <IconButton edge='start' color='inherit' onClick={closeHandle}>
          <Close />
        </IconButton>
      </Grid>
      <Divider />
    </Grid>
  );
};

export default React.memo(DialogHead);

const useStyles = makeStyles(() => ({
  head: { padding: 10, paddingBottom: 0 },
  heading: { fontSize: 28 }
}));
