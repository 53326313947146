import {
  ADD_CHILD_TASK,
  ADD_CHILD_TASK_FAIL,
  ADD_CHILD_TASK_SUCCESS,
  DELETE_CHILD_TASK,
  DELETE_CHILD_TASK_FAIL,
  DELETE_CHILD_TASK_SUCCESS
} from '../constants.ts';

const initial = {
  data: [],
  loading: false,
  error: '',
  message: '',
  page: 1,
  perPage: 10,
  count: 1
};

const childTasks = (
  state = initial,
  action: { type: string; payload: any }
) => {
  switch (action.type) {
    case DELETE_CHILD_TASK:
      return { ...state, loading: true };
    case DELETE_CHILD_TASK_SUCCESS:
      return { ...state, loading: true, message: action.payload.message };
    case DELETE_CHILD_TASK_FAIL:
      return { ...state, loading: false, error: action.payload };

    case ADD_CHILD_TASK:
      return { ...state, loading: true };
    case ADD_CHILD_TASK_SUCCESS:
      return { ...state, loading: true, message: action.payload.message };
    case ADD_CHILD_TASK_FAIL:
      return { ...state, loading: false, error: action.payload };

    case 'CLEAR_ERROR':
      return { ...state, error: '', message: '' };
    default:
      return state;
  }
};
export default childTasks;
