import { TablePagination } from '@material-ui/core';
import React from 'react';
import { PaginationProps } from '../types/customTable';

const Pagination = ({
  hideSizer,
  headItems,
  count,
  perPage,
  page,
  className = '',
  onChangePageHandler,
  changePerPageHandle
}: PaginationProps) => {
  if (!count) return null;
  return (
    <TablePagination
      rowsPerPageOptions={
        hideSizer
          ? []
          : [10, 25, 50, 100, 500].filter(rowNum => rowNum <= count)
      }
      colSpan={headItems.length + 1}
      count={count}
      rowsPerPage={perPage}
      page={page - 1}
      SelectProps={{
        inputProps: { 'aria-label': 'rows per page' },
        native: true
      }}
      className={className}
      // @ts-ignore
      onChangePage={onChangePageHandler}
      onChangeRowsPerPage={e => changePerPageHandle?.(parseInt(e.target.value))}
    />
  );
};

export default Pagination;
