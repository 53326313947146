export const GET_CYBERSECUTIRYFORM = 'GET_CYBERSECUTIRYFORM';
export const GET_CYBERSECUTIRYFORM_SUCCESS = 'GET_CYBERSECUTIRYFORM_SUCCESS';
export const GET_CYBERSECUTIRYFORM_LISTING = 'GET_CYBERSECUTIRYFORM_LISTING';
export const GET_CYBERSECUTIRYFORM_LISTING_SUCCESS =
  'GET_CYBERSECUTIRYFORM_LISTING_SUCCESS';
export const DELETE_CYBERSECUTIRYFORM = 'DELETE_CYBERSECUTIRYFORM';
export const DELETE_CYBERSECUTIRYFORM_SUCCESS =
  'DELETE_CYBERSECUTIRYFORM_SUCCESS';
export const DELETE_CYBERSECUTIRYFORM_FAIL = 'DELETE_CYBERSECUTIRYFORM_FAIL';
export const CHANGE_CYBERSECUTIRYFORM_PAGE = 'CHANGE_CYBERSECUTIRYFORM_PAGE';
export const CHANGE_CYBERSECUTIRYFORM_PAGER = 'CHANGE_CYBERSECUTIRYFORM_PAGER';
export const GET_CYBERSECUTIRYFORM_QUESTIONS =
  'GET_CYBERSECUTIRYFORM_QUESTIONS';
export const GET_CYBERSECUTIRYFORM_QUESTIONS_SUCCESS =
  'GET_CYBERSECUTIRYFORM_QUESTIONS_SUCCESS';
export const GET_CYBERSECUTIRYFORM_QUESTIONS_FAIL =
  'GET_CYBERSECUTIRYFORM_QUESTIONS_FAIL';
export const CREATE_FEEDBACK = 'CREATE_FEEDBACK';
export const CREATE_FEEDBACK_SUCCESS = 'CREATE_FEEDBACK_SUCCESS';
export const CREATE_FEEDBACK_FAIL = 'CREATE_FEEDBACK_FAIL';
