import React, { ReactNode, ReactElement } from 'react';
import { Typography } from '@material-ui/core';

const Heading = ({ children }: { children: ReactNode }): ReactElement => (
  <Typography
    className='m10'
    variant='h6'
    component='h3'
    color='textSecondary'
    align='center'
  >
    {children}
  </Typography>
);

export default React.memo(Heading);
