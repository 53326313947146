import { Switch } from '@material-ui/core';
import {
  onChangeTasksPage,
  onChangeTasksPerPage,
  onChangeTaskStatus,
  onClearTaskDetails,
  onDeleteTask,
  onGetCurrentUserTasks,
  onGetTask,
  onGetTaskDetails,
  onGetTasks
} from 'actions';
import CustomAlertMsg from 'app/Common/CustomAlertMsg';
import { useEditForm } from 'app/Common/Hooks/useEditForm';
import { DynamicObject } from 'app/Common/types';
import { Context } from 'app/Dashboard';
import {
  onDeleteTaskStatus,
  onGetTaskStatuses
} from 'app/Dashboard/Setup/TaskStatus/redux/actions';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Tasks as TasksType, TasksProps } from '../types';
import Board from './Board';
import Form from './Form';
import List from './List';
import TopBar from './Topbar';
import ViewTask from './ViewTask';

const Tasks = ({
  listing,
  selectTaskId,
  projectSearchId,
  mainListing,
  isCreateChildTasks,
  currentTaskRow,
  isChildTask,
  closeForm
}: TasksProps) => {
  const { setPageTitle } = useContext(Context);
  mainListing && setPageTitle('Tasks');

  const [searchText, setSearchText] = useState('');
  const [form, setForm] = useState(false);
  const [statusId, setStatusId] = useState<number>();
  const [isAssignedTasks, setIsAssignedTasks] = useState(true);
  const [taskStatus, setTaskStatus] = useState('');
  const [sortBy, setSortBy] = useState<undefined | 'asc' | 'desc'>(undefined);
  const [listView, setListView] = useState(true);

  const dispatch = useDispatch();
  const { data, loading, error, message, page, perPage, count, task } =
    useSelector(({ tasks }: { tasks: TasksType }) => tasks);
  const { id, formData, clearFormData, editLoading } = useEditForm(
    'task',
    onGetTask,
    () => setForm(true)
  );

  const { data: StatusList, loading: StatusListLoading } = useSelector(
    ({ taskStatuses }: Record<any, any>) => taskStatuses
  );

  const user = JSON.parse(localStorage.getItem('user') || '');
  const params = useParams<{ id: string }>();
  const projectId = Number(params.id) || Number(projectSearchId);
  const loadData = useCallback(() => {
    if (mainListing)
      dispatch(
        onGetCurrentUserTasks(
          isAssignedTasks ? user.id : '',
          page,
          perPage,
          searchText,
          projectId,
          taskStatus,
          sortBy,
          statusId
        )
      );
    else dispatch(onGetTasks(projectId, page, perPage, searchText));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    dispatch,
    perPage,
    page,
    searchText,
    projectId,
    isAssignedTasks,
    taskStatus,
    sortBy,
    statusId
  ]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  const loadTaskStatuses = useCallback(() => {
    dispatch(onGetTaskStatuses(1, 30, ''));
  }, [dispatch]);

  useEffect(() => {
    loadTaskStatuses();
  }, [loadTaskStatuses]);

  useEffect(() => {
    closeForm && setForm(false);
  }, [closeForm]);

  const closeFormHandle = (loadList: boolean) => {
    loadList && loadData();
    id && clearFormData();
    setForm(false);
  };

  const onDeleteStatus = (id: string) => {
    dispatch(onDeleteTaskStatus(id));
  };

  const onCloseAlert = () => message.length && loadData();

  const handleSearchText = (text: string) => {
    setSearchText(text);
    dispatch(onChangeTasksPage(1));
  };
  const handleSorting = (headLabel: string) => {
    setSortBy(sortBy === 'asc' ? 'desc' : 'asc');
  };
  const renderSwitch = () => (
    <span>
      Board View
      <Switch
        onChange={e => setListView(e.target.checked)}
        checked={listView}
      />
      List View
    </span>
  );

  return (
    <>
      <CustomAlertMsg
        type={error.length ? 'error' : 'success'}
        message={error || message}
        onClose={onCloseAlert}
      />
      <TopBar
        loading={loading || (editLoading && !form)}
        setForm={setForm}
        setSearchText={handleSearchText}
        mainListing={mainListing}
        projectId={projectId}
        onAssignedTasks={() => setIsAssignedTasks(!isAssignedTasks)}
        isAssignedTasks={isAssignedTasks}
        isCreateChildTasks={isCreateChildTasks}
        taskStatus={taskStatus}
        setTaskStatus={setTaskStatus}
        setStatusId={setStatusId}
        isChildTask={isChildTask}
        statusList={StatusList}
      />
      {!isCreateChildTasks && renderSwitch()}
      {!isCreateChildTasks && listView && (
        <List
          listing={listing}
          data={data}
          loading={loading || (editLoading && !form)}
          page={page}
          perPage={perPage}
          count={count}
          changePage={page => dispatch(onChangeTasksPage(page))}
          changePerPageHandle={perPage => {
            dispatch(onChangeTasksPage(1));
            dispatch(onChangeTasksPerPage(perPage));
          }}
          onDeleteTask={id => dispatch(onDeleteTask(id))}
          onChangeTaskStatus={data => dispatch(onChangeTaskStatus(data))}
          showDetailsHandle={(e, row) =>
            selectTaskId
              ? selectTaskId(row.id.toString())
              : dispatch(onGetTaskDetails(row.id))
          }
          onSort={handleSorting}
          sortBy={sortBy}
          mainListing={mainListing}
          StatusList={StatusList}
          StatusListLoading={StatusListLoading}
        />
      )}
      {!listView && (
        <Board
          // @ts-ignore
          onDeleteStatus={onDeleteStatus}
          onUpdateStatus={card => dispatch(onChangeTaskStatus(card))}
          data={data}
          onCardDetail={(cardId: number) => dispatch(onGetTaskDetails(cardId))}
          statusList={StatusList}
        />
      )}
      {form && (
        <Form
          projectId={projectId}
          form={form}
          closeForm={closeFormHandle}
          data={isCreateChildTasks ? ({} as DynamicObject) : formData}
          onGetTask={(id: string) => dispatch(onGetTask(id))}
          loading={loading}
          currentTaskRow={currentTaskRow}
          StatusList={StatusList}
          StatusListLoading={StatusListLoading}
        />
      )}

      {!isCreateChildTasks && task.id ? (
        <ViewTask
          row={task}
          onClose={() => {
            loadData();
            clearFormData();
            dispatch(onClearTaskDetails());
          }}
          task={task}
          mainListing
        />
      ) : null}
    </>
  );
};

export default React.memo(Tasks);
