import React from 'react';
import { Grid, Button, Paper } from '@material-ui/core';
import { Close, Check } from '@material-ui/icons';
import { Alert } from '@material-ui/lab';

const Topbar = ({ setFormType, status, loading }: TopbarProps) => {
  const buttons = [
    { name: 'Accept', className: 'mr10', icon: <Check /> },
    { name: 'Reject', className: 'danger', icon: <Close /> }
  ];
  if (!status || loading) return null;
  if (status !== 'pending')
    return (
      <Paper>
        <Alert severity={status === 'accepted' ? 'success' : 'error'}>
          This quote has been {status}
        </Alert>
      </Paper>
    );
  return (
    <Grid className='p10' container justify='flex-end'>
      {buttons.map(({ name, className, icon }) => (
        <Button
          key={name}
          startIcon={icon}
          variant='contained'
          color='primary'
          className={className}
          onClick={() => setFormType(name.toLowerCase())}
        >
          {name}
        </Button>
      ))}
    </Grid>
  );
};

export default React.memo(Topbar);

interface TopbarProps {
  setFormType: (type: string) => void;
  status: string;
  loading: boolean;
}
