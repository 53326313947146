import React, { useCallback, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useFormik } from 'formik';
import { Grid, LinearProgress, TextField, Typography } from '@material-ui/core';
import EmojiEmotionsIcon from '@material-ui/icons/EmojiEmotions';

import AuthFormsButton from './AuthFormsButton';
import CustomAlertMsg from 'app/Common/CustomAlertMsg';
import InputField from 'app/Common/InputFields';
import { acceptNSignupValidation } from 'validate/auth/acceptNSignupValidation';
import { acceptNSignupFields } from 'assets/static/forms/auth/acceptNSignupFields';
import { paramsToObject } from 'utils/common/paramsToObject';
import { useStyles } from '../styles';
import { AcceptInviteFormProps } from '../types';

const AcceptNSignupForm = ({
  acceptNSignupHandle,
  loading,
  error,
  message,
  showInviteData,
  data,
  clearMsgHandler
}: AcceptInviteFormProps) => {
  const classes = useStyles();
  const { push } = useHistory();

  const { search } = useLocation();
  const obj = paramsToObject(search);
  const { first_name, last_name, email } = data;

  const loadData = useCallback(() => {
    showInviteData(obj.invitation_token);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    loadData();
  }, [loadData]);

  const formik = useFormik({
    initialValues: {
      password: '',
      password_confirmation: ''
    },
    validationSchema: acceptNSignupValidation,
    onSubmit: values => {
      acceptNSignupHandle(
        { ...values, first_name, last_name, email },
        obj.invitation_token
      );
    }
  });

  return (
    <div className={classes.container}>
      <Typography variant='h4' component='h2' gutterBottom align='center'>
        Please SignUp
      </Typography>
      <CustomAlertMsg
        type={error.length ? 'error' : 'success'}
        message={error || message}
        onClose={() => {
          clearMsgHandler();
          message.length && push('/');
        }}
      />
      <form onSubmit={formik.handleSubmit} className={classes.form}>
        {loading && <LinearProgress />}
        <EmojiEmotionsIcon
          fontSize='large'
          className={`${classes.icon}  ${classes.forgetIcon}`}
        />

        {[
          { label: 'Email', value: email ?? '' },
          { label: 'First Name', value: first_name ?? '' },
          { label: 'Last Name', value: last_name ?? '' }
        ].map(({ label, value }) => (
          <Grid sm={6} item>
            <TextField
              key={label}
              label={label}
              value={value}
              disabled
              fullWidth
              InputProps={{ className: classes.input }}
              className={classes.fields}
            />
          </Grid>
        ))}
        {acceptNSignupFields.map(({ label, value, type }, index) => (
          <InputField
            key={index}
            className={classes.fields}
            label={label}
            value={value}
            autoFocus={index === 0}
            disabled={loading}
            formik={formik}
            InputProps={{ className: classes.input }}
            autoComplete='new'
            type={type ?? 'text'}
          />
        ))}
        <div className={classes.authFormBottom}>
          <AuthFormsButton loading={loading} text='Accept & Signup' />
        </div>
      </form>
    </div>
  );
};

export default React.memo(AcceptNSignupForm);
