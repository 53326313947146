export const GET_EXPENSES = 'GET_EXPENSES';
export const GET_EXPENSES_SUCCESS = 'GET_EXPENSES_SUCCESS';
export const GET_EXPENSES_FAIL = 'GET_EXPENSES_FAIL';

export const CHANGE_EXPENSES_PAGER = 'CHANGE_EXPENSES_PAGER';
export const CHANGE_EXPENSES_PAGE = 'CHANGE_EXPENSES_PAGE';

export const DELETE_EXPENSE = 'DELETE_EXPENSE';
export const DELETE_EXPENSE_SUCCESS = 'DELETE_EXPENSE_SUCCESS';
export const DELETE_EXPENSE_FAIL = 'DELETE_EXPENSE_FAIL';
