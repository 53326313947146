import * as Yup from 'yup';

export const workLogValidation = Yup.object({
  day: Yup.string().required().label('Day'),
  duration: Yup.number()
    .min(0)
    .max(9999, 'Please enter valid hours')
    .required()
    .label('Hours')
});
