export const GET_TIME_CARDS = 'GET_TIME_CARDS';
export const GET_TIME_CARDS_SUCCESS = 'GET_TIME_CARDS_SUCCESS';
export const GET_TIME_CARDS_FAIL = 'GET_TIME_CARDS_FAIL';

export const GET_PROJECTS_LIST = 'GET_PROJECTS_LIST';
export const GET_PROJECTS_LIST_SUCCESS = 'GET_PROJECTS_LIST_SUCCESS';
export const GET_PROJECTS_LIST_FAIL = 'GET_PROJECTS_LIST_FAILL';

export const GET_TASKS_LIST = 'GET_TASKS_LIST';
export const GET_TASKS_LIST_SUCCESS = 'GET_TASKS_LIST_SUCCESS';
export const GET_TASKS_LIST_FAIL = 'GET_TASKS_LIST_FAIL';

export const ADDING_TIME_CARD = 'ADDING_TIME_CARD';
export const ADDING_TIME_CARD_SUCCESS = 'ADDING_TIME_CARD_SUCCESS';
export const ADDING_TIME_CARD_FAIL = 'ADDING_TIME_CARD_FAIL';

export const UPDATE_TIME_CARD = 'UPDATE_TIME_CARD';
export const UPDATE_TIME_CARD_SUCCESS = 'UPDATE_TIME_CARD_SUCCESS';
export const UPDATE_TIME_CARD_FAIL = 'UPDATE_TIME_CARD_FAIL';

export const ADD_TASK = 'ADD_TASK';
export const ADD_TASK_SUCCESS = 'ADD_TASK_SUCCESS';
export const ADD_TASK_FAIL = 'ADD_TASK_FAIL';

export const GET_SERVICES_LIST = 'GET_SERVICES_LIST';
export const GET_SERVICES_LIST_SUCCESS = 'GET_SERVICES_LIST_SUCCESS';
export const GET_SERVICES_LIST_FAIL = 'GET_SERVICES_LIST_FAIL';

export const CLEAR_MESSAGE_ERROR = 'CLEAR_MESSAGE_ERROR';
export const CLEAR_TASK_ID = 'CLEAR_TASK_ID';
