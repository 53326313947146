import {
  GET_INVITES,
  GET_PENDING_INVITES,
  DELETE_INVITE,
  CHANGE_INVITES_PAGER,
  CHANGE_INVITES_PAGE,
  RE_INVITE,
  GET_INVITE_APPROVAL,
  GET_INVITE_REJECT_APPROVAL,
  GET_INVITE_ACCEPT_APPROVAL,
  SEND_MS_TOKEN,
  DISCONNECT_MS_TOKEN
} from 'constants/index';
import { apiService } from 'middleware/api';
import { FORM_EDIT_DATA } from 'actions';
import { DynamicObject } from 'app/Common/types';

export const onGetPendingInvites = (page = 1, perPage = 10, keyword = '') => {
  const endPoint = `invites.json?page=${page}&per_page=${perPage}&keyword=${keyword}&status=pending`;
  const method = 'get';
  return apiService(endPoint, method, GET_PENDING_INVITES);
};

export const onGetInvites = (page = 1, perPage = 10) => {
  const endPoint = `invites.json?page=${page}&per_page=${perPage}`;
  const method = 'get';
  return apiService(endPoint, method, GET_INVITES);
};

export const onGetInvite = (key: string) => {
  const endPoint = `invites/${key}.json`;
  const method = 'get';
  return apiService(endPoint, method, FORM_EDIT_DATA);
};

export const onDeleteInvite = (key: string) => {
  const endPoint = `invites/${key}.json`;
  const method = 'delete';
  return apiService(endPoint, method, DELETE_INVITE);
};

export const onReInvite = (id: number) => {
  const endPoint = `invites/${id}/resend`;
  const method = 'post';
  return apiService(endPoint, method, RE_INVITE);
};

export const onGetInviteApprovals = (page = 1, perPage = 10, keyword = '') => {
  const endPoint = `invites/invite_approvals.json?page=${page}&per_page=${perPage}&keyword=${keyword}`;
  const method = 'get';
  return apiService(endPoint, method, GET_INVITE_APPROVAL);
};

export const onGetAcceptApproval = (id: number) => {
  const endPoint = `invites/${id}/accept_approval.json`;
  const method = 'get';
  return apiService(endPoint, method, GET_INVITE_ACCEPT_APPROVAL);
};

export const onGetRejectApproval = (id: number) => {
  const endPoint = `invites/${id}/reject_approval.json`;
  const method = 'get';
  return apiService(endPoint, method, GET_INVITE_REJECT_APPROVAL);
};

export const onChangeInvitesPerPage = (perPage: number) => ({
  type: CHANGE_INVITES_PAGER,
  payload: perPage
});

export const onChangeInvitesPage = (page: number) => ({
  type: CHANGE_INVITES_PAGE,
  payload: page
});

export const onSendMSToken = (data: DynamicObject) => {
  const endPoint = `integrations`;
  const method = 'post';
  return apiService(endPoint, method, SEND_MS_TOKEN, data);
};

export const onDisconnectMSToken = () => {
  const endPoint = 'integrations/disconnect';
  const method = 'delete';
  return apiService(endPoint, method, DISCONNECT_MS_TOKEN);
};
