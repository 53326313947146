import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useFormik } from 'formik';
import {
  Checkbox,
  FormControlLabel,
  Grid,
  TextField,
  Tooltip
} from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';

import { useForm } from 'app/Common/Hooks/useForm';
import CustomDialog from 'app/Common/CustomDialog';
import CustomAlertMsg from 'app/Common/CustomAlertMsg';
import InputField from 'app/Common/InputFields';
import FormButtons from 'app/Common/FormButtons';
import { workLogFields } from 'assets/static/forms/workLogFields';
import { workLogValidation } from 'validate/workLogValidation';
import { WorkLog, WorkLogsFormProps } from '../types';
import RichTextEditor from 'app/Common/RichTextEditor';
import {
  dateYMDFormat,
  dateHH_MMFormat,
  getTZ
} from 'utils/common/dateYMDFormat';

const WorkLogsForm = ({
  form,
  closeForm,
  data,
  taskId,
  projectId,
  taskDetail
}: WorkLogsFormProps) => {
  const workLog = data as WorkLog;
  const [description, setDescription] = useState('');
  const [isAddon, setisAddon] = useState(Number(workLog.cost) > 0);
  const formHook = useForm();
  const { companyId } = useParams<{ id: string; companyId: string }>();
  const formik = useFormik({
    initialValues: {
      day: dateYMDFormat(workLog?.day ?? new Date()),
      duration: workLog?.duration ?? '',
      cost: workLog?.cost ?? null
    },
    validationSchema: workLogValidation,
    onSubmit: values => {
      const data = {
        ...values,
        description,
        day: `${values.day} ${dateHH_MMFormat(new Date(), true)} ${getTZ(
          new Date()
        )}`,
        company_id: companyId ?? taskDetail?.company_id,
        project_id: taskDetail?.project_id ?? projectId,
        task_id: taskDetail?.id ?? taskId
      };
      formHook.onSubmitPost(`time_logs`, data);
    }
  });

  const closeAlertHandle = () => {
    if (formHook.status === 'error') return;
    closeForm(true);
  };

  const addOnNote =
    'Cost will only add to amount when checkbox will be checked';

  return (
    <CustomDialog
      open={form}
      closeHandle={() => {
        if (formHook.loading) return;
        closeForm(false);
      }}
      loading={formHook.loading}
      heading={'Create New Work Log'}
      footer='hide'
    >
      <CustomAlertMsg
        type={formHook.status}
        message={formHook.message}
        onClose={closeAlertHandle}
      />
      <Grid
        container
        spacing={2}
        component='form'
        onSubmit={formik.handleSubmit}
        className='mt10'
      >
        {workLogFields.map(({ value, label, type }, index) => (
          <InputField
            formik={formik}
            gridSize={6}
            type={type}
            value={value}
            label={label}
            key={value}
            autoFocus={index === 0}
            fullWidth={true}
          />
        ))}
        <Grid container item sm={12} justify='flex-start' alignItems='center'>
          <Grid item sm={3}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={isAddon}
                  onChange={(e, checked) => setisAddon(checked)}
                />
              }
              label='Add-On Cost'
            />
          </Grid>
          <Grid container alignItems='center' item sm={1}>
            <Tooltip title={addOnNote}>
              <InfoIcon fontSize='small' />
            </Tooltip>
          </Grid>
        </Grid>
        {isAddon && (
          <Grid item sm={6}>
            <TextField
              {...formik.getFieldProps('cost')}
              label='Cost'
              fullWidth
              type='number'
            />
          </Grid>
        )}

        <RichTextEditor
          description={description}
          setDescription={setDescription}
          title='* Description'
        />

        <FormButtons
          loading={formHook.loading}
          onCancel={() => closeForm(false)}
        />
      </Grid>
    </CustomDialog>
  );
};

export default React.memo(WorkLogsForm);
