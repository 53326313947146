export const GET_TASKS = 'GET_TASKS';
export const GET_TASKS_SUCCESS = 'GET_TASKS_SUCCESS';
export const GET_TASKS_FAIL = 'GET_TASKS_FAIL';

export const GET_ASSIGNEE_TASKS = 'GET_ASSIGNEE_TASKS';
export const GET_ASSIGNEE_TASKS_SUCCESS = 'GET_ASSIGNEE_TASKS_SUCCESS';
export const GET_ASSIGNEE_TASKS_FAIL = 'GET_ASSIGNEE_TASKS_FAIL';

export const DELETE_TASK = 'DELETE_TASK';
export const DELETE_TASK_SUCCESS = 'DELETE_TASK_SUCCESS';
export const DELETE_TASK_FAIL = 'DELETE_TASK_FAIL';
export const CHANGE_TASKS_PAGER = 'CHANGE_TASKS_PAGER';
export const CHANGE_TASKS_PAGE = 'CHANGE_TASKS_PAGE';
export const CHANGE_TASK_STATUS = 'CHANGE_TASK_STATUS';
export const CHANGE_TASK_STATUS_SUCCESS = 'CHANGE_TASK_STATUS_SUCCESS';
export const CHANGE_TASK_STATUS_FAIL = 'CHANGE_TASK_STATUS_FAIL';
export const GET_TASK_DETAILS = 'GET_TASK_DETAILS';
export const GET_TASK_DETAILS_SUCCESS = 'GET_TASK_DETAILS_SUCCESS';
export const GET_TASK_DETAILS_FAIL = 'GET_TASK_DETAILS_FAIL';
export const CLEAR_TASK_DETAILS = 'CLEAR_TASK_DETAILS';

export const GET_CHILD_TASKS = 'GET_CHILD_TASKS';
export const GET_CHILD_TASKS_SUCCESS = 'GET_CHILD_TASKS_SUCCESS';
export const GET_CHILD_TASKS_FAIL = 'GET_CHILD_TASKS_FAIL';

export const GET_CHILD_TASKS_DETAILS = 'GET_CHILD_TASKS_DETAILS';
export const GET_CHILD_TASKS_DETAILS_SUCCESS =
  'GET_CHILD_TASKS_DETAILS_SUCCESS';
export const GET_CHILD_TASKS_DETAILS_FAIL = 'GET_CHILD_TASKS_DETAILS_FAIL';

export const GET_USER_BOARD_TASKS = 'GET_USER_BOARD_TASKS';
export const GET_USER_BOARD_TASKS_SUCCESS = 'GET_USER_BOARD_TASKS_SUCCESS';
export const GET_USER_BOARD_TASKS_FAIL = 'GET_USER_BOARD_TASKS_FAIL';

export const CLEAR_ALL_TASKS = 'CLEAR_ALL_TASKS';
export const CLEAR_LATEST_TASKS = 'CLEAR_LATEST_TASKS';
export const CLEAR_USER_BOARD_TASKS = 'CLEAR_USER_BOARD_TASKS';
export const CLEAR_USER_LATEST_TASK = 'CLEAR_USER_LATEST_TASK';
