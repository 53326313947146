import { Switch } from '@material-ui/core';
import CustomTable from 'app/Common/CustomTable';
import { DynamicObject } from 'app/Common/types';
import WorkLogs from 'app/Dashboard/WorkLogs/components';
import React, { memo, useState } from 'react';

const WorkLogsList = ({
  task,
  row,
  message,
  updateList
}: WorkLogsListProps) => {
  const [listView, setListView] = useState(true);

  return (
    <>
      Week View
      <Switch
        onChange={e => setListView(e.target.checked)}
        checked={listView}
      />
      List View
      {listView ? (
        <>
          <WorkLogs
            taskId={row.id}
            taskDetail={task}
            isCreateWorklog={true}
            closeForm={!!message}
          />
          <CustomTable
            rows={updateList ?? []}
            headItems={[
              'Project Name',
              'Day',
              'Hours',
              'Rate/Cost',
              'Description'
            ]}
            columns={['projectName', 'day', 'duration', 'cost', 'note']}
            loading={false}
            perPage={0}
            page={1}
            count={0}
          />
        </>
      ) : (
        <WorkLogs taskId={row.id} taskDetail={task} closeForm={!!message} />
      )}
    </>
  );
};

export default memo(WorkLogsList);

interface WorkLogsListProps {
  task: DynamicObject;
  row: Record<string, any>;
  message: string;
  updateList: any;
}
