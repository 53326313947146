import React from 'react';
import { TableFooter, TableRow } from '@material-ui/core';

import Pagination from './Pagination';
import { CustomFooterProps } from '../types/customTable';
import { MouseClick } from '../types';

const CustomTableFooter = (props: CustomFooterProps) => {
  const { loading, topScroll = 0, changePageHandle, isScroller } = props;
  const onChangePageHandler = (e: MouseClick, changePage: number) => {
    isScroller && window.scrollTo({ top: topScroll, behavior: 'smooth' });
    changePageHandle && changePageHandle(e, changePage + 1);
  };

  if (loading) return null;
  return (
    <TableFooter>
      <TableRow>
        <Pagination {...props} onChangePageHandler={onChangePageHandler} />
      </TableRow>
    </TableFooter>
  );
};
export default CustomTableFooter;
