import { FORM_EDIT_DATA } from 'actions';
import {
  CHANGE_PROJECTS_PAGE,
  CHANGE_PROJECTS_PAGER,
  DELETE_PROJECT,
  GET_ORG_PROJECTS,
  GET_PROJECTS
} from 'constants/index';
import { apiService } from 'middleware/api';

export const onGetProjects = (
  page = 1,
  perPage = 10,
  keyword = '',
  companyId?: number
) => {
  const endPoint = `projects?company_id=${
    companyId ?? ''
  }&page=${page}&per_page=${perPage}&keyword=${keyword}`;
  const method = 'get';
  return apiService(endPoint, method, GET_PROJECTS);
};

export const onGetProject = (id: string) => {
  const endPoint = `projects/${id}.json`;
  const method = 'get';
  return apiService(endPoint, method, FORM_EDIT_DATA);
};

export const onDeleteProject = (id: string) => {
  const endPoint = `projects/${id}.json`;
  const method = 'delete';
  return apiService(endPoint, method, DELETE_PROJECT);
};

export const onChangeProjectsPerPage = (perPage: number) => ({
  type: CHANGE_PROJECTS_PAGER,
  payload: perPage
});

export const onChangeProjectsPage = (page: number) => ({
  type: CHANGE_PROJECTS_PAGE,
  payload: page
});

export const onGetOrgProjects = (
  page = 1,
  perPage = 10,
  keyword = '',
  companyId: string,
  projectTypeId: string,
  projectStatusId: string
) => {
  const endPoint = `projects?page=${page}&per_page=${perPage}&keyword=${keyword}&company_id=${companyId}&type_id=${projectTypeId}&status_id=${projectStatusId}`;
  const method = 'get';
  return apiService(endPoint, method, GET_ORG_PROJECTS);
};
