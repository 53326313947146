export const GET_TAXES = 'GET_TAXES';
export const GET_TAXES_SUCCESS = 'GET_TAXES_SUCCESS';
export const GET_TAXES_FAIL = 'GET_TAXES_FAIL';
export const DELETE_TAX = 'DELETE_TAX';
export const DELETE_TAX_SUCCESS = 'DELETE_TAX_SUCCESS';
export const DELETE_TAX_FAIL = 'DELETE_TAX_FAIL';
export const CHANGE_TAXES_PAGER = 'CHANGE_TAXES_PAGER';
export const CHANGE_TAXES_PAGE = 'CHANGE_TAXES_PAGE';
export const UPDATE_TAX_STATUS = 'UPDATE_TAX_STATUS';
export const UPDATE_TAX_STATUS_SUCCESS = 'UPDATE_TAX_STATUS_SUCCESS';
export const UPDATE_TAX_STATUS_FAIL = 'UPDATE_TAX_STATUS_FAIL';
