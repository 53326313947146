import React, { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Grid, Hidden } from '@material-ui/core';

import LoginForm from './LoginForm';
import Intro from './Intro';
import SignupForm from './SignupForm';
import ForgetForm from './ForgetForm';
import ResetForm from './ResetForm';
import AcceptInviteNSignup from './AcceptInviteNSignup';
import {
  onForgetPassword,
  onLogin,
  onResetPassword,
  onSignup,
  onAccept,
  onShowInviteData,
  onClearMsg
} from 'actions';
import {
  AcceptInviteFormFields,
  AuthProps,
  ForgetFormFields,
  LoginFormFields,
  ResetFormFields,
  SignupFormFields
} from 'types';

const Auth = () => {
  const { push } = useHistory();
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const { isAuth, loading, error, message, data } = useSelector(
    ({ auth }: AuthProps) => auth
  );

  useEffect(() => {
    isAuth && push('/dashboard');
  }, [isAuth, push]);

  const clearMsgHandler = () => dispatch(onClearMsg());
  const loginHandle = (data: LoginFormFields) => dispatch(onLogin(data));
  const signupHandle = (data: SignupFormFields) => dispatch(onSignup(data));
  const resetHandle = (data: ResetFormFields, headers: {}) =>
    dispatch(onResetPassword(data, headers));
  const forgetPasswordHandle = (data: ForgetFormFields) =>
    dispatch(onForgetPassword(data));
  const acceptNSignupHandle = (data: AcceptInviteFormFields, token: string) =>
    dispatch(onAccept(data, token));
  const forgetPassword = (
    <ForgetForm
      forgetPasswordHandle={forgetPasswordHandle}
      loading={loading}
      error={error}
      message={message}
      clearMsgHandler={clearMsgHandler}
    />
  );
  const signup = (
    <SignupForm
      signupHandle={signupHandle}
      loading={loading}
      error={error}
      message={message}
      clearMsgHandler={clearMsgHandler}
    />
  );
  const login = (
    <LoginForm
      loginHandle={loginHandle}
      loading={loading}
      error={error}
      message={message}
      clearMsgHandler={clearMsgHandler}
    />
  );
  const reset = (
    <ResetForm
      resetHandle={resetHandle}
      error={error}
      loading={loading}
      message={message}
      clearMsgHandler={clearMsgHandler}
    />
  );

  const acceptNSignup = (
    <AcceptInviteNSignup
      error={error}
      loading={loading}
      message={message}
      showInviteData={(token: string) => dispatch(onShowInviteData(token))}
      acceptNSignupHandle={acceptNSignupHandle}
      data={data}
      clearMsgHandler={clearMsgHandler}
    />
  );

  let form = login;
  if (pathname === '/signup') form = signup;
  else if (pathname === '/forget-password') form = forgetPassword;
  else if (pathname === '/reset-password') form = reset;
  else if (pathname === '/accept-invite') form = acceptNSignup;
  return (
    <Grid container>
      <Hidden smDown>
        <Grid item md={6}>
          <Intro />
        </Grid>
      </Hidden>
      <Grid item md={6} xs={12}>
        {form}
      </Grid>
    </Grid>
  );
};

export default Auth;
