import {
  GET_NOTIFICATIONS,
  GET_NOTIFICATIONS_SUCCESS,
  GET_NOTIFICATIONS_FAIL,
  CHANGE_NOTIFICATIONS_PAGER,
  CHANGE_NOTIFICATIONS_PAGE
} from '../constants';

const initial = {
  data: [],
  loading: false,
  error: ''
};

const notifications = (
  state = initial,
  action: { type: string; payload: any }
) => {
  switch (action.type) {
    case GET_NOTIFICATIONS:
      return { ...state, loading: true };
    case GET_NOTIFICATIONS_SUCCESS:
      return { ...state, data: action.payload.data, loading: false };
    case GET_NOTIFICATIONS_FAIL:
      return { ...state, error: action.payload, loading: false };
    case CHANGE_NOTIFICATIONS_PAGER:
      return { ...state, perPage: action.payload };
    case CHANGE_NOTIFICATIONS_PAGE:
      return { ...state, page: action.payload };
    default:
      return state;
  }
};

export default notifications;
