export const GET_USERS = 'GET_USERS';
export const GET_USERS_SUCCESS = 'GET_USERS_SUCCESS';
export const GET_USERS_FAIL = 'GET_USERS_FAIL';
export const DELETE_USER = 'DELETE_USER';
export const DELETE_USER_SUCCESS = 'DELETE_USER_SUCCESS';
export const DELETE_USER_FAIL = 'DELETE_USER_FAIL';
export const CHANGE_USERS_PAGER = 'CHANGE_USERS_PAGER';
export const CHANGE_USERS_PAGE = 'CHANGE_USERS_PAGE';
export const UPDATE_USER_ROLE = 'UPDATE_USER_ROLE';
export const UPDATE_USER_ROLE_SUCCESS = 'UPDATE_USER_ROLE_SUCCESS';
export const UPDATE_USER_ROLE_FAIL = 'UPDATE_USER_ROLE_FAIL';
export const CHANGE_USER_PERMISSION = 'CHANGE_USER_PERMISSION';
export const CHANGE_USER_PERMISSION_SUCCESS = 'CHANGE_USER_PERMISSION_SUCCESS';
export const CHANGE_USER_PERMISSION_FAIL = 'CHANGE_USER_PERMISSION_FAIL';

export const GET_USER_BY_EMAIL = 'GET_USER_BY_EMAIL';
export const GET_USER_BY_EMAIL_SUCCESS = 'GET_USER_BY_EMAIL_SUCCESS';
export const GET_USER_BY_EMAIL_FAIL = 'GET_USER_BY_EMAIL_FAIL';

export const GET_USER_LIMIT = 'GET_USER_LIMIT';
export const GET_USER_LIMIT_FAIL = 'GET_USER_LIMIT_FAIL';
export const GET_USER_LIMIT_SUCCESS = 'GET_USER_LIMIT_SUCCESS';
