import React from 'react';
import { Fade, Grow, Zoom, Slide } from '@material-ui/core';

import { TransitionProps } from '@material-ui/core/transitions';

export const ZoomTransition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement },
  ref: React.Ref<unknown>
) {
  return <Zoom {...props} />;
});
export const FadeTransition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement },
  ref: React.Ref<unknown>
) {
  return <Fade {...props} />;
});
export const SlideTransition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement },
  ref: React.Ref<unknown>
) {
  return <Slide {...props} />;
});
export const GrowTransition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement },
  ref: React.Ref<unknown>
) {
  return <Grow {...props} />;
});
