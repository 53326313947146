import React from 'react';
import { Button, DialogActions } from '@material-ui/core';

import { DialogFooterProps } from '../types/customDialog';

const DialogFooter = ({
  closeHandle,
  okHandle,
  footer,
  okButtonText,
  cancelButtonText,
  cancelHandle
}: DialogFooterProps) => {
  if (footer === 'hide') return null;
  if (footer) return <DialogActions>{footer}</DialogActions>;
  return (
    <DialogActions>
      <Button onClick={okHandle} color='primary'>
        {okButtonText ?? 'OK'}
      </Button>
      <Button onClick={cancelHandle ?? closeHandle} color='primary'>
        {cancelButtonText ?? 'Cancel'}
      </Button>
    </DialogActions>
  );
};

export default React.memo(DialogFooter);
