import React, { useState } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import { ThemeProvider } from '@material-ui/core/styles';
import { CssBaseline } from '@material-ui/core';
import 'react-quill/dist/quill.snow.css';

import { store, persistor } from './store';
import Scroller from './Routes/Scroller';
import Routes from './Routes';
import { authenticateUser } from './utils/authenticateUser';
import { customTheme } from './utils/theme';
import { PersistGate } from 'redux-persist/integration/react';

authenticateUser(store);

function App() {
  const [primaryColor, setPrimary] = useState(
    localStorage.getItem('primary_color') || '#0f161c'
  );
  const [secondaryColor, setSecondary] = useState(
    localStorage.getItem('secondary_color') || '#282828'
  );
  const [companyLogo, setCompanyLogo] = useState(
    localStorage.getItem('logo') || ''
  );
  const setStyles = (
    primaryColor: string,
    secondaryColor: string,
    logo: string
  ) => {
    setPrimary(primaryColor);
    setSecondary(secondaryColor);
    setCompanyLogo(logo);
  };
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <Router>
          <ThemeProvider
            theme={customTheme(primaryColor, secondaryColor, companyLogo)}
          >
            <>
              <CssBaseline />
              <Scroller>
                <div className='App'>
                  <Routes setStyles={setStyles} />
                </div>
              </Scroller>
            </>
          </ThemeProvider>
        </Router>
      </PersistGate>
    </Provider>
  );
}

export default App;

interface Theme {
  theme: { data: { primary_color?: string; secondary_color?: string } };
}
