import {
  GET_PROJECT_TYPES,
  DELETE_PROJECT_TYPE,
  CHANGE_PROJECT_TYPES_PAGER,
  CHANGE_PROJECT_TYPES_PAGE
} from 'constants/index';
import { apiService } from 'middleware/api';
import { FORM_EDIT_DATA } from 'actions';

export const onGetProjectTypes = (page = 1, perPage = 10, keyword = '') => {
  const endPoint = `project_types.json?page=${page}&per_page=${perPage}&keyword=${keyword}`;
  const method = 'get';
  return apiService(endPoint, method, GET_PROJECT_TYPES);
};

export const onGetProjectType = (key: string) => {
  const endPoint = `project_types/${key}.json`;
  const method = 'get';
  return apiService(endPoint, method, FORM_EDIT_DATA);
};

export const onDeleteProjectType = (key: string) => {
  const endPoint = `project_types/${key}.json`;
  const method = 'delete';
  return apiService(endPoint, method, DELETE_PROJECT_TYPE);
};

export const onChangeProjectTypesPerPage = (perPage: number) => ({
  type: CHANGE_PROJECT_TYPES_PAGER,
  payload: perPage
});
export const onChangeProjectTypesPage = (page: number) => ({
  type: CHANGE_PROJECT_TYPES_PAGE,
  payload: page
});
