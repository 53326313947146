import { FormGroup, FormLabel, Grid, makeStyles } from '@material-ui/core';
import React from 'react';
import ReactQuill from 'react-quill';
import { toolbarOptions } from 'static/ToolbarOptions';

const RichTextEditor = ({
  description,
  setDescription,
  title = 'Description'
}: RichTextEditorProps) => {
  const classes = useStyles();

  const modules = {
    toolbar: toolbarOptions
  };

  return (
    <Grid sm={12} item container className={classes.quillContainer}>
      <FormGroup className={classes.fullWidth}>
        <FormLabel color='primary' className='mb5 mt16'>
          {title}
        </FormLabel>
        <ReactQuill
          value={description}
          onChange={setDescription}
          className={classes.quill}
          modules={modules}
        />
      </FormGroup>
    </Grid>
  );
};

export default React.memo(RichTextEditor);

export const useStyles = makeStyles(() => ({
  quillContainer: { height: 370 },
  quill: { height: 250, width: '100%' },
  fullWidth: { width: '100%' }
}));

interface RichTextEditorProps {
  description: string;
  setDescription: (val: string) => void;
  title?: string;
}
