import {
  GET_TAX_CODES,
  DELETE_TAX_CODE,
  CHANGE_TAX_CODES_PAGER,
  CHANGE_TAX_CODES_PAGE
} from 'constants/index';
import { apiService } from 'middleware/api';
import { FORM_EDIT_DATA } from 'actions';

export const onGetTaxCodes = (page = 1, perPage = 10, keyword = '') => {
  const endPoint = `tax_codes.json?page=${page}&per_page=${perPage}&keyword=${keyword}`;
  const method = 'get';
  return apiService(endPoint, method, GET_TAX_CODES);
};

export const onGetTaxCode = (id: number) => {
  const endPoint = `tax_codes/${id}.json`;
  const method = 'get';
  return apiService(endPoint, method, FORM_EDIT_DATA);
};

export const onDeleteTaxCode = (id: number) => {
  const endPoint = `tax_codes/${id}.json`;
  const method = 'delete';
  return apiService(endPoint, method, DELETE_TAX_CODE);
};

export const onChangeTaxCodesPerPage = (perPage: number) => ({
  type: CHANGE_TAX_CODES_PAGER,
  payload: perPage
});
export const onChangeTaxCodesPage = (page: number) => ({
  type: CHANGE_TAX_CODES_PAGE,
  payload: page
});
