import axios, { AxiosError } from 'axios';

type methodTypes = 'get' | 'post' | 'put' | 'delete';

export const authService =
  (body: {}, endPoint: string, method: methodTypes, type: string) =>
  async (dispatch: any) => {
    const BASE_URL = process.env.REACT_APP_API_URL;
    const API_URL = `${BASE_URL}${endPoint}`;
    try {
      dispatch({ type });
      const response = await axios({ method, url: API_URL, data: body });

      const res: any = response.data;
      const resHeaders = response.headers;
      if (res.status === 'error') throw Error(res.errors.full_messages[0]);
      if (res.success === false) {
        if (Array.isArray(res.errors)) throw Error(res.errors[0]);
        else throw Error(res.errors);
      }
      let headersObj: { [key: string]: any } = {};
      for (let pair of Object.entries(resHeaders))
        headersObj[pair[0]] = pair[1];
      if (res.data?.current_tenant?.auth_token)
        headersObj['Organization-Token'] = res.data?.current_tenant?.auth_token;
      if (type === 'LOGIN') {
        localStorage.setItem('token', JSON.stringify(headersObj));
        localStorage.setItem('user', JSON.stringify(res.data));
      }

      dispatch({
        type: `${type}_SUCCESS`,
        payload: { data: headersObj, user: res.data }
      });
    } catch (error) {
      const err = error as Error;
      localStorage.removeItem('token');
      dispatch({
        type: `${type}_FAIL`,
        payload:
          typeof err.response !== 'undefined'
            ? err.response?.data?.errors
              ? err.response.data.errors.full_messages
                ? err.response.data.errors.full_messages[0]
                : err.response.data.errors[0]
              : err.response.status >= 400
              ? err.response.data.error
              : err.response.data.error[0]
            : typeof err.message === 'string'
            ? err.message
            : 'Failed to fetch'
      });
    }
  };

interface Error extends Omit<AxiosError, 'response'> {
  response: {
    status: number;
    data: { errors: any; error: any };
  };
}
