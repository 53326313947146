import {
  DELETE_TASKS_STATUS,
  DELETE_TASKS_STATUS_FAIL,
  DELETE_TASKS_STATUS_SUCCESS,
  GET_TASK_STATUSES,
  GET_TASK_STATUSES_FAIL,
  GET_TASK_STATUSES_SUCCESS,
  UPDATE_TASKS_STATUS,
  UPDATE_TASKS_STATUS_FAIL,
  UPDATE_TASKS_STATUS_SUCCESS
} from '../constants';

const initial = {
  data: [],
  loading: false,
  error: '',
  message: '',
  page: 1,
  perPage: 100,
  count: 1,
  success: false,
  updated: false
};

const taskStatuses = (
  state = initial,
  action: { type: string; payload: any }
) => {
  switch (action.type) {
    case GET_TASK_STATUSES:
      return { ...state, loading: true };
    case GET_TASK_STATUSES_SUCCESS:
      return {
        ...state,
        data: action.payload.data,
        count: action.payload.meta_attributes
          ? action.payload.meta_attributes.total_count
          : 1,
        loading: false
      };
    case GET_TASK_STATUSES_FAIL:
      return { ...state, error: action.payload, loading: false };
    case 'CLEAR_ERROR':
      return { ...state, error: '', message: '' };
    case UPDATE_TASKS_STATUS:
      return { ...state, loading: true, updated: false };

    case UPDATE_TASKS_STATUS_SUCCESS:
      return { ...state, loading: true, updated: true };

    case UPDATE_TASKS_STATUS_FAIL:
      return { ...state, loading: true, updated: false };

    case DELETE_TASKS_STATUS:
      return { ...state, loading: true, success: false };
    case DELETE_TASKS_STATUS_SUCCESS:
      return {
        ...state,
        message: action.payload.message,
        loading: true,
        success: true
      };
    case DELETE_TASKS_STATUS_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
        success: false
      };
    default:
      return state;
  }
};

export default taskStatuses;
