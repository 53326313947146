import {
  GET_INVOICES,
  GET_INVOICES_SUCCESS,
  GET_INVOICES_FAIL,
  GET_INVOICE,
  GET_INVOICE_FAIL,
  GET_INVOICE_SUCCESS,
  CHANGE_INVOICE_PAGE,
  CHANGE_INVOICE_PAGER
} from '../constants';

const initial = {
  data: [],
  loading: false,
  error: '',
  message: '',
  invoiceData: {},
  page: 1,
  perPage: 10,
  count: 1
};

const invoices = (state = initial, action: { type: string; payload: any }) => {
  switch (action.type) {
    case GET_INVOICES:
      return { ...state, loading: true };
    case GET_INVOICES_SUCCESS:
      return {
        ...state,
        data: action.payload.data,
        message: action.payload.message,
        count: action.payload.meta_attributes
          ? action.payload.meta_attributes.total_count
          : 1,
        loading: false
      };
    case GET_INVOICES_FAIL:
      return { ...state, error: action.payload, loading: false };
    case GET_INVOICE:
      return { ...state, loading: true };
    case GET_INVOICE_SUCCESS:
      return {
        ...state,
        invoiceData: action.payload.data,
        message: action.payload.message,
        loading: false
      };
    case GET_INVOICE_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false
      };
    case CHANGE_INVOICE_PAGER:
      return { ...state, perPage: action.payload };
    case CHANGE_INVOICE_PAGE:
      return { ...state, page: action.payload };
    default:
      return state;
  }
};

export default invoices;
