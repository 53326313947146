import React, { useEffect } from 'react';
import {
  Button,
  Chip,
  Divider,
  Menu,
  MenuItem,
  Typography
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import clsx from 'clsx';

import { AuthProps } from 'types';
import { DynamicObject } from 'app/Common/types';
import { useStyles } from '../../styles/topbar';
import { OrganizationsProps } from 'app/Dashboard/Organizations/types';
import { onGetOrganizations, onSetOrg } from 'actions';
import { organizationChangeEvent } from 'utils/organizationChangeEvent';
import { useHistory } from 'react-router-dom';
import { Add as AddIcon } from '@material-ui/icons';
import { onClearCurrentSubscription } from 'actions';
import { PlanSubscriptionsProps } from 'app/Dashboard/StripeCard/types';
import CustomAlertMsg from 'app/Common/CustomAlertMsg';

const OrganizationsMenu = () => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const dispatch = useDispatch();
  const { data, user } = useSelector(({ auth }: AuthProps) => auth);
  const { data: organizations, current } = useSelector(
    ({ organizations }: OrganizationsProps) => organizations
  );
  const [orgMessage, setOrgMessage] = React.useState('');
  const history = useHistory();
  useEffect(() => {
    dispatch(onGetOrganizations());
  }, [dispatch]);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    user.role === 'admin' && setAnchorEl(event.currentTarget);
  };
  const handleClose = () => setAnchorEl(null);
  const changeOrganizationHandle = (organization: DynamicObject) => {
    dispatch(onClearCurrentSubscription());
    setTimeout(() => organizationChangeEvent(organization, user, data), 100);

    dispatch(onSetOrg({ data: { organization } }));
  };

  const { subscriptionData } = useSelector(
    ({ planSubscriptions }: PlanSubscriptionsProps) => planSubscriptions
  );

  const { count, free } = useSelector(
    ({ organizations }: OrganizationsProps) => organizations
  );

  const handleOrganization = () => {
    if (count && subscriptionData?.plan?.organizations_count) {
      if (count >= subscriptionData?.plan?.organizations_count && !free)
        return setOrgMessage('Organization Limit Exceeded');
    }
    history.push('/organization');
  };

  const handleUserError = () => {
    setOrgMessage('');
  };

  return (
    <div>
      <CustomAlertMsg
        type={orgMessage.length ? 'error' : 'success'}
        message={orgMessage}
        onClose={() => handleUserError()}
      />
      <Button
        color='primary'
        variant='contained'
        className='no-shadow'
        onClick={handleClick}
      >
        {current?.name ?? ''}
      </Button>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {organizations.map(val =>
          val.suspended ? (
            <MenuItem
              key={val.id}
              onClick={handleClose}
              className={clsx([classes.orgMenuItem, classes.disableOrg])}
            >
              {val.organization?.name}
              <Chip label='Suspended' size='small' />
            </MenuItem>
          ) : (
            <MenuItem
              key={val.id}
              onClick={() => changeOrganizationHandle(val?.organization)}
              className={classes.orgMenuItem}
            >
              {val.organization?.name}
            </MenuItem>
          )
        )}
        {user.role === 'admin' && (
          <>
            <Divider />
            <MenuItem className={classes.orgMenuItem} onClick={handleClose}>
              <Button onClick={handleOrganization} className='dp-flex'>
                <Typography className='mr5 dp-flex'>
                  Create New <AddIcon />{' '}
                </Typography>
              </Button>
            </MenuItem>
          </>
        )}
      </Menu>
    </div>
  );
};

export default React.memo(OrganizationsMenu);
