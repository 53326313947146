import {
  GET_PRODUCTS,
  DELETE_PRODUCT,
  CHANGE_PRODUCTS_PAGER,
  CHANGE_PRODUCTS_PAGE
} from 'constants/index';
import { apiService } from 'middleware/api';
import { FORM_EDIT_DATA } from 'actions';

export const onGetProducts = (page = 1, perPage = 10, keyword = '',type='') => {
  const endPoint = `products.json?page=${page}&per_page=${perPage}&keyword=${keyword}&product_type=${type}`;
  const method = 'get';
  return apiService(endPoint, method, GET_PRODUCTS);
};

export const onGetProduct = (key: string) => {
  const endPoint = `products/${key}.json`;
  const method = 'get';
  return apiService(endPoint, method, FORM_EDIT_DATA);
};

export const onDeleteProduct = (key: string) => {
  const endPoint = `products/${key}.json`;
  const method = 'delete';
  return apiService(endPoint, method, DELETE_PRODUCT);
};

export const onChangeProductsPerPage = (perPage: number) => ({
  type: CHANGE_PRODUCTS_PAGER,
  payload: perPage
});

export const onChangeProductsPage = (page: number) => ({
  type: CHANGE_PRODUCTS_PAGE,
  payload: page
});
