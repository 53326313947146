import React from 'react';
import { Grid } from '@material-ui/core';
import htmlParser from 'html-react-parser';

import { DisclaimerProps } from '../../types';
const Disclaimer = ({ disclaimer }: DisclaimerProps) => (
  <Grid item md={8} sm={6}>
    {htmlParser(disclaimer ?? '')}
  </Grid>
);

export default React.memo(Disclaimer);
