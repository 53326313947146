import React from 'react';
import { LinearProgress } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';

import { LoaderProps } from '../types/customTable';

const Loader = ({ loading, noData }: LoaderProps) => (
  <>
    <LinearProgress />
    {loading &&
      noData &&
      [50, 30, 30, 30, 30].map((v, i) => <Skeleton height={v} key={i} />)}
  </>
);

export default React.memo(Loader);
