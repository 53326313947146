import * as Yup from 'yup';

export const resetValidation = Yup.object({
  password: Yup.string().required().min(6).label('Password'),
  password_confirmation: Yup.string()
    .required()
    .min(6)
    .oneOf([Yup.ref('password')], 'Passwords must be same')
    .label('Password Confirmation')
});
