export const signupFields = [
  { value: 'email', label: 'E-mail Address', type: 'email' },
  { value: 'first_name', label: 'First Name' },
  { value: 'last_name', label: 'Last Name' },
  { value: 'password', label: 'Password', type: 'password' },
  {
    value: 'password_confirmation',
    label: 'Confirm Password',
    type: 'password'
  }
];
