import React from 'react';
import parse from 'html-react-parser';

import CustomTable from 'app/Common/CustomTable';
import { AcceptQuoteListProps } from '../types';
import CustomTag from 'app/Common/CustomTag';

const AcceptQuoteList = ({ data, loading }: AcceptQuoteListProps) => {
  return (
    <>
      <CustomTable
        rows={data.map(row => ({
          ...row,
          price: `$${row.price}`,
          total: `$${row.total}`,
          discount: `${row.discount}%`,
          frequencyStatus: <CustomTag title={row.frequency} noTag />,
          info: (
            <>
              <strong>{row.title}</strong>
              <p>{parse(row.description)}</p>
            </>
          )
        }))}
        headItems={['Items', 'Price', 'Quantity', 'Frequency', 'Discount', 'Total']}
        columns={['info', 'price', 'quantity', 'frequencyStatus', 'discount', 'total']}
        loading={loading}
        perPage={data.length}
        page={1}
        count={0}
        isScroller
      />
    </>
  );
};

export default React.memo(AcceptQuoteList);
