export const LOGIN = 'LOGIN';
export const LOGOUT = 'LOGOUT';
export const LOGIN_ATTEMPT = 'LOGIN_ATTEMPT';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';
export const SIGNUP = 'SIGNUP';
export const SIGNUP_SUCCESS = 'SIGNUP_SUCCESS';
export const SIGNUP_FAIL = 'SIGNUP_FAIL';
export const FORGET = 'FORGET';
export const FORGET_SUCCESS = 'FORGET_SUCCESS';
export const FORGET_FAIL = 'FORGET_FAIL';
export const RESET = 'RESET';
export const RESET_SUCCESS = 'RESET_SUCCESS';
export const RESET_FAIL = 'RESET_FAIL';
export const ACCEPT = 'ACCEPT';
export const ACCEPT_SUCCESS = 'ACCEPT_SUCCESS';
export const ACCEPT_FAIL = 'ACCEPT_FAIL';
export const SHOW_INVITE = 'SHOW_INVITE';
export const SHOW_INVITE_SUCCESS = 'SHOW_INVITE_SUCCESS';
export const SHOW_INVITE_FAIL = 'SHOW_INVITE_FAIL';
export const INVITED_USER = 'INVITED_USER';
export const INVITED_USER_SUCCESS = 'INVITED_USER_SUCCESS';
export const INVITED_USER_FAIL = 'INVITED_USER_FAIL';
export const SET_ORG = 'SET_ORG';
