import React, { useState, FormEvent } from 'react';
import { TextField, LinearProgress, Typography } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import KeyIcon from '@material-ui/icons/VpnKey';

import Footer from './Footer';
import AuthFormsButton from './AuthFormsButton';
import CustomAlertMsg from 'app/Common/CustomAlertMsg';
import { useStyles } from '../styles';
import { ForgetFormProps } from '../types';

const ForgetPasswordForm = ({
  forgetPasswordHandle,
  loading,
  error,
  message,
  clearMsgHandler
}: ForgetFormProps) => {
  const [email, setEmail] = useState('');
  const classes = useStyles();
  const { push } = useHistory();
  const submitHandle = (event: FormEvent) => {
    event.preventDefault();
    forgetPasswordHandle({ email });
  };

  return (
    <div className={classes.container}>
      <Typography variant='h4' component='h2' gutterBottom align='center'>
        Forget Password
      </Typography>
      <CustomAlertMsg
        type={error.length ? 'error' : 'success'}
        message={error || message}
        onClose={() => {
          clearMsgHandler();
          message.length && push('/');
        }}
      />
      <form onSubmit={submitHandle} className={classes.form}>
        {loading && <LinearProgress />}
        <KeyIcon
          color='primary'
          fontSize='large'
          className={`${classes.icon}  ${classes.forgetIcon}`}
        />
        <TextField
          className={classes.fields}
          placeholder='E-mail Address'
          type='email'
          onChange={e => setEmail(e.target.value)}
          value={email}
          required
          autoFocus
          autoComplete='new-email'
          InputProps={{ className: classes.input }}
        />
        <div className={classes.authFormBottom}>
          <AuthFormsButton loading={loading} text='Submit' />
          <Footer formType='forget' />
        </div>
      </form>
    </div>
  );
};

export default React.memo(ForgetPasswordForm);
