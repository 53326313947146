import {
  GET_COMPANIES,
  GET_COMPANIES_FAIL,
  GET_COMPANIES_SUCCESS,
  DELETE_COMPANY,
  DELETE_COMPANY_FAIL,
  DELETE_COMPANY_SUCCESS,
  CHANGE_COMPANIES_PAGER,
  CHANGE_COMPANIES_PAGE,
  CHANGE_COMPANY_STATUS,
  CHANGE_COMPANY_STATUS_FAIL,
  CHANGE_COMPANY_STATUS_SUCCESS,
  GET_COMPANIES_COUNT,
  GET_COMPANIES_COUNT_SUCCESS,
  GET_COMPANIES_COUNT_FAIL,
  GET_SALES_PERSONS,
  GET_SALES_PERSONS_SUCCESS,
  GET_SALES_PERSONS_FAIL,
  GET_COMPANY_CONTACTS,
  GET_COMPANY_CONTACTS_SUCCESS,
  GET_COMPANY_CONTACTS_FAIL,
  CLEAR_COMPANY_CONTACTS,
  GET_ACCOUNT_TRANSFER,
  GET_ACCOUNT_TRANSFER_SUCCESS,
  GET_ACCOUNT_TRANSFER_FAIL
} from 'constants/index';

const initial = {
  data: [],
  loading: false,
  error: '',
  message: '',
  formLoading: false,
  page: 1,
  perPage: 10,
  count: 1,
  companiesCount: {},
  salesPersons: [],
  companyContacts: []
};

const companies = (state = initial, action: { type: string; payload: any }) => {
  switch (action.type) {
    case GET_COMPANIES:
      return { ...state, loading: true };
    case GET_COMPANIES_SUCCESS:
      return {
        ...state,
        data: action.payload.data,
        count: action.payload.meta_attributes
          ? action.payload.meta_attributes.total_count
          : 1,
        loading: false
      };
    case GET_COMPANIES_FAIL:
      return { ...state, error: action.payload, loading: false };
    case 'CLEAR_ERROR':
      return { ...state, error: '', message: '' };
    case DELETE_COMPANY:
      return { ...state, loading: true };
    case DELETE_COMPANY_SUCCESS:
      return { ...state, message: action.payload.message, loading: false };
    case DELETE_COMPANY_FAIL:
      return { ...state, error: action.payload, loading: false };
    case CHANGE_COMPANY_STATUS:
      return { ...state, loading: true };
    case CHANGE_COMPANY_STATUS_SUCCESS:
      return {
        ...state,
        message: 'Company status has been changed',
        loading: false
      };
    case CHANGE_COMPANY_STATUS_FAIL:
      return { ...state, error: action.payload, loading: false };
    case CHANGE_COMPANIES_PAGER:
      return { ...state, perPage: action.payload };
    case CHANGE_COMPANIES_PAGE:
      return { ...state, page: action.payload };
    case GET_COMPANIES_COUNT:
      return { ...state, loading: true };
    case GET_COMPANIES_COUNT_SUCCESS:
      return { ...state, loading: false, companiesCount: action.payload.data };
    case GET_COMPANIES_COUNT_FAIL:
      return { ...state, loading: false, error: action.payload };
    case GET_SALES_PERSONS:
      return { ...state, loading: true };
    case GET_SALES_PERSONS_SUCCESS:
      return { ...state, loading: false, salesPersons: action.payload.data };
    case GET_SALES_PERSONS_FAIL:
      return { ...state, loading: false, error: action.payload };
    case GET_COMPANY_CONTACTS:
      return { ...state, loading: true };
    case GET_COMPANY_CONTACTS_SUCCESS:
      return {
        ...state,
        loading: false,
        companyContacts: action.payload.data
      };
    case GET_COMPANY_CONTACTS_FAIL:
      return { ...state, loading: false, error: action.payload };
    case CLEAR_COMPANY_CONTACTS:
      return { ...state, companyContacts: [] };
    case GET_ACCOUNT_TRANSFER:
      return { ...state, loading: true };
    case GET_ACCOUNT_TRANSFER_SUCCESS:
      return { ...state, loading: false, message: action.payload.message };
    case GET_ACCOUNT_TRANSFER_FAIL:
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};

export default companies;
