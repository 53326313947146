import {
  GET_ACCEPTQUOTE,
  GET_ACCEPTQUOTE_FAIL,
  GET_ACCEPTQUOTE_SUCCESS
} from 'constants/index';

const initial = {
  quote: {},
  loading: false,
  error: '',
  message: '',
  items: []
};

const acceptQuote = (
  state = initial,
  action: { type: string; payload: any }
) => {
  switch (action.type) {
    case GET_ACCEPTQUOTE:
      return { ...state, loading: true };
    case GET_ACCEPTQUOTE_SUCCESS:
      return {
        ...state,
        quote: action.payload.data,
        items: action.payload.data?.items ?? [],
        loading: false
      };
    case GET_ACCEPTQUOTE_FAIL:
      return { ...state, error: action.payload, loading: false };
    case 'CLEAR_ERROR':
      return { ...state, error: '', message: '' };
    default:
      return state;
  }
};

export default acceptQuote;
