import {
  Button,
  FormHelperText,
  Grid,
  makeStyles,
  TextField
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import {
  onClearTaskDetails,
  onGetChildTasks,
  onGetTask,
  onGetTaskDetails,
  onGetUsers
} from 'actions';
import AsyncSearch from 'app/Common/AsyncSearch';
import CustomAlertMsg from 'app/Common/CustomAlertMsg';
import CustomDatePicker from 'app/Common/CustomDatePicker';
import CustomDialog from 'app/Common/CustomDialog';
import CustomTable from 'app/Common/CustomTable';
import { useForm } from 'app/Common/Hooks/useForm';
import InfoItem from 'app/Common/InfoItem';
import ListDeleteButton from 'app/Common/ListDeleteButton';
import { DynamicObject } from 'app/Common/types';
import { Users } from 'app/Dashboard/Admin-Settings/Users/types';
import { onGetTaskServices } from 'app/Dashboard/Setup/TaskServices/redux/actions';
import { Services } from 'app/Dashboard/Setup/TaskServices/types';
import {
  onAddChildTask,
  onDeleteChildTask
} from 'app/Dashboard/SubTask/redux/actions';
import { messages } from 'assets/static/strings/messages';
import parse from 'html-react-parser';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { dateYMDFormat } from 'utils/common/dateYMDFormat';
import { Tasks as TaskTypes } from '../../types';
import Tasks from '../index';
import TaskComments from './TaskComments';
import TaskHistory from './TaskHistory';
import WorkLogsList from './WorkLogsList';

const ViewTask = ({ row, onClose, task, onSuccess }: ViewTaskProps) => {
  const { descriptionContainer, selectContainer, headLabel, pl7 } = useStyles();
  const { push } = useHistory();
  const dispatch = useDispatch();

  const {
    tasks,
    childTasks,
    users,
    taskServices: { data: services, loading: servicesLoading }
  } = useSelector(
    (state: {
      tasks: TaskTypes;
      childTasks: { message: string };
      users: Users;
      taskServices: Services;
    }) => state
  );

  const { data: statusList, loading: statusListLoading } = useSelector(
    ({ taskStatuses }: Record<any, any>) => taskStatuses
  );
  const [tab, setTab] = useState('0');
  const [loading, setLoading] = useState(false);
  const [estimatedTime, setEstimatedTime] = useState(task.estimated_hour ?? '');
  const [selectedTaskId, setSelectedTaskId] = useState('');
  const [statusId, setStatusId] = useState<number>();
  const [assigneeId, setAssigneeId] = useState('');
  const [taskServiceId, setTaskServiceId] = useState('');
  const [dueDate, setDueDate] = useState<Date | null | string>(
    dateYMDFormat(task.due_date ? new Date(task.due_date) : new Date())
  );
  const [estimateValidationErr, setEstimateValidationErr] = useState<
    null | string
  >(null);

  const formHook = useForm();
  useEffect(() => {
    const data = { task: { parent_task_id: row.id } };
    selectedTaskId.length && dispatch(onAddChildTask(selectedTaskId, data));
    setSelectedTaskId('');
    dispatch(onGetTaskDetails(row.id));
    childTasks.message?.length && dispatch({ type: 'CLEAR_ERROR' });
    // eslint-disable-next-line
  }, [dispatch, selectedTaskId, childTasks?.message?.length, formHook.message]);

  useEffect(() => {
    task.id && dispatch(onGetTask(task.id.toString()));
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const data: {
      assignee_id?: string;
      task_status_id?: number;
      task_service_id?: string;
    } = {};
    assigneeId.length && (data.assignee_id = assigneeId);
    data.task_status_id = statusId;
    taskServiceId.length && (data.task_service_id = taskServiceId);
    if (statusId || assigneeId.length || taskServiceId.length) {
      onSuccess && setLoading(true);
      formHook.onSubmitPut(`tasks/${task.id}`, data);
    }
    // eslint-disable-next-line
  }, [assigneeId, statusId, taskServiceId]);

  if (!Object.keys(task).length) return null;

  const items = [
    { label: 'Company', value: task.company?.name },
    { label: 'Project', value: task.project?.name }
  ];
  const updateList = task?.time_logs.map((item: TimeLogsItem) => {
    let updatDescr = `${item.description}`;

    if (updatDescr !== null && updatDescr !== 'null') {
      item.note = <p>{parse(updatDescr)}</p>;
    } else {
      item.note = <p></p>;
    }
    item.day = dateYMDFormat(item.day);
    item.projectName = row.project.name;
    item.cost = task?.task_service?.cost
      ? (task?.task_service?.cost ?? '') * Number(item?.duration ?? '')
      : 'N/A';
    return item;
  });

  const renderAssignedChildTask = () => {
    const headItems = ['Title', 'Description'];
    const columns = ['title', 'info'];
    const updatedData = tasks?.task?.child_tasks?.map((row: DynamicObject) => {
      row.info = row.description ? <p>{parse(row.description)}</p> : '';
      return row;
    });
    return (
      <Grid container item sm={12} justify='flex-start'>
        <Grid item sm={10}>
          <h4>Sub/Child Tasks</h4>
          <CustomTable
            rows={updatedData ?? []}
            headItems={headItems}
            columns={columns}
            loading={tasks.loading}
            perPage={10}
            page={1}
            count={1}
            actions={row => [
              <ListDeleteButton
                loading={tasks.loading}
                onClickHandler={() =>
                  dispatch(
                    onDeleteChildTask(row.id.toString(), {
                      task: { remove_parent: true }
                    })
                  )
                }
                message={messages.deleteParentAlert}
              />
            ]}
            rowClickHandle={(e, { id }) => {
              push(`/subtask/${id}`);
              dispatch(onGetTaskDetails(id, true));
              dispatch(onClearTaskDetails());
            }}
          />
        </Grid>
      </Grid>
    );
  };
  const currentAssignee = task.assignee?.first_name
    ? `${task.assignee?.first_name ?? ''} ${task.assignee?.last_name ?? ''}`
    : task.assignee?.first_name;
  const asyncFields = [
    {
      head: 'Assignee',
      label: !assigneeId ? currentAssignee ?? '* Select Assignee' : '',
      data:
        users.data?.map(person => ({
          ...person,
          name: `${person.first_name} ${person.last_name}`
        })) ?? [],
      loading: users.loading,
      getter: onGetUsers,
      setter: setAssigneeId
    },
    {
      head: 'Service',
      label: !taskServiceId
        ? task.task_service?.name ?? '* Select Service'
        : '* Select Service',
      data: services,
      loading: servicesLoading,
      getter: onGetTaskServices,
      setter: setTaskServiceId
    }
  ];

  const updateTask = (date?: string, eta?: number | string) => {
    const data: { due_date?: string; estimated_hour?: string | number } = {};
    if (!date?.length || date) {
      data.due_date = date?.length ? date : '';
    }
    eta && (data.estimated_hour = eta);
    formHook.onSubmitPut(`tasks/${task.id}`, data);
  };

  const onClearDate = (e: any) => {
    e.stopPropagation();
    if (dueDate === null) return;
    setDueDate(null);
    updateTask('');
  };

  const handleDate = (date: string) => {
    setDueDate(date);
    updateTask(date);
  };

  const alertCloseHandle = async () => {
    if (formHook.status === 'success') {
      await onSuccess?.(parseInt(assigneeId));
      setLoading(false);
    }
    formHook.onClear();
  };

  const closeHandle = () => {
    if (formHook.loading || loading) return;
    dispatch(onClearTaskDetails());
    onClose();
  };

  const handleSubmitOnBlur = () => {
    const isValid = /^[0-9]\d?(?:\.\d{0,2})?$/.test(estimatedTime);
    if (!isValid)
      return setEstimateValidationErr(
        'Time Estimate cannot have more than 2 decimal places'
      );
    setEstimateValidationErr(null);
    updateTask(undefined, estimatedTime);
  };
  const dates = [
    {
      title: 'Due date',
      value: dueDate,
      setter: handleDate,
      clearer: onClearDate
    }
  ];

  return (
    <>
      <CustomAlertMsg
        type={formHook.status}
        message={formHook.message}
        onClose={alertCloseHandle}
      />
      <CustomDialog
        open={true}
        closeHandle={closeHandle}
        loading={loading}
        heading={task.title}
        maxWidth='md'
        footer={
          <Button color='primary' disabled={loading} onClick={closeHandle}>
            Close
          </Button>
        }
      >
        <Grid container spacing={2} className='pl5'>
          <Grid item sm={8}>
            <Tasks
              isCreateChildTasks={true}
              currentTaskRow={row}
              isChildTask={!!task.parent_task_id}
              closeForm={!!formHook.message.length}
            />
            <Grid item>
              <strong>Description</strong>
              <p className={descriptionContainer}>{parse(task.description)}</p>
            </Grid>

            <Grid container item sm={12} justify='flex-start'>
              <Grid item sm={10} className='pt10'>
                <AsyncSearch
                  filled
                  data={tasks.childTasks ?? []}
                  keyword='title'
                  getHandle={(page, perPage, keyword) =>
                    dispatch(
                      onGetChildTasks(
                        page,
                        perPage,
                        keyword,
                        row.id,
                        row?.project?.id ?? ''
                      )
                    )
                  }
                  selectHandle={setSelectedTaskId}
                  loading={tasks.loading}
                  label='Add Child Task'
                  clearFieldValue={!!selectedTaskId}
                />
              </Grid>
            </Grid>
            {!!tasks.task.child_tasks.length && renderAssignedChildTask()}
          </Grid>
          <Grid item sm={4} container alignItems='flex-start'>
            <Grid item sm={10}>
              <Grid className={pl7}>
                <Grid item sm={12} className={pl7}>
                  <Autocomplete
                    disabled={false}
                    id='taskStatus'
                    defaultValue={task.task_status}
                    options={statusList}
                    getOptionLabel={option =>
                      statusListLoading ? 'Loading...' : option.name
                    }
                    onChange={(e, v, r) => setStatusId(v?.id as number)}
                    renderInput={params => (
                      <TextField
                        {...params}
                        label={'Select Status'}
                        variant='standard'
                      />
                    )}
                  />
                </Grid>
              </Grid>
              {asyncFields.map(
                ({ label, data, loading, getter, setter, head }, index) => (
                  <Grid key={index} className={selectContainer}>
                    <span className={headLabel}>{head}</span>
                    <AsyncSearch
                      data={data}
                      getHandle={(page, perPage, keyword) =>
                        dispatch(getter(page, perPage, keyword))
                      }
                      selectHandle={setter}
                      loading={loading}
                      label={label}
                      disabled={formHook.loading || loading}
                    />
                  </Grid>
                )
              )}
              <Grid className={`${selectContainer} mt5`} item sm={12}>
                <TextField
                  label='Time Estimate'
                  type='number'
                  fullWidth
                  value={estimatedTime}
                  onChange={e => setEstimatedTime(e.target.value)}
                  InputLabelProps={{
                    shrink: true
                  }}
                  onBlur={handleSubmitOnBlur}
                />
                <FormHelperText error>{estimateValidationErr} </FormHelperText>
              </Grid>
              {items.map(({ value, label }) => (
                <InfoItem
                  key={label}
                  label={label}
                  value={value}
                  loading={false}
                />
              ))}
              <Grid item sm={12} className={selectContainer}>
                {dates.map(val => (
                  <CustomDatePicker
                    withTZ={false}
                    fullWidth
                    key={val.title}
                    {...val}
                  />
                ))}
              </Grid>
            </Grid>
          </Grid>
          <Grid item sm={12}>
            <Button
              size='small'
              variant='contained'
              color={tab === '0' ? 'secondary' : 'default'}
              onClick={() => setTab('0')}
            >
              Comments
            </Button>
            <Button
              className='ml10'
              size='small'
              variant='contained'
              color={tab === '1' ? 'secondary' : 'default'}
              onClick={() => setTab('1')}
            >
              History
            </Button>
            <Button
              className='ml10'
              size='small'
              variant='contained'
              color={tab === '2' ? 'secondary' : 'default'}
              onClick={() => setTab('2')}
            >
              Work Logs
            </Button>
          </Grid>
          <Grid item sm={12}>
            {tab === '0' && <TaskComments taskID={task.id} />}
            {tab === '1' && <TaskHistory audits={task.task_audits} />}
            {tab === '2' && (
              <WorkLogsList
                task={task}
                row={row}
                message={formHook.message}
                updateList={updateList}
              />
            )}
          </Grid>
        </Grid>
      </CustomDialog>
    </>
  );
};

export default ViewTask;

const useStyles = makeStyles(theme => ({
  descriptionContainer: { backgroundColor: '#00000009', padding: 10 },
  selectContainer: { paddingLeft: 16 },
  headLabel: { color: '#afafaf' },
  pl7: { paddingLeft: 7 }
}));

interface ViewTaskProps {
  row: { [key: string]: any };
  onClose: () => void;
  task: DynamicObject;
  mainListing?: boolean;
  onSuccess?: (assignees: number) => void;
}
interface TimeLogsItem {
  image_urls: DynamicObject;
  description: string;
  day: string;
  note: any;
  projectName: string;
  cost: string | number;
  duration: number;
}
