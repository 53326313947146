import React from 'react';
import { Grid, TextField, makeStyles } from '@material-ui/core';

import { InputFieldProps } from './types';
import ReactInputMask from 'react-input-mask';
const InputField = ({
  formik,
  label,
  gridSize,
  value,
  type,
  placeholder,
  ...props
}: InputFieldProps) => {
  const classes = useStyles();
  const isPhoneNumber = label?.toLowerCase().includes('number');

  return (
    <Grid sm={gridSize ?? 6} item>
      {isPhoneNumber ? (
        <PhoneNumberComponent formik={formik} value={value} label={label} />
      ) : (
        <TextField
          className={classes.labelSize}
          type={type}
          {...props}
          InputLabelProps={
            type === 'date' || (formik.touched[value] && formik.errors[value])
              ? { shrink: true }
              : {}
          }
          label={label}
          placeholder={placeholder}
          error={formik.touched[value] && formik.errors[value] ? true : false}
          helperText={formik.touched[value] && formik.errors[value]}
          {...formik.getFieldProps(value)}
        />
      )}
    </Grid>
  );
};
// @ts-ignore
const PhoneNumberComponent = ({ formik, value, label }) => (
  <ReactInputMask
    mask='(999) 999-9999'
    maskChar=' '
    value={formik.values[value]}
    onChange={event => formik.setFieldValue(value, event.target.value)}
  >
    {() => (
      <TextField
        name={value}
        label={label}
        style={{ width: '100%' }}
        error={formik.touched[value] && formik.errors[value] ? true : false}
        helperText={formik.touched[value] && formik.errors[value]}
      />
    )}
  </ReactInputMask>
);

export default React.memo(InputField);

const useStyles = makeStyles(theme => ({
  labelSize: {
    [theme.breakpoints.down('sm')]: {
      '&.MuiFormControl-root': {
        '& .MuiInputLabel-root': {
          fontSize: 12
        }
      }
    }
  }
}));
