export const messages = {
  deleteParentAlert:
    'Are you sure you want to delete it? It will delete all associated data',
  disclaimerText: `
    <ul>
      <li>Company will be billed after indicating acceptance of this quote.</li>
      <li>Payment will be due prior to delivery of service and goods,</li>
      <li>Please fax or mail the signed price quote to the address above.</li>
    </ul>
  `,
  deleteWithChildAlert: `Deleting parent task will detach child tasks, are you sure you want to proceed?`,
  deleteAlert: 'Are you sure you want to delete it?'
};
