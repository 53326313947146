import {
  GET_THEME_DATA,
  GET_THEME_DATA_SUCCESS,
  GET_THEME_DATA_FAIL
} from 'constants/index';

const initialState = { data: {}, loading: false, error: '' };

const theme = (
  state = initialState,
  action: { type: string; payload: any }
) => {
  switch (action.type) {
    case GET_THEME_DATA:
      return { ...state, loading: true };
    case GET_THEME_DATA_SUCCESS:
      return { ...state, loading: false, data: action.payload.current_tenant };
    case GET_THEME_DATA_FAIL:
      return { ...state, loading: false, error: action.payload };
    case 'CLEAR_ERROR':
      return { ...state, error: '' };
    default:
      return state;
  }
};

export default theme;
