import React from 'react';

import { useStyles } from '../styles';
import logo from 'assets/images/logo.png';
import dots from 'assets/images/dots.png';
import 'css/animate.css';

const Intro = () => {
  const classes = useStyles();
  return (
    <div className={classes.imageContainer}>
      <img src={logo} alt='logo' width={300} className={classes.logo} />
      <img src={dots} alt='img' width={175} className='ml30' />
      <div className={classes.bounceCircle} />
    </div>
  );
};

export default React.memo(Intro);
