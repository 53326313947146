import {
  GET_COMPANY_DETAILS,
  GET_COMPANY_DETAILS_FAIL,
  GET_COMPANY_DETAILS_SUCCESS
} from 'constants/index';

const initial = { data: {}, loading: false, error: '' };

const company = (
  state = initial,
  action: { type: string; payload: { data: {} } }
) => {
  switch (action.type) {
    case 'CLEAR_ERROR':
      return { ...state, error: '' };
    case GET_COMPANY_DETAILS:
      return { ...state, loading: true };
    case GET_COMPANY_DETAILS_SUCCESS:
      return { ...state, data: action.payload.data, loading: false };
    case GET_COMPANY_DETAILS_FAIL:
      return { ...state, error: action.payload, loading: false };
    default:
      return state;
  }
};

export default company;
