import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
  imageContainer: {
    position: 'relative',
    backgroundColor: theme.palette.primary.main,
    minHeight: '100vh',
    overflow: 'hidden',
    height: '100%'
  },
  logo: {
    position: 'absolute',
    bottom: '50%',
    right: 50,
    width: 350
  },

  bounceCircle: {
    position: 'absolute',
    top: '75%',
    left: '55%',
    border: '45px solid rgba(255,255,255, 0.4)',
    borderRadius: 900,
    height: 600,
    width: 600,
    zIndex: 2,
    animationName: 'bounce',
    animationDuration: '4s',
    animationIterationCount: 'infinite'
  },
  container: {
    minHeight: '100vh',
    height: '100%',
    backgroundPosition: 'center',
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    aliginItems: 'center',
    position: 'relative',
    zIndex: 3,
    background: theme.palette.background.default,
    padding: '10px 0'
  },
  form: {
    alignSelf: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    borderRadius: 5,
    boxShadow: `0 1px 5px 0 rgba(0,0,0,.2), 0 2px 2px 0 rgba(0,0,0,.14), 0 3px 1px -2px rgba(0,0,0,.12)`
  },
  signupForm: {},
  fields: {
    width: 300,
    padding: '10px 0',
    margin: 30,
    marginBottom: 10,
    marginTop: 0
  },
  input: {
    '& Input': {
      textAlign: 'center',
      paddingBottom: 10,
      color: '#555',
      '&#password': {
        '-webkit-text-security': 'circle'
      }
    }
  },
  authFormBottom: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    '& a': { textDecoration: 'none', color: theme.palette.info.dark }
  },
  authBtn: {
    margin: '30px !important',
    marginBottom: '15px !important',
    marginTop: '15px !important'
  },
  icon: { alignSelf: 'center', marginBottom: 20, marginTop: 30 },
  loginIcon: { color: theme.palette.info.dark },
  signupIcon: { color: theme.palette.success.dark },
  forgetIcon: { color: theme.palette.error.light },
  footer: { margin: '10px auto' },
  verticalDivider: { color: theme.palette.grey[500] },
  trial:{
    fontSize:'14px',
  }
}));
