import {
  GET_QUOTEITEMS,
  DELETE_QUOTEITEM,
  CHANGE_QUOTEITEMS_PAGER,
  CHANGE_QUOTEITEMS_PAGE,
  PRINT_QUOTE
} from 'constants/index';
import { FORM_EDIT_DATA } from 'actions';
import { apiService } from 'middleware/api';

export const onGetQuoteItems = (
  quoteId: number,
  page = 1,
  perPage = 10,
  keyword = '',
  status = ''
) => {
  const endPoint = `quotes/${quoteId}/items.json?page=${page}&per_page=${perPage}&keyword=${keyword}&status=${status}`;
  const method = 'get';
  return apiService(endPoint, method, GET_QUOTEITEMS);
};

export const onGetQuoteItem = (id: number) => {
  const endPoint = `items/${id}.json`;
  const method = 'get';
  return apiService(endPoint, method, FORM_EDIT_DATA);
};

export const onDeleteQuoteItem = (id: number) => {
  const endPoint = `items/${id}.json`;
  const method = 'delete';
  return apiService(endPoint, method, DELETE_QUOTEITEM);
};

export const onChangeQuoteItemsPerPage = (perPage: number) => ({
  type: CHANGE_QUOTEITEMS_PAGER,
  payload: perPage
});

export const onChangeQuoteItemsPage = (page: number) => ({
  type: CHANGE_QUOTEITEMS_PAGE,
  payload: page
});

export const onPrintQuote = (quoteId: number) => {
  const endPoint = `quotes/${quoteId}/print_pdf`;
  const method = 'get';
  return apiService(endPoint, method, PRINT_QUOTE);
};
