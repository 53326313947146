import React, { useState } from 'react';
import { useFormik } from 'formik';
import { FormHelperText, Grid } from '@material-ui/core';

import { useForm } from 'app/Common/Hooks/useForm';
import CustomDialog from 'app/Common/CustomDialog';
import CustomAlertMsg from 'app/Common/CustomAlertMsg';
import InputField from 'app/Common/InputFields';
import FormButtons from 'app/Common/FormButtons';
import { templateTaskFields } from 'assets/static/forms/setup/templateTaskFields';
import { TemplateTasksFormProps } from '../types';
import { templateTaskValidation } from 'validate/setup/templateTaskValidation';
import RichTextEditor from 'app/Common/RichTextEditor';

const ProjectTemplatesForm = ({ form, closeForm, templateTask, projectTemplateId }: TemplateTasksFormProps) => {
    const formHook = useForm();
    const { id } = templateTask;

    const [description, setDescription] = useState(templateTask.description ?? '')
    const [error, setError] = useState<null | string>(null);

    const formik = useFormik({
        initialValues: {
            title: templateTask.title ?? '',
            service: templateTask.service ?? '',
        },
        validationSchema: templateTaskValidation,
        onSubmit: values => {
            setDescription(description === '<p><br></p>' ? '' : description)
            if (!description.length || description === '<p><br></p>') return setError('Description is required');
            setError(null);

            const data = { ...values, project_template_id: projectTemplateId ?? '', description };
            id
                ? formHook.onSubmitPut(`project_task_templates/${id}.json`, data)
                : formHook.onSubmitPost(`project_task_templates.json`, data);
        }
    });

    const closeAlertHandle = () => {
        if (formHook.status === 'error') return;
        closeForm(true);
    };

    return (
        <>
            <CustomAlertMsg
                type={formHook.status}
                message={formHook.message}
                onClose={closeAlertHandle}
            />
            <CustomDialog
                open={form}
                closeHandle={() => {
                    if (formHook.loading) return;
                    closeForm(false);
                }}
                loading={formHook.loading}
                heading={id ? 'Update Template Task' : 'Create New Template Task'}
                footer='hide'
            >
                <Grid
                    container
                    spacing={2}
                    component='form'
                    onSubmit={formik.handleSubmit}
                    className='mt10'
                >
                    {templateTaskFields.map(({ value, label }, index) => (
                        <InputField
                            formik={formik}
                            value={value}
                            label={label}
                            key={value}
                            autoFocus={index === 0}
                            fullWidth={true}
                            gridSize={6}
                            multiline={false}
                        />
                    ))}
                    <RichTextEditor
                        description={description}
                        setDescription={setDescription}
                        title='* Description'
                    />
                    <FormHelperText error className='ml10'>{error} </FormHelperText>
                    <FormButtons
                        id={id}
                        loading={formHook.loading}
                        onCancel={() => closeForm(false)}
                    />
                </Grid>
            </CustomDialog>
        </>
    );
};

export default React.memo(ProjectTemplatesForm);