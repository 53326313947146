import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import List from './List';
import TopBar from './Topbar';
import {
  onChangeSubscriptionsPerPage,
  onGetSubscriptions,
  onChangeSubscriptionsPage
} from 'actions';
import { Context } from 'app/Dashboard';
import CustomAlertMsg from 'app/Common/CustomAlertMsg';
import { SubscriptionsProps } from '../types';
import Form from './Form';

const Subscriptions = () => {
  const { setPageTitle } = useContext(Context);
  setPageTitle('Subscriptions');
  const dispatch = useDispatch();
  const { data, loading, error, message, page, perPage, count } = useSelector(
    ({ subscriptions }: SubscriptionsProps) => subscriptions
  );
  const [searchText, setSearchText] = useState('');
  const [form, setForm] = useState(false);
  const [companyId, setCompanyId] = useState('');
  const [fromDate, setFromDate] = useState('');
  const [toDate, setToDate] = useState('');

  const loadData = useCallback(() => {
    dispatch(
      onGetSubscriptions(page, perPage, searchText, companyId, fromDate, toDate)
    );
  }, [dispatch, perPage, page, searchText, companyId, fromDate, toDate]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  const handleSearchText = (text: string) => {
    setSearchText(text);
    dispatch(onChangeSubscriptionsPage(1));
  };

  const closeFormHandle = (loadList: boolean) => {
    loadList && loadData();
    setForm(false);
  };

  return (
    <>
      <CustomAlertMsg
        type={error.length ? 'error' : 'success'}
        message={error || message}
        onClose={() => {
          message.length && loadData();
        }}
      />
      <TopBar
        loading={loading}
        setForm={setForm}
        setSearchText={handleSearchText}
        setCompanyId={setCompanyId}
        alldata={data}
        fromDate={fromDate}
        setDate={setFromDate}
        endingDate={toDate}
        setEndDate={setToDate}
      />
      <List
        data={data}
        loading={loading}
        page={page}
        perPage={perPage}
        count={count}
        changePage={page => dispatch(onChangeSubscriptionsPage(page))}
        changePerPageHandle={perPage => {
          dispatch(onChangeSubscriptionsPage(1));
          dispatch(onChangeSubscriptionsPerPage(perPage));
        }}
      />
      {form && (
        <Form form={form} closeForm={closeFormHandle} loading={loading} />
      )}
    </>
  );
};

export default Subscriptions;
