import React from 'react';

import { DynamicObject } from 'app/Common/types';
import {
  Grid,
  List,
  ListItem,
  Divider,
  ListItemText,
  Chip
} from '@material-ui/core';
import {
  Assignment as AssignmentIcon,
  ArrowRightAlt as ArrowRightAltIcon
} from '@material-ui/icons';
import { makeStyles } from '@material-ui/styles';
const TaskHistory = ({ audits }: TaskHistoryProps) => {
  const classes = useStyles();

  function getTaskName(object: any) {
    for (let key in object) return object[key];
  }
  return (
    <Grid>
      <List>
        {audits.map((value, index) => {
          const { action, audited_changes, auditable_type } = value;
          const name = `${value.username ?? 'User'}`;

          if (auditable_type === 'TimeLog')
            return (
              <>
                <ListItem alignItems='flex-start'>
                  <ListItemText
                    primary={
                      <React.Fragment>
                        <p key={index}>
                          <b>{name}</b> logged{' '}
                          <b>{audited_changes?.duration}</b> hours.
                        </p>
                      </React.Fragment>
                    }
                  ></ListItemText>
                </ListItem>
                <Divider />
              </>
            );
          if (action === 'create')
            return (
              <>
                <ListItem alignItems='flex-start'>
                  <ListItemText
                    primary={
                      <React.Fragment>
                        <p key={index}>
                          <b>{name}</b> {action + 'd'} the task
                        </p>
                      </React.Fragment>
                    }
                  ></ListItemText>
                </ListItem>
                <Divider />
              </>
            );
          return (
            <>
              <ListItem alignItems='flex-start'>
                <ListItemText
                  primary={
                    <React.Fragment>
                      <p key={index}>
                        <b>{name}</b> {action + 'd'} the task with following
                        changes:
                      </p>
                    </React.Fragment>
                  }
                  secondary={
                    <div className={classes.tasksContainer}>
                      <Chip
                        label={getTaskName(audited_changes)?.[0]}
                        icon={<AssignmentIcon fontSize='small' />}
                      />
                      <ArrowRightAltIcon fontSize='large' />
                      <Chip
                        label={getTaskName(audited_changes)?.[1]}
                        icon={<AssignmentIcon fontSize='small' />}
                      />
                    </div>
                  }
                />
              </ListItem>
              <Divider />
            </>
          );
        })}
      </List>
    </Grid>
  );
};

export default React.memo(TaskHistory);

const useStyles = makeStyles({
  tasksContainer: { display: 'flex', alignItems: 'center' }
});
interface TaskHistoryProps {
  audits: Array<DynamicObject>;
}
