export const getTimeInterval = date => {
  const seconds = Math.floor((new Date() - new Date(date)) / 1000);
  let interval = Math.floor(seconds / 31536000);
  if (interval > 1) {
    return `${interval} ${'years'} ${'ago'}`;
  }
  if (interval === 1) {
    return `${interval} ${'years'} ${'ago'}`;
  }
  interval = Math.floor(seconds / 2592000);
  if (interval > 1) {
    return `${interval} ${'months'} ${'ago'}`;
  }
  if (interval === 1) {
    return `${interval} ${'month'} ${'ago'}`;
  }
  interval = Math.floor(seconds / 604800);
  if (interval > 1) {
    return `${interval} ${'weeks'} ${'ago'}`;
  }
  if (interval === 1) {
    return `${interval} ${'week'} ${'ago'}`;
  }
  interval = Math.floor(seconds / 86400);
  if (interval > 1) {
    return `${interval} ${'days'} ${'ago'}`;
  }
  if (interval === 1) {
    return `${interval} ${'day'} ${'ago'}`;
  }
  interval = Math.floor(seconds / 3600);
  if (interval > 1) {
    return `${interval} ${'hours'} ${'ago'}`;
  }
  if (interval === 1) {
    return `${interval} ${'hour'} ${'ago'}`;
  }
  interval = Math.floor(seconds / 60);
  if (interval > 1) {
    return `${interval} ${'minutes'} ${'ago'}`;
  }
  if (interval === 1) {
    return `${interval} ${'minute'} ${'ago'}`;
  }
  if (Math.floor(seconds) < 5) {
    return 'Now';
  }
  return `${Math.floor(seconds)} ${'Seconds'} ${'Ago'}`;
};
