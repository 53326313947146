import {
  GET_COMPANIES,
  DELETE_COMPANY,
  CHANGE_COMPANIES_PAGER,
  CHANGE_COMPANIES_PAGE,
  CHANGE_COMPANY_STATUS,
  GET_COMPANIES_COUNT,
  GET_SALES_PERSONS,
  GET_COMPANY_CONTACTS,
  GET_ACCOUNT_TRANSFER
} from 'constants/index';
import { FORM_EDIT_DATA } from 'actions';
import { apiService } from 'middleware/api';
import { companyStatusChangeData } from '../../types';

export const onGetCompanies = (
  page = 1,
  perPage = 10,
  keyword = '',
  status = ''
) => {
  const modifyString = keyword.replace(/&/g, '%26');
  const endPoint = `companies.json?page=${page}&per_page=${perPage}&keyword=${modifyString}&status=${status}`;
  const method = 'get';
  return apiService(endPoint, method, GET_COMPANIES);
};
export const onGetCompanyContacts = (
  contactId: string | number,
  page = 1,
  perPage = 10,
  keyword = ''
) => {
  const endPoint = `companies/${contactId}/contacts.json?page=${page}&per_page=${perPage}&keyword=${keyword}`;
  const method = 'get';
  return apiService(endPoint, method, GET_COMPANY_CONTACTS);
};

export const onGetCompany = (key: string) => {
  const endPoint = `companies/${key}.json`;
  const method = 'get';
  return apiService(endPoint, method, FORM_EDIT_DATA);
};

export const onDeleteCompany = (key: string) => {
  const endPoint = `companies/${key}.json`;
  const method = 'delete';
  return apiService(endPoint, method, DELETE_COMPANY);
};

export const onChangeCompaniesPerPage = (perPage: number) => ({
  type: CHANGE_COMPANIES_PAGER,
  payload: perPage
});

export const onChangeCompaniesPage = (page: number) => ({
  type: CHANGE_COMPANIES_PAGE,
  payload: page
});

export const onChangeCompanyStatus = (data: companyStatusChangeData) => {
  const endPoint = `companies/${data.id}.json`;
  const method = 'put';
  return apiService(endPoint, method, CHANGE_COMPANY_STATUS, data);
};

export const onGetCompaniesCount = () => {
  const endPoint = `companies/status_count`;
  const method = 'get';
  return apiService(endPoint, method, GET_COMPANIES_COUNT);
};

export const onGetSalesPersons = (page = 1, perPage = 10, keyword = '') => {
  const endPoint = `users.json?page=${page}&per_page=${perPage}&keyword=${keyword}`;
  const method = 'get';
  return apiService(endPoint, method, GET_SALES_PERSONS);
};

export const onTransferAllAccounts = (
  selecteruserId: number,
  assignedUserId: number
) => {
  const endPoint = `companies/transfer_account.json`;
  const method = 'post';
  let data = {
    from_user: selecteruserId,
    to_user: assignedUserId
  };
  return apiService(endPoint, method, GET_ACCOUNT_TRANSFER, data);
};

export const onTransferAccountsWithStatus = (
  selectedAccountStatus: string,
  selecteruserId: number,
  assignedUserId: number
) => {
  const endPoint = `companies/transfer_account.json`;
  const method = 'post';
  let data = {
    status: selectedAccountStatus,
    from_user: selecteruserId,
    to_user: assignedUserId
  };
  return apiService(endPoint, method, GET_ACCOUNT_TRANSFER, data);
};

export const onChnageAllStatus = (currentStatus: string, newStatus: string) => {
  const endPoint = `companies/change_status.json`;
  const method = 'post';
  let data = {
    current_status: currentStatus,
    new_status: newStatus
  };
  return apiService(endPoint, method, GET_ACCOUNT_TRANSFER, data);
};

export const onChnageSpecificStatus = (
  selectedUserStatusId: number,
  newStatus: string,
  currentStatus: string
) => {
  const endPoint = `companies/change_status.json`;
  const method = 'post';
  let data = {
    sales_person_id: selectedUserStatusId,
    current_status: currentStatus,
    new_status: newStatus
  };
  return apiService(endPoint, method, GET_ACCOUNT_TRANSFER, data);
};

export const onTransferAccountsCsvStatus = (data: FormData) => {
  const endPoint = `companies/transfer_account_csv.json`;
  const method = 'post';
  return apiService(endPoint, method, GET_ACCOUNT_TRANSFER, data, true);
};

export const onTransferStatusCsv = (data: FormData) => {
  const endPoint = `companies/change_status_csv.json`;
  const method = 'post';
  return apiService(endPoint, method, GET_ACCOUNT_TRANSFER, data, true);
};
