import { Avatar, Box, Divider, Grid } from '@material-ui/core';
import React from 'react';

const SingleCommentUI = () => {
  return (
    <Box component='div' my={2}>
      <Grid container spacing={0}>
        <Grid
          item
          xs={12}
          style={{
            display: 'flex',

            alignItems: 'center'
          }}>
          <Avatar style={{ background: 'purple' }}>SA</Avatar>
          <Box
            ml={1}
            component='div'
            style={{ display: 'flex', flexDirection: 'column' }}>
            <h4 style={{ margin: '0px', padding: '0px' }}>Sultan Aslam</h4>
            <p style={{ margin: '0px', padding: '0px' }}>All is well</p>
          </Box>
        </Grid>
      </Grid>
      <Box component='div' my={2}>
        <Divider />
      </Box>
    </Box>
  );
};

export default SingleCommentUI;
