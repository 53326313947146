import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Grid, Typography } from '@material-ui/core';

import List from './List';
import Topbar from './Topbar';
import Footer from './Footer';
import QuoteSubmitForm from './QuoteSubmitForm';
import { onGetAcceptQuote } from 'actions';
import CustomAlertMsg from 'app/Common/CustomAlertMsg';
import { AcceptQuoteProps } from '../types';

const AcceptQuote = () => {
  const [formType, setFormType] = useState('');
  const { token } = useParams<{ token: string }>();
  const dispatch = useDispatch();
  const { quote, loading, error, message, items } = useSelector(
    ({ acceptQuote }: AcceptQuoteProps) => acceptQuote
  );
  const loadData = useCallback(() => {
    dispatch(onGetAcceptQuote({ token }));
  }, [dispatch, token]);
  useEffect(() => {
    loadData();
  }, [loadData]);
  const afterSuccessHandle = () => {
    if (message.length) {
      setFormType('');
      loadData();
    }
  };
  return (
    <Grid className='m10'>
      <CustomAlertMsg
        type={error.length ? 'error' : 'success'}
        message={error || message}
        onClose={afterSuccessHandle}
      />
      <Typography
        className='m10'
        variant='h2'
        component='h2'
        color='textSecondary'
        align='center'
      >
        {quote.company?.name}
      </Typography>
      <Topbar
        setFormType={setFormType}
        status={quote.status}
        loading={loading}
      />
      <List data={items} loading={loading} />
      {quote.id && (
        <Footer
          quoteId={quote.id}
          total={Number(quote.quote_totals?.grand_total ?? 0)}
          subTotal={quote.quote_totals?.subtotal}
          quoteShipping={quote.shipping}
          taxCode={quote.tax_code?.name}
          taxcodeId={quote.tax_code_id}
          reloadQuote={loadData}
          disclaimer={quote.disclaimer}
          quoteTaxes={quote.quote_taxes}
        />
      )}
      {formType !== '' && (
        <QuoteSubmitForm formType={formType} setFormType={setFormType} />
      )}
    </Grid>
  );
};

export default AcceptQuote;
