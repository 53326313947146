import { makeStyles } from '@material-ui/styles';
import CustomTable from 'app/Common/CustomTable';
import FieldChangeButton from 'app/Common/FieldChangeButton';
import ListDeleteButton from 'app/Common/ListDeleteButton';
import ListEditButton from 'app/Common/ListEditButton';
import { DynamicObject } from 'app/Common/types';
import { messages } from 'assets/static/strings/messages';
import React from 'react';
import { dateYMDFormat } from 'utils/common/dateYMDFormat';
import { TasksListProps } from '../types';

const useStyles = makeStyles({
  red: {
    color: 'red'
  }
});

const TasksList = ({
  listing,
  data,
  loading,
  onDeleteTask,
  page,
  perPage,
  count,
  changePerPageHandle,
  changePage,
  onChangeTaskStatus,
  showDetailsHandle,
  mainListing,
  onSort,
  sortBy,
  StatusList,
  StatusListLoading
}: TasksListProps) => {
  const classes = useStyles();
  const headItems = ['Title', 'Assignee', 'Service', 'Due Date', 'Status'];
  const columns = [
    'title',
    'assignee.first_name+assignee.last_name',
    'task_service.name',
    'dueDate',
    'statusBtn'
  ];
  const rows = data.map(row => {
    const current_date = new Date();
    current_date.setHours(0, 0, 0, 0);
    const due = new Date(row.due_date);
    due.setHours(0, 0, 0, 0);
    const valid = current_date.getTime() > due.getTime() ? true : false;
    row.dueDate = row.due_date ? (
      <span className={`${valid ? classes.red : ''}`}>
        {dateYMDFormat(row.due_date)}
      </span>
    ) : (
      'N/A'
    );
    row.statusBtn = (
      <FieldChangeButton
        backgroundColor={row.task_status?.color}
        loading={loading}
        onChange={({ id, task_status_id }) =>
          onChangeTaskStatus({ id, task_status_id })
        }
        isTaskStatus
        field='status'
        title='Change task status'
        data={row}
        list={StatusList}
      />
    );
    return row;
  });
  const actions = (row: DynamicObject) => {
    return [
      <ListEditButton loading={loading} name='task' />,
      <ListDeleteButton
        loading={loading}
        onClickHandler={onDeleteTask}
        message={
          row.child_tasks?.length
            ? messages.deleteWithChildAlert
            : messages.deleteParentAlert
        }
      />
    ];
  };

  if (listing)
    return (
      <CustomTable
        rows={data}
        headItems={['Title']}
        columns={['title']}
        loading={loading}
        perPage={perPage}
        page={page}
        count={count}
        changePageHandle={(e, page) => changePage(page)}
        changePerPageHandle={perPage => {
          changePage(1);
          changePerPageHandle(perPage);
        }}
        rowClickHandle={showDetailsHandle}
      />
    );
  return (
    <CustomTable
      rows={rows}
      headItems={headItems}
      sortingHeadItems={mainListing ? ['Due Date'] : []}
      columns={columns}
      loading={loading}
      perPage={perPage}
      page={page}
      count={count}
      isScroller
      changePageHandle={(e, page) => changePage(page)}
      changePerPageHandle={perPage => {
        changePage(1);
        changePerPageHandle(perPage);
      }}
      actions={actions}
      rowClickHandle={showDetailsHandle}
      onSort={onSort}
      sortBy={sortBy}
    />
  );
};

export default React.memo(TasksList);
