import React, { Suspense, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

import clsx from 'clsx';
import { onGetCompaniesCount, onGetThemeData } from 'actions';
import { onGetUsers } from '../../actions';
import { AuthProps, DashboardProps, Theme } from '../../types';
import AcceptInvite from './AcceptInvite/components';
import AcceptOrganizationMsg from './components/AcceptOrganizationMsg';
import Appbar from './components/Appbar';
import Sidebar from './components/Sidebar';
import { useStyles } from './styles';
import SalesLogin from './SalesLogin';
import { useMediaQuery } from '@material-ui/core';

export const Context = React.createContext(
  {} as { pageTitle: string; setPageTitle: (title: string) => void }
);

const Dashboard = ({ children, setStyles }: DashboardProps) => {
  const isMobile = useMediaQuery('(max-width:500px)');
  const [drawerOpen, setOpen] = useState(true);
  const [pageTitle, setPageTitle] = useState('Dashboard');

  const { push } = useHistory();
  const { pathname } = useLocation();
  const classes = useStyles();
  const dispatch = useDispatch();
  type authThemeData = AuthProps & Theme;
  const {
    auth: { user },
    theme: { data }
  } = useSelector(({ auth, theme }: authThemeData) => ({ auth, theme }));

  useEffect(() => {
    if (
      user.current_tenant?.super_user_id === user.id &&
      !user.current_tenant?.stripe_subscription?.stripe_subscription_id &&
      !user.free
    ) {
      push('/subscription-plans');
    } else if (user.role === 'hr') {
      push('/newHireAutomation');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [push, user.current_tenant, user.free, user.id]);

  const loadUsersData = useCallback(() => {
    dispatch(onGetUsers(1, 50, ''));
  }, [dispatch]);

  useEffect(() => {
    loadUsersData();
  }, [loadUsersData]);

  useEffect(() => {
    dispatch(onGetThemeData());
    dispatch(onGetCompaniesCount());
  }, [dispatch]);
  useEffect(() => {
    pathname === '/dashboard' &&
      pageTitle !== 'Dashboard' &&
      setPageTitle('Dashboard');
  }, [pathname, pageTitle]);
  useEffect(() => {
    data &&
      setStyles(
        data.primary_color,
        data.secondary_color,
        data?.logo_urls?.original
      );
  }, [data, setStyles]);

  if (!localStorage.token) {
    window.location.href = '/';
    return null;
  }

  if (
    pathname.includes('subscription-plans') ||
    pathname.includes('stripe-card') ||
    pathname.includes('card-details')
  ) {
    return null;
  }

  if (
    user.organization_users?.length &&
    !user.current_tenant?.stripe_subscription?.stripe_subscription_id &&
    !user.super_user?.free
  ) {
    return (
      <>
        <SalesLogin />
      </>
    );
  }

  if (pathname !== '/organization' && !user.current_tenant)
    push('/organization?isnew=true');

  return (
    <Context.Provider value={{ pageTitle, setPageTitle }}>
      <AcceptInvite />
      <AcceptOrganizationMsg />
      <Appbar drawerOpen={drawerOpen} setOpen={setOpen} user={user} />
      {!isMobile && (
        <Sidebar drawerOpen={drawerOpen} setOpen={setOpen} user={user} />
      )}
      <main
        className={clsx(classes.content, {
          [classes.contentShift]: drawerOpen
        })}
      >
        <Suspense fallback={'Loading...'}>{children}</Suspense>
      </main>
    </Context.Provider>
  );
};

export default Dashboard;
