import Auth from 'app/Auth/components/index';
import Dashboard from 'app/Dashboard';
import ExpenseCategory from 'app/Dashboard/Setup/Expense-Category/components';
import TemplateTasks from 'app/Dashboard/Setup/Project-Templates/TemplateTasks/components';
import Subscriptions from 'app/Dashboard/Subscriptions/components';
import SubTask from 'app/Dashboard/SubTask/components';
import AcceptQuote from 'app/UnAuthorize/AcceptQuote/components/index';
import { authRoutes } from 'assets/static/authRoutes';
import React, { lazy, Suspense } from 'react';
import {
  Route,
  RouteProps,
  Switch,
  useHistory,
  useLocation
} from 'react-router-dom';
import { paramsToObject } from 'utils/common/paramsToObject';
import ErrorBoundary from './ErrorBoundary';
import PrivateRoute from './PrivateRoute';

import ExternalCyberSecurityForm from 'app/Dashboard/CyberSecurityForm/components/externalCyberSecurityForm';

const Companies = lazy(() => import('app/Dashboard/Companies/components'));
const Organizations = lazy(
  () => import('app/Dashboard/Organizations/components')
);
const Company = lazy(() => import('app/Dashboard/Company/components'));
const Products = lazy(() => import('app/Dashboard/Products/components'));
const Kanban = lazy(() => import('app/Dashboard/KanBan'));
const Reminders = lazy(() => import('app/Dashboard/Reminders/components'));
const CyberSecurityForm = lazy(
  () => import('app/Dashboard/CyberSecurityForm/components')
);
const NewHireAutomation = lazy(
  () => import('app/Dashboard/NewHireAutomation/components')
);

const AdminSettings = lazy(() => import('app/Dashboard/Admin-Settings'));
const Setup = lazy(() => import('app/Dashboard/Setup/components'));
const Project = lazy(() => import('app/Dashboard/Project/components'));
const Projects = lazy(() => import('app/Dashboard/Projects/components'));
const Tasks = lazy(() => import('app/Dashboard/Tasks/components'));
const AcceptInvite = lazy(
  () => import('app/Dashboard/AcceptInvite/components')
);
const ThemeCustomize = lazy(
  () => import('app/Dashboard/Setup/ThemeCustomize/components')
);
const WorkLogs = lazy(() => import('app/Dashboard/WorkLogs/components'));
const Home = lazy(() => import('app/Dashboard/Home'));
const Taxes = lazy(() => import('app/Dashboard/Setup/Taxes/components'));
const Quotes = lazy(() => import('app/Dashboard/Quotes/components'));
const Disclaimers = lazy(() => import('app/Dashboard/Disclaimers/components'));

const QuoteItems = lazy(() => import('app/Dashboard/QuoteItems/components'));
const SubscriptionItems = lazy(
  () => import('app/Dashboard/SubscriptionItems/components')
);
const TimeCard = lazy(() => import('app/Dashboard/Time-Card/components/index'));
const SubscriptionPlans = lazy(
  () => import('app/Dashboard/components/Appbar/SubscriptionPlans')
);
const StripeCardForm = lazy(
  () => import('app/Dashboard/StripeCard/components/index')
);
const StripeCardDetails = lazy(
  () => import('app/Dashboard/StripeCard/components/CardDetails')
);

const NoMatch = ({ location }: RouteProps) => (
  <div>
    <h3>
      No Found for <code>{location && location.pathname}</code>
    </h3>
  </div>
);

const Routes = ({
  setStyles
}: {
  setStyles: (pc: string, sc: string, logo: string) => void;
}) => {
  const { search, pathname } = useLocation();
  const { push } = useHistory();
  const acceptOrgToken = paramsToObject(search).invitation_token;
  if (pathname === '/accept-organization-request' && acceptOrgToken) {
    localStorage.setItem('acceptOrgToken', acceptOrgToken);
    push('/');
  }
  return (
    <ErrorBoundary>
      <Switch>
        {authRoutes.map(path => (
          <Route exact path={path} component={Auth} key={path} />
        ))}
        <Route
          path='/quote-details/:token'
          component={AcceptQuote}
          clientAcceptance
        />
        <Route
          path='/cyberSecurityForm'
          component={ExternalCyberSecurityForm}
        />
        <Suspense fallback={'Loading...'}>
          <PrivateRoute
            path='/subscription-plans'
            component={SubscriptionPlans}
          />
          <PrivateRoute path='/stripe-card' component={StripeCardForm} />
          <PrivateRoute path={'/card-details'} component={StripeCardDetails} />

          <Dashboard setStyles={setStyles}>
            <Route
              render={() => (
                <Switch>
                  <PrivateRoute path='/dashboard' component={Home} />
                  <PrivateRoute
                    path='/organization'
                    component={Organizations}
                  />
                  <PrivateRoute path='/companies' component={Companies} />
                  <PrivateRoute path='/company/:id' component={Company} />
                  <PrivateRoute
                    path='/project/:companyId/:id'
                    component={Project}
                  />
                  <PrivateRoute path='/products' component={Products} />
                  <PrivateRoute path='/kanban' component={Kanban} />
                  <PrivateRoute path='/reminders' component={Reminders} />
                  <PrivateRoute
                    path='/cyberSecurityFormsListing'
                    component={CyberSecurityForm}
                  />
                  <PrivateRoute
                    path='/newHireAutomation'
                    component={NewHireAutomation}
                  />

                  <PrivateRoute
                    path='/admin/:tabId'
                    component={AdminSettings}
                  />

                  <PrivateRoute
                    exact
                    path='/admin-setup/theme'
                    component={ThemeCustomize}
                  />
                  <PrivateRoute path='/admin-setup/:tabId' component={Setup} />
                  <PrivateRoute
                    path='/accept-organization-request'
                    component={AcceptInvite}
                  />
                  <PrivateRoute
                    path='/worklogs/:companyId/:companyName'
                    render={() => <WorkLogs mainListing />}
                  />
                  <PrivateRoute
                    path='/worklogs'
                    render={() => <WorkLogs mainListing />}
                  />

                  <PrivateRoute path='/timecards' render={() => <TimeCard />} />

                  <PrivateRoute path='/taxes' component={Taxes} />
                  <PrivateRoute path='/quotes' component={Quotes} />
                  <PrivateRoute path='/disclaimers' component={Disclaimers} />

                  <PrivateRoute
                    exact
                    path='/subscriptions'
                    component={Subscriptions}
                  />
                  <PrivateRoute
                    exact
                    path='/subscription/:subscriptionId'
                    component={SubscriptionItems}
                  />
                  <PrivateRoute
                    path='/projects'
                    render={() => <Projects mainListing />}
                  />
                  <PrivateRoute
                    path='/tasks'
                    render={() => <Tasks mainListing />}
                  />
                  <PrivateRoute
                    path='/subtask/:subTaskId'
                    component={SubTask}
                  />
                  <PrivateRoute path='/quote/:quoteId' component={QuoteItems} />
                  <PrivateRoute
                    path='/template-task/:projectTemplateId'
                    component={TemplateTasks}
                  />
                  <PrivateRoute
                    path='/expense-category'
                    component={ExpenseCategory}
                  />
                  <Route component={NoMatch} />
                </Switch>
              )}
            />
          </Dashboard>
        </Suspense>
      </Switch>
    </ErrorBoundary>
  );
};

export default Routes;
