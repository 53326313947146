import React from 'react';
import { Button, Grid } from '@material-ui/core';
import { Add } from '@material-ui/icons';

import ListSearcher from 'app/Common/ListSearcher';
import { SubscriptionsTopbarProps } from '../types';
import AsyncSearch from 'app/Common/AsyncSearch';
import { useDispatch, useSelector } from 'react-redux';
import { onGetCompanies } from 'actions';
import { CSVLink } from 'react-csv';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

const SubscriptionsTopbar = ({
  loading,
  setForm,
  setSearchText,
  setCompanyId,
  alldata,
  fromDate,
  setDate,
  endingDate,
  setEndDate
}: SubscriptionsTopbarProps) => {
  const dispatch = useDispatch();
  const { data, loading: companiesLoading } = useSelector(
    ({ companies }: CompaniesProps) => companies
  );

  const DataRecordArray: any = [];
  alldata.forEach((item: any) => {
    item?.subscription_items.map((singlesubscrption: any) => {
      const recordObjec = {
        no: item?.full_number,
        company: item?.company?.name,
        preparedBy: `${item?.user?.first_name} ${item?.user?.last_name}`,
        lastUpdated: item?.subscription_month,
        productCode: singlesubscrption?.product?.internal_code,
        productName: singlesubscrption?.product?.name,
        quntity: singlesubscrption?.quantity,
        price: singlesubscrption?.product?.cost,
        unitPrice: singlesubscrption?.product?.unit_price,
        manufacturer: singlesubscrption?.product?.manufacturer
      };
      DataRecordArray.push(recordObjec);
    });
  });

  const csvReport: any = {
    data: DataRecordArray,
    headers,
    filename: 'Subscription_Report.csv'
  };

  const months = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec'
  ];

  return (
    <Grid
      container
      className='mt10 mb10'
      justify='space-between'
      alignItems='flex-end'
    >
      <Grid container item sm={8}>
        <div className='subscriptionSearch'>
          <ListSearcher searchTextHandle={setSearchText} loading={loading} />
        </div>
        <Grid item sm={4} className='ml5'>
          <AsyncSearch
            data={data}
            getHandle={(page, perPage, keyword) =>
              dispatch(onGetCompanies(page, perPage, keyword))
            }
            selectHandle={setCompanyId}
            loading={companiesLoading}
            label='Search by Company'
          />
        </Grid>
        <Grid item sm={2} className='ml5 subscription-date'>
          <FormControl>
            <InputLabel id='select-fromMonth-label'>From Month</InputLabel>
            <Select
              labelId='select-fromMonth-label'
              id='demo-simple-select'
              value={fromDate}
              onChange={(e: any) => setDate(e.target.value)}
            >
              <MenuItem value=''>
                <em>None</em>
              </MenuItem>
              {months.map((singleMonth: string, i: number) => (
                <MenuItem
                  value={`${new Date().getFullYear()}-${i + 1}-01`}
                  key={i}
                >
                  {singleMonth}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item sm={2} className='ml5 subscription-date'>
          <FormControl>
            <InputLabel id='select-toMonth-label'>To Month</InputLabel>
            <Select
              labelId='select-toMonth-label'
              id='demo-simple-select'
              value={endingDate}
              onChange={(e: any) => {
                setEndDate(e.target.value);
                let chktoDate = new Date(e.target.value);
                let checkfromdate = new Date(fromDate);
                if (chktoDate < checkfromdate || fromDate === '') {
                  setDate(`${new Date().getFullYear()}-1-01`);
                }
              }}
            >
              <MenuItem value=''>
                <em>None</em>
              </MenuItem>
              {months.map((singleMonth: string, i: number) => (
                <MenuItem
                  value={`${new Date().getFullYear()}-${i + 1}-${new Date(
                    new Date().getFullYear(),
                    i + 1,
                    0
                  ).getDate()}`}
                  key={i}
                >
                  {singleMonth}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      </Grid>
      <Grid container item sm={4} justify='flex-end'>
        <Grid item>
          <Button
            className='subscriptionExportBtn'
            size='small'
            color='primary'
            variant='contained'
            disabled={!DataRecordArray?.length}
          >
            <CSVLink {...csvReport}>Export to CSV</CSVLink>
          </Button>
        </Grid>
        <Grid item className='ml5'>
          <Button
            size='small'
            color='primary'
            variant='contained'
            disabled={loading}
            onClick={() => setForm(true)}
          >
            <Add /> Upload Subscription
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default React.memo(SubscriptionsTopbar);

interface CompaniesProps {
  companies: { data: Array<{ name: string; id: number }>; loading: boolean };
}

const headers = [
  { label: 'No', key: 'no' },
  { label: 'Company', key: 'company' },
  { label: 'Prepared By', key: 'preparedBy' },
  { label: 'Last Updated', key: 'lastUpdated' },
  { label: 'Product Code', key: 'productCode' },
  { label: 'Product Name', key: 'productName' },
  { label: 'Quantity', key: 'quntity' },
  { label: 'Price $', key: 'price' },
  { label: 'Unit Price $', key: 'unitPrice' },
  { label: 'Manufacturer', key: 'manufacturer' }
];
