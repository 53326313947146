import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Table, Paper, LinearProgress, Grid } from '@material-ui/core';

import Loader from './Loader';
import { CustomTableProps } from '../types/customTable';
import CustomTableHead from './CustomTableHead';
import CustomTableBody from './CustomTableBody';
import CustomTableFooter from './CustomTableFooter';
import noRecordImg from 'assets/images/norecord.png';

const CustomTable = (props: CustomTableProps) => {
  const { rows, size, loading } = props;

  const classes = useStyles();

  if (loading && !rows.length)
    return (
      <Paper className={classes.root}>
        <Loader loading={loading} noData={!rows.length} />
      </Paper>
    );

  return (
    <Paper className={classes.root}>
      {loading && <LinearProgress />}
      <Table className={classes.table} size={size || 'small'}>
        <CustomTableHead {...props} />
        <CustomTableBody {...props} />
        <CustomTableFooter {...props} />
      </Table>

      {!loading && !rows.length && (
        <Grid container justify='center' className={classes.noFound}>
          <img src={noRecordImg} alt='No Record Found' />
        </Grid>
      )}
    </Paper>
  );
};

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    overflowX: 'auto',
    marginTop: theme.spacing(1)
  },
  table: { minWidth: 295, position: 'relative' },
  noFound: { padding: '10px 0', '& img': { width: 295, maxWidth: '100%' } },
  paginationClass: {
    display: 'flex',
    justifyContent: 'flex-start',
    padding: ' 0px 17px'
  }
}));

export default CustomTable;
