export const GET_PROJECTS = 'GET_PROJECTS';
export const GET_PROJECTS_SUCCESS = 'GET_PROJECTS_SUCCESS';
export const GET_PROJECTS_FAIL = 'GET_PROJECTS_FAIL';
export const DELETE_PROJECT = 'DELETE_PROJECT';
export const DELETE_PROJECT_SUCCESS = 'DELETE_PROJECT_SUCCESS';
export const DELETE_PROJECT_FAIL = 'DELETE_PROJECT_FAIL';
export const CHANGE_PROJECTS_PAGER = 'CHANGE_PROJECTS_PAGER';
export const CHANGE_PROJECTS_PAGE = 'CHANGE_PROJECTS_PAGE';

export const GET_ORG_PROJECTS = 'GET_ORG_PROJECTS';
export const GET_ORG_PROJECTS_SUCCESS = 'GET_ORG_PROJECTS_SUCCESS';
export const GET_ORG_PROJECTS_FAIL = 'GET_ORG_PROJECTS_FAIL';
