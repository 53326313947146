export const GET_QUOTES = 'GET_QUOTES';
export const GET_QUOTES_SUCCESS = 'GET_QUOTES_SUCCESS';
export const GET_QUOTES_FAIL = 'GET_QUOTES_FAIL';
export const DELETE_QUOTE = 'DELETE_QUOTE';
export const DELETE_QUOTE_SUCCESS = 'DELETE_QUOTE_SUCCESS';
export const DELETE_QUOTE_FAIL = 'DELETE_QUOTE_FAIL';
export const CHANGE_QUOTES_PAGER = 'CHANGE_QUOTES_PAGER';
export const CHANGE_QUOTES_PAGE = 'CHANGE_QUOTES_PAGE';
export const CHANGE_QUOTE_STATUS = 'CHANGE_QUOTE_STATUS';
export const CHANGE_QUOTE_STATUS_SUCCESS = 'CHANGE_QUOTE_STATUS_SUCCESS';
export const CHANGE_QUOTE_STATUS_FAIL = 'CHANGE_QUOTE_STATUS_FAIL';
export const GET_QUOTE = 'GET_QUOTE';
export const GET_QUOTE_SUCCESS = 'GET_QUOTE_SUCCESS';
export const GET_QUOTE_FAIL = 'GET_QUOTE_FAIL';

export const CLEAR_QUOTE = 'CLEAR_QUOTE';
