import React, { ReactElement, useEffect, useState } from 'react';
import { Button, Grid, Switch } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import parse from 'html-react-parser';
import { useParams } from 'react-router-dom';

import CustomTag from 'app/Common/CustomTag';
import InfoItem from 'app/Common/InfoItem';
import { taskStatusItems } from 'assets/static/taskStatusItems';
import TaskHistory from 'app/Dashboard/Tasks/components/ViewTask/TaskHistory';
import WorkLogs from 'app/Dashboard/WorkLogs/components';
import { onGetTaskDetails } from 'actions';
import { DynamicObject } from 'app/Common/types';
import CustomTable from 'app/Common/CustomTable';
import { dateYMDFormat } from 'utils/common/dateYMDFormat';
import { Tasks as TaskTypes } from '../../Tasks/types';
import ListDeleteButton from 'app/Common/ListDeleteButton';
import { messages } from 'assets/static/strings/messages';
import Topbar from './Topbar';
import CustomAlertMsg from 'app/Common/CustomAlertMsg';
import { onDeleteChildTask } from '../redux/actions';

const SubTask = () => {
  const { subTaskId } = useParams<{ subTaskId: string }>();
  const [tab, setTab] = useState('0');
  const [listView, setListView] = useState(true);
  const {
    tasks: { loading, childTask: task },
    childTasks: { message }
  } = useSelector(
    (state: {
      tasks: TaskTypes;
      childTasks: { message: string; loading: boolean };
    }) => state
  );
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(onGetTaskDetails(Number(subTaskId), true));
    message?.length && dispatch({ type: 'CLEAR_ERROR' });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, message?.length]);
  useEffect(() => {
    dispatch(onGetTaskDetails(Number(subTaskId), true));
    //eslint-disable-next-line
  }, []);
  if (!Object.keys(task).length) return null;
  const status =
    taskStatusItems.find(({ value }) => value === task.status)?.name ?? '';
  const items = [
    {
      label: 'Status',
      value: (
        <div className='mt5'>
          <CustomTag title={status} />
        </div>
      )
    },
    {
      label: 'Assignee',
      value: `${task.assignee?.first_name ?? ''} ${
        task.assignee?.last_name ?? ''
      }`
    },
    { label: 'Company', value: task.company?.name },
    { label: 'Project', value: task.project?.name },
    { label: 'Service', value: task.task_service?.name },
    { label: 'Due Date', value: dateYMDFormat(task.due_date) }
  ];

  const updateList = task?.time_logs?.map((item: TimeLogsItem) => {
    item.note = <p>{parse(item?.description ?? '')}</p>;
    item.day = dateYMDFormat(item.day);
    item.projectName = task?.company?.name ?? '';
    return item;
  });
  const renderAssignedChildTask = () => {
    const parentTask = [task?.parent_task ?? {}];
    const headItems = ['Title', 'Description'];
    const columns = ['title', 'info'];
    const updatedData = parentTask?.map((row: DynamicObject) => {
      row.info = row.description ? <p>{parse(row?.description ?? '')}</p> : '';
      return row;
    });
    return (
      <Grid container item sm={12} justify='flex-start'>
        <Grid item sm={10}>
          <h4>Parent Task</h4>
          <CustomTable
            rows={updatedData ?? []}
            headItems={headItems}
            columns={columns}
            loading={loading}
            perPage={10}
            page={1}
            count={1}
            actions={row => [
              <ListDeleteButton
                loading={loading}
                onClickHandler={() =>
                  dispatch(
                    onDeleteChildTask(subTaskId, {
                      task: { remove_parent: true }
                    })
                  )
                }
                message={messages.deleteParentAlert}
              />
            ]}
          />
        </Grid>
      </Grid>
    );
  };
  const onCloseAlert = () =>
    message.length && dispatch(onGetTaskDetails(Number(subTaskId), true));

  return (
    <>
      <CustomAlertMsg type='success' message={message} onClose={onCloseAlert} />
      <Topbar subTaskName={task.title} />
      <Grid container spacing={2} className='pl5'>
        <Grid item sm={8}>
          <Grid item>
            <strong>Description</strong>
            <p>{parse(task?.description ?? '')}</p>
          </Grid>
          {task?.parent_task &&
            !!Object.values(task.parent_task) &&
            renderAssignedChildTask()}
        </Grid>
        <Grid item sm={4} container alignItems='flex-start'>
          <Grid item>
            {items.map(({ value, label }) => (
              <InfoItem
                key={label}
                label={label}
                value={value}
                loading={false}
              />
            ))}
          </Grid>
        </Grid>
        <Grid item sm={12}>
          <Button
            size='small'
            variant='contained'
            color={tab === '0' ? 'secondary' : 'default'}
            onClick={() => setTab('0')}
          >
            History
          </Button>
          <Button
            className='ml10'
            size='small'
            variant='contained'
            color={tab === '1' ? 'secondary' : 'default'}
            onClick={() => setTab('1')}
          >
            Work Logs
          </Button>
        </Grid>
        <Grid item sm={12}>
          {tab === '0' ? (
            <TaskHistory
              audits={task.task_audits.length ? task.task_audits : []}
            />
          ) : (
            <>
              Week View
              <Switch
                onChange={e => setListView(e.target.checked)}
                checked={listView}
              />
              List View
              {listView ? (
                <>
                  <WorkLogs
                    taskId={subTaskId}
                    taskDetail={task}
                    isCreateWorklog={true}
                  />
                  <CustomTable
                    rows={updateList ?? []}
                    headItems={['Project Name', 'Day', 'Hours', 'Description']}
                    columns={['projectName', 'day', 'duration', 'note']}
                    loading={false}
                    perPage={0}
                    page={1}
                    count={0}
                  />
                </>
              ) : (
                <WorkLogs taskId={subTaskId} taskDetail={task} />
              )}
            </>
          )}
        </Grid>
      </Grid>
    </>
  );
};

export default SubTask;

interface ViewTaskProps {
  row: { [key: string]: any };
  onClose: () => void;
  task: DynamicObject;
  mainListing?: boolean;
}
interface TimeLogsItem {
  image_urls: DynamicObject;
  description: string;
  day: string;
  attachment?: any;
  note: ReactElement;
  projectName: string;
  company: { name: string };
}
