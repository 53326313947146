import React from 'react';
import {
  Avatar,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  makeStyles
} from '@material-ui/core';
import { Image as ImageIcon, Close as CloseIcon } from '@material-ui/icons';

const AttachmentList = ({ files, setFiles }: AttachmentListProps) => {
  const classes = useStyles();
  const removeFile = (index: number) => {
    const newFiles = [...files];
    newFiles.splice(index, 1);
    setFiles && setFiles(newFiles);
  };
  return (
    <List className={classes.list}>
      {files.map((file, index) => (
        <ListItem key={file.path} className={classes.item}>
          <ListItemAvatar>
            <Avatar>
              <ImageIcon />
            </Avatar>
          </ListItemAvatar>
          <ListItemText primary={file.path || file.name} />
          {setFiles && (
            <ListItemSecondaryAction>
              <IconButton
                edge='end'
                aria-label='delete'
                onClick={() => removeFile(index)}
              >
                <CloseIcon />
              </IconButton>
            </ListItemSecondaryAction>
          )}
        </ListItem>
      ))}
    </List>
  );
};

export default AttachmentList;

type File = { [key: string]: any };
interface AttachmentListProps {
  files: Array<File>;
  setFiles?: (updatedFiles: Array<File>) => void;
}

const useStyles = makeStyles(() => ({
  list: { width: '100%' },
  item: { paddingLeft: 8, paddingRight: 8 }
}));
