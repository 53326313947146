import React from 'react';
import { makeStyles } from '@material-ui/core';

const CustomTag = ({ title, noTag }: { title: string; noTag?: boolean }) => {
  const classes = useStyles();
  return (
    <div className={classes.tag}>
      {!noTag && <span className={classes.icon} />}
      {title?.replace(/_/g, ' ')}
    </div>
  );
};

export default React.memo(CustomTag);

const useStyles = makeStyles(() => ({
  tag: {
    fontWeight: 500,
    minWidth: 110,
    width: 'max-content',
    textTransform: 'capitalize'
  },
  icon: {
    display: 'inline-block',
    padding: 4,
    background: '#a06e0f',
    borderRadius: '50%',
    marginRight: 3
  }
}));
