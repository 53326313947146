import React, { useCallback, useEffect, useState } from 'react';
import CustomAlertMsg from 'app/Common/CustomAlertMsg';
import { useDispatch, useSelector } from 'react-redux';
import { TemplateTasksProps } from '../types';
import {
  onChangeTemplateTaskPage,
  onChangeTemplateTaskPerPage,
  onDeleteTemplateTask,
  onGetTemplateTask,
  onGetTemplateTasks
} from '../redux/actions';
import List from './List';
import TopBar from './Topbar';
import Form from './Form';
import { useEditForm } from 'app/Common/Hooks/useEditForm';
import { useParams } from 'react-router-dom';

const TemplateTasks = () => {
  const dispatch = useDispatch();
  const { projectTemplateId } = useParams<{ projectTemplateId: string }>();
  const { data, loading, error, message, page, perPage, count } = useSelector(
    ({ templateTasks }: TemplateTasksProps) => templateTasks
  );
  const { id, formData, clearFormData, editLoading } = useEditForm(
    'projectTemplate',
    onGetTemplateTask,
    () => setForm(true)
  );

  const [searchText, setSearchText] = useState('');
  const [form, setForm] = useState(false);

  const loadData = useCallback(() => {
    dispatch(onGetTemplateTasks(page, perPage, searchText, projectTemplateId));
  }, [dispatch, perPage, page, searchText, projectTemplateId]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  const closeFormHandle = (loadList: boolean) => {
    loadList && loadData();
    id && clearFormData();
    setForm(false);
  };
  const handleSearchText = (text: string) => {
    setSearchText(text);
    dispatch(onChangeTemplateTaskPage(1));
  };
  return (
    <>
      <CustomAlertMsg
        type={error.length ? 'error' : 'success'}
        message={error || message}
        onClose={() => {
          message.length && loadData();
        }}
      />
      <TopBar
        loading={loading || (editLoading && !form)}
        setForm={setForm}
        setSearchText={handleSearchText}
      />
      <List
        data={data}
        loading={loading || (editLoading && !form)}
        page={page}
        perPage={perPage}
        count={count}
        changePage={page => dispatch(onChangeTemplateTaskPage(page))}
        changePerPageHandle={perPage => {
          dispatch(onChangeTemplateTaskPage(1));
          dispatch(onChangeTemplateTaskPerPage(perPage));
        }}
        onDeleteTemplateTask={id => dispatch(onDeleteTemplateTask(id))}
      />
      {form && (
        <Form
          form={form}
          projectTemplateId={projectTemplateId}
          closeForm={closeFormHandle}
          templateTask={formData}
          onGetTemplateTask={id => dispatch(onGetTemplateTask(id))}
          loading={loading}
        />
      )}
    </>
  );
};

export default TemplateTasks;
