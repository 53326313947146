import React from 'react';
import { Drawer, Divider } from '@material-ui/core';

import { SidebarProps } from '../../types';
import { useStyles } from '../../styles/sidebar';
import SidebarItems from './SidebarItems';

const Sidebar = ({ drawerOpen }: SidebarProps) => {
  const classes = useStyles();

  const currentOrglogo = localStorage.getItem('logo');

  const logoPath = currentOrglogo
    ? `${process.env.REACT_APP_BASE_URL}${currentOrglogo}`
    : '';

  return (
    <div className={classes.root}>
      <Drawer
        className={classes.drawer}
        variant='persistent'
        anchor='left'
        open={drawerOpen}
        classes={{ paper: classes.drawerPaper }}
      >
        <div className={classes.drawerHeader}>
          <img src={logoPath} alt='' width={250} />
        </div>
        <Divider />
        <SidebarItems />
      </Drawer>
    </div>
  );
};
export default Sidebar;
