import {
  FORM_EDIT_DATA,
  FORM_EDIT_DATA_SUCCESS,
  FORM_EDIT_DATA_FAIL,
  CLEAR_EDIT_FORM
} from 'actions/Hooks/editForm';

const initial = { loading: false, error: '', data: {} };

const formEditReducer = (state = initial, action: any) => {
  switch (action.type) {
    case FORM_EDIT_DATA:
      return { ...state, loading: true };
    case FORM_EDIT_DATA_SUCCESS:
      return { ...state, loading: false, data: action.payload.data };
    case FORM_EDIT_DATA_FAIL:
      return { ...state, loading: false, error: action.payload };
    case 'CLEAR_ERROR':
      return { ...state, loading: false, error: '' };
    case CLEAR_EDIT_FORM:
      return { ...state, data: {} };
    default:
      return state;
  }
};

export default formEditReducer;
