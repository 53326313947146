import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(
  ({ typography, palette: { success, grey } }) => ({
    status: { textTransform: 'capitalize' },
    acceptedIcon: {
      color: success.main,
      verticalAlign: 'middle',
      marginRight: 5,
      cursor: 'help'
    },
    quoteInfoBtns: { minWidth: 150 },
    tomatoClrBtn: { color: '#D9512B' },
    skyClrBtn: { color: '#2B86BA' },

    total: { color: grey[500], fontSize: 20, textAlign: 'right' },
    totalCount: {
      color: '#D9512B',
      fontSize: '1.5em',
      fontWeight: 600,
      marginLeft: 5
    },
    heading: {
      fontSize: typography.pxToRem(15),
      fontWeight: typography.fontWeightRegular
    },
    taxList: {
      flexDirection: 'column',
      padding: 10,
      borderTop: '1px solid #f5f5f5'
    }
  })
);
