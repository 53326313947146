export const GET_CONTACTS = 'GET_CONTACTS';
export const GET_CONTACTS_SUCCESS = 'GET_CONTACTS_SUCCESS';
export const GET_CONTACTS_FAIL = 'GET_CONTACTS_FAIL';
export const DELETE_CONTACT = 'DELETE_CONTACT';
export const DELETE_CONTACT_SUCCESS = 'DELETE_CONTACT_SUCCESS';
export const DELETE_CONTACT_FAIL = 'DELETE_CONTACT_FAIL';
export const CHANGE_CONTACTS_PAGER = 'CHANGE_CONTACTS_PAGER';
export const CHANGE_CONTACTS_PAGE = 'CHANGE_CONTACTS_PAGE';
export const GET_ALL_CONTACTS = 'GET_ALL_CONTACTS';
export const GET_ALL_CONTACTS_SUCCESS = 'GET_ALL_CONTACTS_SUCCESS';
export const GET_ALL_CONTACTS_FAIL = 'GET_ALL_CONTACTS_FAIL';
export const CHANGE_LIST_PAGE = 'CHANGE_LIST_PAGE';
export const ADD_EXISTING_CONTACT = 'ADD_EXISTING_CONTACT';
export const ADD_EXISTING_CONTACT_SUCCESS = 'ADD_EXISTING_CONTACT_SUCCESS';
export const ADD_EXISTING_CONTACT_FAIL = 'ADD_EXISTING_CONTACT_FAIL';
