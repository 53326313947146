import React, { useState, FormEvent } from 'react';
import {
  TextField,
  LinearProgress,
  Typography,
  Hidden
} from '@material-ui/core';
import LockIcon from '@material-ui/icons/Lock';

import Footer from './Footer';
import AuthFormsButton from './AuthFormsButton';
import CustomAlertMsg from 'app/Common/CustomAlertMsg';
import { useStyles } from '../styles';
import { LoginFormProps } from '../types';

const LoginForm = ({
  loginHandle,
  loading,
  error,
  clearMsgHandler
}: LoginFormProps) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const classes = useStyles();

  const submitHandle = (event: FormEvent) => {
    event.preventDefault();
    loginHandle({ email, password });
  };

  return (
    <div className={classes.container}>
      <Hidden mdUp>
        <Typography variant='h4' component='h2' gutterBottom align='center'>
          Next Level Technologies
        </Typography>
      </Hidden>
      <CustomAlertMsg
        type='error'
        message={error}
        onClose={() => clearMsgHandler()}
      />
      <form onSubmit={submitHandle} className={classes.form}>
        {loading && <LinearProgress />}
        <LockIcon
          color='primary'
          fontSize='large'
          className={`${classes.icon}  ${classes.loginIcon}`}
        />
        <TextField
          className={classes.fields}
          placeholder='E-mail Address'
          type='email'
          onChange={e => setEmail(e.target.value)}
          value={email}
          required
          autoFocus
          autoComplete='new-email'
          InputProps={{ className: classes.input }}
        />
        <TextField
          id='password'
          placeholder='Password'
          className={classes.fields}
          onChange={e => setPassword(e.target.value)}
          value={password}
          type='password'
          required
          InputProps={{ className: classes.input }}
          autoComplete='new-password'
        />
        <div className={classes.authFormBottom}>
          <AuthFormsButton loading={loading} text='Login' />
          <Footer />
        </div>
      </form>
    </div>
  );
};

export default React.memo(LoginForm);
