import { Box, TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import React, { useEffect, useState } from 'react';
import { AsyncSearchProps, KeyboardNChangeEvent } from './types';

const AsyncSearch = ({
  data,
  getHandle,
  selectHandle,
  loading,
  disabled = false,
  label = 'Select',
  keyword = 'name',
  multiple,
  filled,
  handleChange: changeHandler,
  defaultValue,
  clearFieldValue = false,
  renderOption
}: AsyncSearchProps) => {
  const [open, setOpen] = useState(false);
  const [search, setSearch] = useState('');
  const [isDefaultValue, setDefaultValue] = useState(!!defaultValue);
  useEffect(() => {
    if (open) getHandle(1, 30, search);
    // eslint-disable-next-line
  }, [open, search]);
  useEffect(() => {
    !open && setSearch('');
  }, [open, setSearch]);

  const onSelect = (
    e: KeyboardNChangeEvent,
    options: unknown,
    keyEvent?: boolean
  ) => {
    setDefaultValue(false);
    const allOptions = options as Array<{ [key: string]: string }>;
    const { value, innerText } = e.target;
    const selected = allOptions.find(
      option => (keyEvent ? value : innerText) === option[keyword]?.trim()
    );
    selected
      ? selectHandle(selected?.id?.toString(), selected)
      : selectHandle('');
  };
  const handleChange = (e: KeyboardNChangeEvent) => {
    setDefaultValue(false);
    changeHandler && changeHandler(e);
    (e.target.value.length > 2 || e.target.value.length === 0) &&
      setSearch(e.target.value);
  };
  const searchDefaultValue = data.find(item => item[keyword] === defaultValue);
  isDefaultValue && selectHandle(searchDefaultValue?.id.toString() ?? '');
  clearFieldValue && selectHandle('');
  return (
    <Autocomplete
      getOptionLabel={option => {
        return option[keyword] ? option[keyword] : '';
      }}
      key={clearFieldValue ? data.length : null}
      multiple={multiple}
      open={open}
      disabled={disabled}
      onFocus={() => setOpen(true)}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      onChange={e => onSelect(e, data)}
      defaultValue={defaultValue ? searchDefaultValue : null}
      onKeyUp={e => e.key === 'Enter' && onSelect(e, data, true)}
      // @ts-ignore
      options={data.length ? data : []}
      renderOption={
        renderOption
          ? option => (
              <Box fontWeight={Number(option.id) < 0 && 500} fontSize={15}>
                {option.name}
              </Box>
            )
          : undefined
      }
      loading={loading}
      autoHighlight={true}
      renderInput={params => (
        <TextField
          onChange={handleChange}
          {...params}
          label={label}
          fullWidth
        />
      )}
    />
  );
};

export default React.memo(AsyncSearch);
