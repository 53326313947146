import React from 'react';
import { Grid } from '@material-ui/core';

import { QuoteDetailsFooter } from '../../types';
import QuoteTotals from './QuoteTotals';
import Disclaimer from './Disclaimer';

const Footer = ({
  disclaimer,
  quoteId,
  total,
  subTotal,
  quoteShipping,
  taxCode,
  taxcodeId,
  reloadQuote,
  quoteTaxes
}: QuoteDetailsFooter) => (
  <Grid container justify='space-between' className='mt10 mb10'>
    <Disclaimer disclaimer={disclaimer} />
    <QuoteTotals
      quoteId={quoteId}
      total={total}
      subTotal={subTotal}
      quoteShipping={quoteShipping}
      taxCode={taxCode}
      taxcodeId={taxcodeId}
      reloadQuote={reloadQuote}
      quoteTaxes={quoteTaxes}
    />
  </Grid>
);

export default React.memo(Footer);
