export const GET_DISCLAIMERS = 'GET_DISCLAIMERS';
export const GET_DISCLAIMERS_SUCCESS = 'GET_DISCLAIMERS_SUCCESS';
export const GET_DISCLAIMERS_FAIL = 'GET_DISCLAIMERS_FAIL';
export const DELETE_DISCLAIMER = 'DELETE_DISCLAIMER';
export const DELETE_DISCLAIMER_SUCCESS = 'DELETE_DISCLAIMER_SUCCESS';
export const DELETE_DISCLAIMER_FAIL = 'DELETE_DISCLAIMER_FAIL';
export const CHANGE_DISCLAIMERS_PAGER = 'CHANGE_DISCLAIMERS_PAGER';
export const CHANGE_DISCLAIMERS_PAGE = 'CHANGE_DISCLAIMERS_PAGE';
export const CHANGE_DISCLAIMER = 'CHANGE_DISCLAIMER';
export const CHANGE_DISCLAIMER_SUCCESS = 'CHANGE_DISCLAIMER_SUCCESS';
export const CHANGE_DISCLAIMER_FAIL = 'CHANGE_DISCLAIMER_FAIL';
export const GET_DISCLAIMER = 'GET_DISCLAIMER';
export const GET_DISCLAIMER_SUCCESS = 'GET_DISCLAIMER_SUCCESS';
export const GET_DISCLAIMER_FAIL = 'GET_DISCLAIMER_FAIL';
export const CLEAR_DISCLAIMER = 'CLEAR_DISCLAIMER';
