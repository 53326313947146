import React from 'react';
import { Typography, Button } from '@material-ui/core';
import { useDispatch } from 'react-redux';

import { logout } from 'actions';
import { useStyles } from './styles';

const SalesLogin = () => {
  const dispatch = useDispatch();
  const classes = useStyles();

  return (
    <div>
      <Typography variant='h6' align='center'>
        please contact your Admin to get access.
      </Typography>
      <Button className={classes.logout} onClick={() => dispatch(logout())}>
        Logout
      </Button>
    </div>
  );
};
export default SalesLogin;
