import { FORM_EDIT_DATA } from 'actions';
import { apiService } from 'middleware/api';
import {
  CHANGE_EXPENSE_CATEGORIES_PAGE,
  CHANGE_EXPENSE_CATEGORIES_PAGER,
  DELETE_EXPENSE_CATEGORY,
  GET_EXPENSE_CATEGORIES
} from '../constants';

export const onGetExpenseCategories = (
  page = 1,
  perPage = 10,
  keyword = ''
) => {
  const endPoint = `expense_categories.json?page=${page}&per_page=${perPage}&keyword=${keyword}`;
  const method = 'get';
  return apiService(endPoint, method, GET_EXPENSE_CATEGORIES);
};

export const onChangeCategoriesPerPage = (perPage: number) => ({
  type: CHANGE_EXPENSE_CATEGORIES_PAGER,
  payload: perPage
});

export const onChangeCategoriesPage = (page: number) => ({
  type: CHANGE_EXPENSE_CATEGORIES_PAGE,
  payload: page
});

export const onGetExpenseCategory = (key: string) => {
  const endPoint = `expense_categories/${key}.json`;
  const method = 'get';
  return apiService(endPoint, method, FORM_EDIT_DATA);
};

export const onDeleteExpenseCategory = (key: string) => {
  const endPoint = `expense_categories/${key}.json`;
  const method = 'delete';
  return apiService(endPoint, method, DELETE_EXPENSE_CATEGORY);
};
