import React, { useEffect, useRef, useState } from 'react';
import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
  Select,
  MenuItem,
  Grid,
  makeStyles,
  Paper,
  Typography,
  CircularProgress,
  Button,
  Checkbox,
  Modal,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import sign from 'jwt-encode';

import { paramsToObject } from 'utils/common/paramsToObject';
import { onGetCyberSecurityQuestions, onSubmitForm } from '../redux/actions';
import { CyberSecurityFormProps } from '../types';
import { DynamicObject } from 'app/Common/types';
import RecaptchaField from 'app/Common/RecaptchaFormField';
import CustomAlertMsg from 'app/Common/CustomAlertMsg';

const ExternalCyberSecurityForm = () => {
  const { search } = useLocation();
  const headers = paramsToObject(search);
  const [token, setToken] = useState('');

  const dispatch = useDispatch();

  useEffect(() => {
    const SECRET_KEY = `${process.env.REACT_APP_JWT_SECRET_KEY}`;
    const createdToken = sign(headers, SECRET_KEY, { alg: 'HS256' });
    setToken(createdToken);
    dispatch(onGetCyberSecurityQuestions(createdToken));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  const { data, loading, createLoading, message } = useSelector(
    ({ cyberSecurity }: CyberSecurityFormProps) => cyberSecurity
  );

  const classes = useStyles();

  const [popoverOpen, setPopoverOpen] = useState(false);
  const [businessName, setBusinessName] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [sendPdfReport, setSendPdfReport] = useState(false);
  const [answers, setAnswers] = useState<AnswersType>({});
  const [isFormValid, setIsFormValid] = useState(false);
  const [errors, setErrors] = useState('');
  const [recaptchaValue, setRecaptchaValue] = useState('');
  const [emailError, setEmailError] = useState<string | null>(null);
  const [nextButtonError, setNextButtonError] = useState<string | null>(null);
  const [hasAttemptedSubmit, setHasAttemptedSubmit] = useState(false);
  const [unansweredQuestions, setUnansweredQuestions] = useState<string[]>([]);
  const [, setShowChildren] = useState<{ [name: string]: any }>();

  const anchorRef = useRef(null);

  const handlePopoverOpen = () => {
    setHasAttemptedSubmit(true);

    if (validateAnswers()) {
      setNextButtonError(null);
      setPopoverOpen(true);
    } else {
      setNextButtonError('Please fill out all the answers before proceeding.');
    }
  };
  const handlePopoverClose = () => {
    setPopoverOpen(false);
    setRecaptchaValue('');
  };

  const handleAnswerChange: HandleAnswerChangeType = (id, answer) => {
    if (Array.isArray(answer)) {
      answer = answer.filter(val => val !== undefined);
    }

    const parentQuestion = data.find(item => item.id === parseInt(id, 10));

    if (parentQuestion && parentQuestion.childQuestions) {
      for (const childId of parentQuestion.childQuestions) {
        if (answer === 'yes') {
          setShowChildren({ [childId]: true });
          if (!answers[childId]) {
            setUnansweredQuestions(prev => [...prev, childId]);
          }
        } else if (answer === 'no') {
          setShowChildren({ [childId]: false });
          if (answers[childId]) {
            setAnswers(prevState => ({
              ...prevState,
              [childId]: ''
            }));
            setUnansweredQuestions(prev => prev.filter(qId => qId !== childId));
          }
        }
      }
    }

    if (Array.isArray(answer) && !answer.length) {
      const newState = { ...answers };
      delete newState[id];
      setAnswers(newState);
    } else {
      setAnswers(prevState => ({
        ...prevState,
        [id]: answer
      }));
      const updated = unansweredQuestions.filter(v => {
        return v !== id;
      });

      setTimeout(() => setUnansweredQuestions(updated), 0);
    }

    if (hasAttemptedSubmit && validateAnswers()) {
      setNextButtonError(null);
    }
  };

  const validateForm = () => {
    let allQuestionsAnswered = true;

    for (let i = 0; i < data?.length; i++) {
      const item = data[i];

      if (item.parent_id && answers[String(item.parent_id)] === 'no') {
        continue;
      }

      if (!answers[String(item.id)]) {
        allQuestionsAnswered = false;
        break;
      }
    }

    const areAllFieldsFilled =
      !!businessName &&
      !!firstName &&
      !!lastName &&
      !!email &&
      !!phoneNumber &&
      !emailError;
    const areRecaptchaValidated = !!recaptchaValue;

    const formIsValid =
      allQuestionsAnswered && areAllFieldsFilled && areRecaptchaValidated;
    setIsFormValid(formIsValid);

    if (!formIsValid) {
      setNextButtonError('Please fill out all the answers before proceeding.');
    } else {
      setNextButtonError(null);
    }
  };

  const validateAnswers = (): boolean => {
    const unanswered: string[] = [];

    for (let i = 0; i < data.length; i++) {
      const item = data[i];

      if (item.parent_id && answers[String(item.parent_id)] !== 'yes') {
        continue;
      }

      if (item?.question_type === 'checkbox' && item.select_multiple) {
        if (!answers[String(item.id)] || !answers[String(item.id)].length) {
          unanswered.push(String(item.id));
        }
      } else if (!answers[String(item.id)]) {
        unanswered.push(String(item.id));
      }
    }

    setUnansweredQuestions(unanswered);
    return unanswered.length === 0;
  };

  useEffect(() => {
    validateForm();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    answers,
    businessName,
    firstName,
    lastName,
    email,
    phoneNumber,
    recaptchaValue
  ]);

  const validateEmail = (email: string) => {
    let emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    return emailRegex.test(email);
  };

  const handleSubmit = (): void => {
    const dataToSend = {
      business_name: businessName,
      first_name: firstName,
      last_name: lastName,
      email,
      phone_number: phoneNumber,
      send_pdf_report: sendPdfReport,
      'g-recaptcha-response': recaptchaValue,
      cyber_security_answers_attributes: Object.keys(answers)
        .filter(questionId => {
          const question = data.find(item => String(item.id) === questionId);
          if (
            question?.parent_id &&
            answers[String(question.parent_id)] === 'no'
          ) {
            return false;
          }
          return true;
        })
        .flatMap(questionId => {
          const question = data.find(item => String(item.id) === questionId);

          if (question?.question_type === 'text_area') {
            return {
              cyber_security_question_id: parseInt(questionId, 10),
              answer: answers[questionId] as string
            };
          } else if (question?.select_multiple) {
            const answerKeys =
              typeof answers[questionId] === 'string'
                ? (answers[questionId] as string).split(',')
                : (answers[questionId] as string[]);
            return answerKeys.map((key: string) => {
              return {
                cyber_security_question_id: parseInt(questionId, 10),
                answer: question?.question_options[key]
              };
            });
          } else {
            const selectedAnswer = Array.isArray(answers[questionId])
              ? answers[questionId][0]
              : answers[questionId];
            const selectedOption =
              question?.question_options[selectedAnswer as string];
            if (questionId === '-1') {
              let result = data.find(
                item =>
                  item.slug === 'do-you-use-a-password-manager-if-so-which-one'
              );
              return {
                cyber_security_question_id: parseInt(String(result?.id), 10),
                answer: selectedAnswer
              };
            } else {
              return {
                cyber_security_question_id: parseInt(questionId, 10),
                answer: selectedOption
              };
            }
          }
        })
    };

    dispatch(onSubmitForm(dataToSend, token));
  };
  const closeAlertHandle = () => {
    window.location.href = 'https://nltsf.com';
  };

  const [textFieldErrors, setTextFieldErrors] = useState<DynamicObject>(
    {} as DynamicObject
  );

  const handleTextChange = (id: string, value: string) => {
    const trimmedValue = value.trim();
    const question = data.find(item => item.id === parseInt(id, 10));
    if (question && question.parent_id) {
      const parentAnswer = answers[String(question.parent_id)];
      if (parentAnswer !== 'yes') {
        return;
      }
    }
    if (!trimmedValue.length) {
      setTextFieldErrors(prev => ({
        ...prev,
        [id]: 'This field cannot be only spaces.'
      }));
    } else {
      setTextFieldErrors(prev => {
        const newState = { ...prev };
        delete newState[id];
        return newState;
      });
    }
    handleAnswerChange(id, value);
  };

  const [dropdownStates, setDropdownStates] = useState<DropdownStates>({});

  const handleSelectOpen = (id: number) => {
    setDropdownStates(prev => ({ ...prev, [id]: true }));
  };

  const handleSelectClose = (id: number) => {
    setDropdownStates(prev => ({ ...prev, [id]: false }));
  };

  const questionNumberRef = useRef(0);

  function renderQuestions() {
    questionNumberRef.current = 0;
    return data?.map((item: DynamicObject) => {
      const parentAnsweredYes =
        !item.parent_id || answers[String(item.parent_id)] === 'yes';

      questionNumberRef.current++;
      return (
        <Grid container key={item?.id} style={{ marginBottom: '20px' }}>
          <h3>
            <span
              className={
                unansweredQuestions.includes(String(item?.id))
                  ? `${classes.highlightQuestionText}`
                  : parentAnsweredYes
                  ? ''
                  : classes.disabledQuestionText
              }
            >
              {questionNumberRef.current}. {item?.question}
            </span>
          </h3>

          {item?.question_type === 'checkbox' && (
            <FormControl fullWidth>
              <Select
                multiple={item.select_multiple}
                disabled={!parentAnsweredYes}
                displayEmpty
                value={
                  answers[String(item?.id)] || (item.select_multiple ? [] : '')
                }
                required
                MenuProps={{
                  anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'left'
                  },
                  transformOrigin: {
                    vertical: 'top',
                    horizontal: 'left'
                  },
                  getContentAnchorEl: null
                }}
                open={dropdownStates[item.id] || false}
                onOpen={() => handleSelectOpen(item.id)}
                onClose={() => handleSelectClose(item.id)}
                onChange={e => {
                  handleAnswerChange(
                    String(item?.id),
                    e.target.value as string[]
                  );
                }}
              >
                {item.select_multiple === true && (
                  <div className={classes.closeIcon}>
                    <Button
                      size='small'
                      className={classes.doneButton}
                      onClick={() => handleSelectClose(item.id)}
                    >
                      Done
                    </Button>
                  </div>
                )}
                <MenuItem disabled value=''>
                  <em>
                    {' '}
                    {item.select_multiple
                      ? ' Select Multiple '
                      : 'Select Options'}
                  </em>
                </MenuItem>
                {Object.keys(item?.question_options).map(key => (
                  <MenuItem key={key} value={key}>
                    {item?.question_options[key]}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}

          {item?.question_type === 'radio' && (
            <FormControl component='fieldset' fullWidth>
              <RadioGroup
                aria-label='options'
                onChange={e =>
                  handleAnswerChange(String(item?.id), e.target.value)
                }
              >
                {Object.keys(item?.question_options).map(key => (
                  <FormControlLabel
                    key={key}
                    value={key}
                    control={<Radio />}
                    label={item?.question_options[key]}
                  />
                ))}
              </RadioGroup>
            </FormControl>
          )}
          {item?.question_type === 'text_area' && (
            <TextField
              disabled={!parentAnsweredYes}
              multiline
              rows={2}
              variant='outlined'
              fullWidth
              required
              value={answers[String(item?.id)] || ''}
              error={!!textFieldErrors[String(item?.id)]}
              helperText={textFieldErrors[String(item?.id)]}
              onChange={e => handleTextChange(String(item?.id), e.target.value)}
            />
          )}
          {item.slug === 'do-you-use-a-password-manager-if-so-which-one' && (
            <>
              <TextField
                key={item.id}
                multiline
                rows={2}
                variant='outlined'
                fullWidth
                required
                value={answers[String(-1)] || ''}
                error={!!textFieldErrors[String(item.id)]}
                helperText={textFieldErrors[String(item.id)]}
                onChange={e => handleTextChange(String(-1), e.target.value)}
              />
            </>
          )}
        </Grid>
      );
    });
  }

  const [open, setOpen] = useState(false);
  const [acceptTerms, setAcceptTerms] = useState(false);

  const handleOpenDialog = (event: any) => {
    event.preventDefault();
    setOpen(true);
  };

  const handleCloseDialog = () => {
    setOpen(false);
  };

  const handleAgree = () => {
    setAcceptTerms(true);
    setOpen(false);
  };

  const handleCancel = () => {
    setOpen(false);
    setAcceptTerms(false);
  };

  const handleCheckboxChange = (event: any) => {
    event.preventDefault();
    setAcceptTerms(event.target.checked);
  };
  return (
    <>
      <CustomAlertMsg
        message={message}
        type={message.length ? 'success' : 'error'}
        onClose={closeAlertHandle}
        minWidth='150px'
      />
      <div className={classes.container}>
        <Grid container justify='center'>
          <Grid item xs={12} sm={8} md={6}>
            <Paper className={classes.paper}>
              <Typography className={classes.header}>
                Cyber Security Form
              </Typography>
              <Typography className={classes.subHeader} color='error'>
                Please ensure all questions in the questionnaire are filled out
                to submit the form.
              </Typography>
              {loading ? (
                <div className={classes.progress}>
                  <CircularProgress />
                </div>
              ) : (
                renderQuestions()
              )}
              <div className={classes.authBtn}>
                {hasAttemptedSubmit && nextButtonError && (
                  <Typography color='error' className={classes.errorText}>
                    {nextButtonError}
                  </Typography>
                )}
                {data.length > 1 && (
                  <Button
                    ref={anchorRef}
                    type='submit'
                    color='primary'
                    variant='contained'
                    onClick={handlePopoverOpen}
                    disabled={loading}
                  >
                    Next
                  </Button>
                )}
              </div>
            </Paper>
          </Grid>
        </Grid>
      </div>
      <Modal
        open={popoverOpen}
        onClose={handlePopoverClose}
        aria-labelledby='business-modal-title'
        aria-describedby='business-modal-description'
      >
        <div className={classes.modalContent}>
          <Typography variant='h6' id='business-modal-title'>
            Business Details
          </Typography>
          <label>Business Name *</label>
          <TextField
            className={classes.modalField}
            variant='outlined'
            fullWidth
            value={businessName}
            onChange={e => setBusinessName(e.target.value)}
          />
          <label>Business Phone Number *</label>
          <TextField
            className={classes.modalField}
            variant='outlined'
            fullWidth
            type='text'
            value={phoneNumber}
            onChange={e => {
              const val = e.target.value;
              if (/^(\d{1,12}|)$/.test(val)) {
                setPhoneNumber(val);
              }
            }}
          />
          <label>First Name *</label>
          <TextField
            className={classes.modalField}
            variant='outlined'
            fullWidth
            value={firstName}
            onChange={e => setFirstName(e.target.value)}
          />
          <label>Last Name *</label>
          <TextField
            className={classes.modalField}
            variant='outlined'
            fullWidth
            value={lastName}
            onChange={e => setLastName(e.target.value)}
          />
          <label>Email *</label>
          <TextField
            className={classes.modalField}
            variant='outlined'
            fullWidth
            value={email}
            error={Boolean(emailError)}
            helperText={emailError}
            onChange={e => {
              setEmail(e.target.value);
              if (!validateEmail(e.target.value)) {
                setEmailError('Invalid email format');
              } else {
                setEmailError(null);
              }
            }}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={sendPdfReport}
                onChange={() => setSendPdfReport(prevState => !prevState)}
              />
            }
            label='Would you like to receive result via email?'
          />
          <FormControlLabel
            control={
              <Checkbox checked={acceptTerms} onChange={handleCheckboxChange} />
            }
            label='Do you accept the Terms and Conditions?'
            onClick={handleOpenDialog}
          />
          <Dialog open={open} onClose={handleCloseDialog}>
            <DialogContent>
              <DialogContentText>
                Next Level Technology will be performing a network vulnerability
                and penetration test. No credentials will be used or shared
                during this test. This will not impact on the network, outside
                of an increase in traffic. This is simply a test that simulates
                an attack from both inside and outside the network. Next Level
                Technology will run a script of approximately 160,000 known
                vulnerabilities. No changes or updates are being done. Any
                downtime incurred is strictly coincidental. Next Level
                Technology is not liable for any downtime, nor responsible for
                fixing any downtime during said scan. Results of the scan will
                be shared, and the business (or person agreeing not sure on
                verbiage here) is not under any obligation to take services. By
                clicking and accepting this acknowledgement, Client agrees Next
                Level Technology is performing this service subject to all the
                terms and conditions in the Master Services Agreement between
                Client and Next Level Technology.
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleAgree} color='primary'>
                Agree
              </Button>
              <Button onClick={handleCancel} color='primary'>
                Cancel
              </Button>
            </DialogActions>
          </Dialog>

          <div className={classes.reCaptcha}>
            <RecaptchaField
              name='g-recaptcha-response'
              label='reCAPTCHA'
              rules={[
                {
                  required: true,
                  message: 'Please check recaptcha'
                }
              ]}
              error={errors}
              setError={setErrors}
              onChange={(value: string) => setRecaptchaValue(value)}
            />
          </div>
          <Button
            type='submit'
            color='primary'
            variant='contained'
            onClick={handleSubmit}
            disabled={!isFormValid || createLoading || !acceptTerms}
          >
            Submit
          </Button>
        </div>
      </Modal>
    </>
  );
};

export default ExternalCyberSecurityForm;

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1
  },
  paper: {
    padding: theme.spacing(3),
    margin: 'auto',
    maxWidth: 900,
    boxShadow: '0px 0px 15px rgba(0, 0, 0, 0.2)',
    border: '1px solid #ddd',
    marginTop: 20,
    marginBottom: 20,
    minHeight: '100vh'
  },
  container: {
    backgroundColor: theme.palette.primary.main,
    minHeight: '100vh'
  },
  header: {
    display: 'flex',
    justifyContent: 'center',
    fontSize: '30px',
    fontWeight: 'bold'
  },
  progress: {
    display: 'flex',
    justifyContent: 'center',
    paddingY: 20,
    position: 'absolute',
    top: '50%',
    left: '50%'
  },
  authBtn: {
    display: 'flex',
    justifyContent: 'end'
  },
  modalContent: {
    position: 'absolute',
    width: '80%',
    maxWidth: 450,
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(4),
    outline: 'none',
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2)
  },
  modalField: {
    margin: theme.spacing(1, 0)
  },
  subHeader: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: theme.spacing(2),
    fontStyle: 'italic'
  },
  highlightQuestionText: {
    color: '#f44336',
    display: 'inline',
    padding: '2px 4px',
    borderRadius: '2px'
  },
  reCaptcha: {
    [theme.breakpoints.down('sm')]: {
      transform: 'scale(0.77)',
      transformOrigin: '0 0'
    }
  },
  errorText: {
    display: 'flex',
    alignItems: 'center'
  },
  disabledQuestionText: {
    color: '#A9A9A9'
  },
  selectButton: {
    display: 'flex',
    justifyContent: 'end'
  },
  selectField: {
    border: '1px solid black'
  },
  closeIcon: {
    display: 'flex',
    justifyContent: 'end'
  },
  doneButton: {
    color: 'blue'
  }
}));

type AnswersType = {
  [questionId: string]: string | string[];
};

type HandleAnswerChangeType = (id: string, answer: string | string[]) => void;

interface DropdownStates {
  [key: number]: boolean;
}
