import { DynamicObject } from 'app/Common/types';
import { Data, UserData } from 'types';

export const organizationChangeEvent = (
  organization: DynamicObject,
  user: UserData,
  data: Data
) => {
  localStorage.setItem(
    'token',
    JSON.stringify({ ...data, 'Organization-Token': organization.auth_token })
  );
  localStorage.setItem(
    'user',
    JSON.stringify({ ...user, current_tenant: organization })
  );
  window.location.href = window.location.origin;
};
