import React, { ReactElement } from 'react';
import { Link } from 'react-router-dom';
import { Breadcrumbs, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const AppBreadCrumbs = ({
  paths,
  current,
  loading,
  maxItems = 8
}: AppBreadCrumbsProps): ReactElement | null => {
  const classes = useStyles();

  return loading ? null : (
    <Breadcrumbs className={classes.breadcrumb} maxItems={maxItems}>
      {paths.map(({ name, link }: Paths) => (
        <Link to={link} className={classes.link} key={link}>
          {name}
        </Link>
      ))}
      <Typography color='textPrimary'>{current}</Typography>
    </Breadcrumbs>
  );
};

const useStyles = makeStyles(({ palette: { grey } }) => ({
  breadcrumb: { marginLeft: 8 },
  link: { color: grey[600], textDecoration: 'none' }
}));

type Paths = {
  name: string;
  link: string;
};
interface AppBreadCrumbsProps {
  paths: Array<Paths>;
  current: string;
  loading: boolean;
  maxItems?: number;
}

export default React.memo(AppBreadCrumbs);
