import {
  GET_PLANS,
  GET_PLANS_SUCCESS,
  GET_PLANS_FAIL,
  GET_PLAN,
  GET_PLAN_SUCCESS,
  GET_PLAN_FAIL
} from '../constants';

const initial = {
  data: [],
  loading: false,
  error: '',
  planData: [],
  planLoading: false,
  planError: ''
};

const plans = (state = initial, action: { type: string; payload: any }) => {
  switch (action.type) {
    case GET_PLANS:
      return { ...state, loading: true };
    case GET_PLANS_SUCCESS:
      return {
        ...state,
        data: action.payload.data,
        count: action.payload.meta_attributes
          ? action.payload.meta_attributes.total_count
          : 1,
        loading: false
      };
    case GET_PLANS_FAIL:
      return { ...state, error: action.payload, loading: false };
    case GET_PLAN:
      return { ...state, planLoading: true };
    case GET_PLAN_SUCCESS:
      return {
        ...state,
        planData: action.payload.data,
        planLoading: false
      };
    case GET_PLAN_FAIL:
      return { ...state, planError: action.payload, planLoading: false };
    case 'CLEAR_ERROR':
      return { ...state, error: '', message: '' };
    default:
      return state;
  }
};

export default plans;
