export const GET_INVOICES = 'GET_INVOICES';
export const GET_INVOICES_SUCCESS = 'GET_INVOICES_SUCCESS';
export const GET_INVOICES_FAIL = 'GET_INVOICES_FAIL';

export const GET_INVOICE = 'GET_INVOICE';
export const GET_INVOICE_SUCCESS = 'GET_INVOICE_SUCCESS';
export const GET_INVOICE_FAIL = 'GET_INVOICE_FAIL';

export const CHANGE_INVOICE_PAGE = 'CHANGE_INVOICE_PAGE';
export const CHANGE_INVOICE_PAGER = 'CHANGE_INVOICE_PAGER';
