import { Box } from '@material-ui/core';
import ListDeleteButton from 'app/Common/ListDeleteButton';
import { DynamicObject } from 'app/Common/types';
import { onChangeStatus } from 'app/Dashboard/Setup/TaskStatus/redux/actions';
import parse from 'html-react-parser';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import TaskBoard from 'react-trello';

type taskStatusTypes = 'todo' | 'in_progress' | 'done';
interface BoardMainProps {
  onCardDetail: (cardId: number) => void;
  collapsibleLanes?: Boolean;
  showBoardTitles?: Boolean;
  data: DynamicObject[];
  onUpdateStatus: (task: { id: number; task_status_id: number }) => void;
  onDeleteStatus: (id: string) => void;

  statusList: Array<Record<any, any>>;
}

const Board = ({
  onCardDetail,
  data,
  onUpdateStatus,
  collapsibleLanes,
  showBoardTitles = true,
  statusList,
  onDeleteStatus
}: BoardMainProps) => {
  const boardBackgroundColor = {
    backgroundColor: '#fff',
    height: 'fit-content'
  };
  const [prevTasks, setPrevTasks] = useState<DynamicObject | {}>({});
  const dispatch = useDispatch();

  const onDragEnd = (
    cardId: number,
    sourceLaneId: taskStatusTypes,
    targetLaneId: taskStatusTypes
  ) => {
    if (sourceLaneId === targetLaneId) return;
    onUpdateStatus({ id: cardId, task_status_id: parseInt(targetLaneId) });
  };

  const boardViewItems = (data: DynamicObject[]) => {
    const updatedData = data.map(row => {
      row.description = row.description ? (
        React.isValidElement(row.description) ? (
          <p>{row.description}</p>
        ) : (
          <p>
            {parse(
              row.description?.length > 80
                ? row.description.replace(/<\/?[^>]+(>|$)/g, '').slice(0, 70)
                : row.description
            )}
          </p>
        )
      ) : (
        ''
      );

      row.title =
        row.title?.length > 30
          ? row.title.replace(/<\/?[^>]+(>|$)/g, '').slice(0, 30) + '...'
          : row.title;

      return row;
    });

    const lanes = statusList.map((status: Record<any, any>) => {
      return {
        id: status.id,
        title: showBoardTitles ? status.name : '',
        label: showBoardTitles ? (
          <Box width={'5%'}>
            <ListDeleteButton
              size='small'
              loading={!status.name}
              onClickHandler={() => onDeleteStatus(status.id.toString())}
            />
          </Box>
        ) : (
          ''
        ),
        cards: updatedData.filter(card => card.task_status_id === status.id)
      };
    });

    return { lanes };
  };

  return (
    <TaskBoard
      hideCardDeleteIcon
      style={boardBackgroundColor}
      handleDragEnd={onDragEnd}
      data={Object.keys(prevTasks).length ? prevTasks : boardViewItems(data)}
      onCardClick={onCardDetail}
      onDataChange={(v: DynamicObject) => setPrevTasks(v)}
      collapsibleLanes={collapsibleLanes}
      editLaneTitle={showBoardTitles ? true : false}
      onLaneUpdate={(laneId: any, data: any) => {
        const payload = { name: data.title };
        //@ts-ignore
        dispatch(onChangeStatus(laneId, payload));
      }}
    />
  );
};
export default React.memo(Board);
