import { Button, Divider, Popover, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, { useState } from 'react';
import { MouseNChangeEvent } from './types';

const FieldChangeButton = ({
  loading,
  data,
  onChange,
  list,
  title = '',
  field,
  viewOnly,
  disabled,
  backgroundColor,
  isCompany = false,
  isRole = false,
  isContactType = false,
  isQuoteStatus = false,
  isTaskStatus = false,
  isApproval = false
}: FieldChangeButtonProps) => {
  const classes = useStyles({ loading });
  const [anchorEl, setAnchorEl] = useState(null);

  const anchorElToggle = (element = null) => {
    setAnchorEl(element);
  };

  const confirmHandler = (statusId: number, name: string) => {
    if (!onChange) return;
    anchorElToggle(null);
    const updated = { ...data };
    if (updated.task_status_id) {
      updated.task_status_id = statusId;
    } else {
      updated.status = name.toLowerCase();
    }
    onChange(updated);
  };

  const statusClickHandler = (event: MouseNChangeEvent) => {
    event.stopPropagation();
    if (viewOnly) return null;
    setAnchorEl(event.currentTarget);
  };
  const name = isRole
    ? data.role
    : isCompany
    ? data.status
    : isContactType
    ? data.contact_type
    : isQuoteStatus
    ? data.status
    : isApproval
    ? data.status
    : data.task_status?.name;

  const isDisabled = (value: string, name: string) => {
    if (isCompany) {
      return value === data.status;
    }
    if (isRole) {
      return value === data.role;
    }
    if (isQuoteStatus) {
      return value === data.status;
    }
    if (isTaskStatus) {
      return name === data.task_status.name;
    }
    if (isApproval) {
      return value === 'pending';
    }
    return false;
  };
  return (
    <>
      <Button
        variant='contained'
        onClick={statusClickHandler}
        className={classes.statusBtn}
        disabled={loading || disabled}
        style={{
          background: loading || disabled ? '' : backgroundColor ?? '#a06e0f'
        }}
      >
        {name?.replace('_', ' ')}
      </Button>
      <Popover
        className={classes.pop}
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={() => anchorElToggle(null)}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <>
          <Typography
            className={classes.typography}
            variant='h3'
            component='h3'
          >
            {title}
          </Typography>
          <Divider className='mb10' />
          <div className={classes.buttons}>
            {list.map(({ id, name, color, value }, index) => {
              return (
                <Button
                  key={index}
                  disabled={isDisabled(value, name)}
                  onClick={() => confirmHandler(id, value)}
                  style={{
                    background:
                      name === data.task_status?.name ? '#b9b9b9' : color
                  }}
                  className={classes.statusBtn}
                >
                  {name}
                </Button>
              );
            })}
          </div>
        </>
      </Popover>
    </>
  );
};

const useStyles = makeStyles(theme => ({
  statusBtn: {
    color: '#fff',
    textTransform: 'capitalize',
    padding: 5,
    width: 86,
    lineHeight: 1,
    background: '#a06e0f',
    cursor: ({ loading }: { loading: boolean }) =>
      loading ? 'not-allowed' : 'pointer'
  },
  typography: { padding: '10px 20px', fontSize: 18, fontWeight: 500 },
  pop: { textAlign: 'center' },
  buttons: {
    marginLeft: 5,
    display: 'flex',
    maxWidth: 350,
    flexWrap: 'wrap',
    justifyContent: 'center',
    paddingBottom: 5,
    '& button': { marginBottom: 5, marginRight: 5 }
  }
}));

export default FieldChangeButton;

interface Data {
  [name: string]: any;
}
interface FieldChangeButtonProps {
  data: Data;
  loading: boolean;
  list: Array<Record<any, any>>;
  onChange?: (data: Data) => void;
  title?: string;
  field: string;
  viewOnly?: boolean;
  disabled?: boolean;
  backgroundColor?: string | null;
  isCompany?: boolean;
  isRole?: boolean;
  isContactType?: boolean;
  isQuoteStatus?: boolean;
  isTaskStatus?: boolean;
  isApproval?: boolean;
}
