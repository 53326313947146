import {
  CHANGE_EXPENSES_PAGE,
  CHANGE_EXPENSES_PAGER,
  DELETE_EXPENSE,
  DELETE_EXPENSE_FAIL,
  DELETE_EXPENSE_SUCCESS,
  GET_EXPENSES,
  GET_EXPENSES_FAIL,
  GET_EXPENSES_SUCCESS
} from '../constants';

const initial = {
  data: [],
  loading: false,
  error: '',
  message: '',
  page: 1,
  perPage: 10,
  count: 1,
  task: {}
};

const expenses = (state = initial, action: { type: string; payload: any }) => {
  switch (action.type) {
    case GET_EXPENSES:
      return { ...state, loading: true };
    case GET_EXPENSES_SUCCESS:
      return {
        ...state,
        data: action.payload.data,
        count: action.payload.meta_attributes
          ? action.payload.meta_attributes.total_count
          : 1,
        loading: false
      };
    case GET_EXPENSES_FAIL:
      return { ...state, error: action.payload, loading: false };
    case 'CLEAR_ERROR':
      return { ...state, error: '', message: '' };
    case CHANGE_EXPENSES_PAGER:
      return { ...state, perPage: action.payload };
    case CHANGE_EXPENSES_PAGE:
      return { ...state, page: action.payload };
    case DELETE_EXPENSE:
      return { ...state, loading: true };
    case DELETE_EXPENSE_SUCCESS:
      return { ...state, message: action.payload.message, loading: false };
    case DELETE_EXPENSE_FAIL:
      return { ...state, error: action.payload, loading: false };
    default:
      return state;
  }
};

export default expenses;
