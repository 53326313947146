import React from 'react';
import { Link } from 'react-router-dom';
import { Divider } from '@material-ui/core';

import { useStyles } from '../styles';

const Footer = ({ formType }: { formType?: 'forget' | 'signup' }) => {
  const classes = useStyles();
  if (formType === 'forget')
    return (
      <div className={classes.footer}>
        <Link to='/'>Go to Login</Link>
      </div>
    );
  if (formType === 'signup')
    return (
      <div className={classes.footer}>
        <Link to='/'>Already have an account?</Link>
      </div>
    );
  return (
    <>
      <Divider />
      <div className={classes.footer}>
        <Link to='/signup' className='mr5'>
          Create new Account
        </Link>
        <span className={classes.verticalDivider}>|</span>
        <Link to='/forget-password' className='ml5'>
          Forget Password?
        </Link>
      </div>
    </>
  );
};

export default React.memo(Footer);
