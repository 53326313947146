import {
  GET_NOTIFICATIONS,
  GET_READ_NOTIFICAFTIONS,
  CHANGE_NOTIFICATIONS_PAGER,
  CHANGE_NOTIFICATIONS_PAGE
} from '../constants';

import { apiService } from 'middleware/api';

export const onGetNotifications = (page = 1, perPage = 10, keyword = '') => {
  const endPoint = `notifications.json?page=${page}&per_page=${perPage}&keyword=${keyword}`;
  const method = 'get';
  return apiService(endPoint, method, GET_NOTIFICATIONS);
};

export const onGetReadNotifications = () => {
  const endPoint = `notifications/mark_all_as_read.json`;
  const method = 'get';
  return apiService(endPoint, method, GET_READ_NOTIFICAFTIONS);
};

export const onChangeNotificationsPerPage = (perPage: number) => ({
  type: CHANGE_NOTIFICATIONS_PAGER,
  payload: perPage
});

export const onChangeNotificationsPage = (page: number) => ({
  type: CHANGE_NOTIFICATIONS_PAGE,
  payload: page
});
