import { format, utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz';

import { store } from 'store';

export const dateHH_MMFormat = (
  time: Date | string | null,
  is24Hours?: boolean
) => {
  const timeZone = store.getState()?.auth?.user?.time_zone;
  const tzDate = utcToZonedTime(time ?? new Date(), timeZone);
  return is24Hours
    ? format(tzDate, 'HH:mm', { timeZone })
    : format(tzDate, 'hh:mm a', { timeZone });
};

export const dateYMDFormat = (time: Date | string | null, divider = '-') => {
  const timeZone = store.getState()?.auth?.user?.time_zone;
  const tzDate = utcToZonedTime(time ?? new Date(), timeZone);
  return format(tzDate, `yyyy${divider}MM${divider}dd`, { timeZone });
};

export const dateYMDHMFormat = (time: Date | string | null, divider = '-') => {
  const timeZone = store.getState()?.auth?.user?.time_zone;
  const tzDate = utcToZonedTime(time ?? new Date(), timeZone);
  return format(tzDate, `yyyy${divider}MM${divider}dd HH:mm`, { timeZone });
};

export const dateYMDFormatWithTimeZone = (
  time: Date | string | null,
  divider = '-'
) => {
  if (typeof time === 'string' && !time.length) return '';
  const timeZone = store.getState()?.auth?.user?.time_zone;
  const tzDate = utcToZonedTime(time ?? new Date(), timeZone);
  return format(tzDate, `yyyy${divider}MM${divider}dd`, { timeZone });
};

export const timeHMSFormat = (time: Date | string | null, divider = '.') => {
  const date = time ? new Date(time) : new Date();
  var hours = date.getUTCHours();
  var minutes = date.getUTCMinutes();
  var ampm = hours >= 12 ? 'PM' : 'AM';
  hours = hours % 12;
  hours = hours ? hours : 12;
  var new_minutes = minutes < 10 ? '0' + minutes : minutes;
  var strTime = hours + ':' + new_minutes + ' ' + ampm;
  return strTime;
};

export const dateTZFormat = (time: Date | string | null) => {
  const timeZone = store.getState()?.auth?.user?.time_zone;
  const tzDate = utcToZonedTime(time ?? new Date(), timeZone);
  return format(tzDate, 'yyyy-MM-dd HH:mm:ss zzz', { timeZone });
};

export const dateTZ_MDYFormat = (time: Date | string | null) => {
  const timeZone = store.getState()?.auth?.user?.time_zone;
  const tzDate = utcToZonedTime(time ?? new Date(), timeZone);
  return format(tzDate, 'MM-dd-yyyy', { timeZone });
};

export const dateUTCFormat = (time: Date | string) => {
  const timeZone = store.getState()?.auth?.user?.time_zone;
  return zonedTimeToUtc(time, timeZone);
};

export const getTZ = (time: Date) => {
  const timeZone = store.getState()?.auth?.user?.time_zone;
  return format(time, 'zzz', { timeZone });
};
