import React, { useState } from 'react';
import { useFormik } from 'formik';
import { Grid } from '@material-ui/core';

import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import { useForm } from 'app/Common/Hooks/useForm';
import CustomDialog from 'app/Common/CustomDialog';
import CustomAlertMsg from 'app/Common/CustomAlertMsg';
import FormButtons from 'app/Common/FormButtons';
import { SubscriptionFormProps } from '../types';
import MultifilesUploader from 'app/Common/MultiFilesUploader';
import AttachmentList from 'app/Common/AttachmentList';

import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

const SubscriptionForm = ({ form, closeForm }: SubscriptionFormProps) => {
  const [files, setFiles] = React.useState<Array<{ [key: string]: any }>>([]);
  const [selectMonth, setSelectMonth] = useState('');

  const formHook = useForm();

  const formik = useFormik({
    initialValues: {
      files: []
    },
    onSubmit: values => {
      const formData = new FormData();
      files.forEach((file: { [key: string]: string }) =>
        // @ts-ignore
        formData.append(`csv_file`, file)
      );
      formData.append('month_subscription', selectMonth);
      formHook.onSubmitPost(`nlt_subscriptions/upload_csv`, formData, true);
    }
  });

  const closeAlertHandle = () => {
    if (formHook.status === 'error') return;
    closeForm(true);
  };

  const months = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec'
  ];
  return (
    <>
      <CustomAlertMsg
        type={formHook.status}
        message={formHook.message}
        onClose={closeAlertHandle}
      />
      <CustomDialog
        open={form}
        closeHandle={() => {
          if (formHook.loading) return;
          closeForm(false);
        }}
        loading={formHook.loading}
        heading='Add Subscription'
        footer='hide'>
        <Grid
          container
          spacing={2}
          component='form'
          onSubmit={formik.handleSubmit}
          justify='center'>
          {!!files.length && (
            <AttachmentList files={files} setFiles={setFiles} />
          )}
          <MultifilesUploader
            files={files}
            uploadHandler={(file: { [key: string]: any }) =>
              setFiles(prev => [...prev, file])
            }
            text='Add Subscriptions'
            fileType={['.xlsx']}
          />
          <Grid item sm={3} className='ml5 p0 upSubDate '>
            <FormControl>
              <InputLabel id='select-toMonth-label'>Select Month</InputLabel>
              <Select
                labelId='select-toMonth-label'
                id='demo-simple-select'
                value={selectMonth}
                onChange={(e: any) => {
                  setSelectMonth(e.target.value);
                }}>
                {months.map((singleMonth: string, i: number) => (
                  <MenuItem
                    value={`${new Date().getFullYear()}-${i + 1}-01`}
                    key={i}>
                    {singleMonth}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <FormButtons
            loading={formHook.loading}
            submitBtnText='Add'
            onCancel={() => closeForm(false)}
            submitBtnDisable={!files.length || !selectMonth.length}
          />
        </Grid>
      </CustomDialog>
    </>
  );
};

export default React.memo(SubscriptionForm);
