import {
  GET_PROJECT_DETAILS,
  GET_PROJECT_DETAILS_FAIL,
  GET_PROJECT_DETAILS_SUCCESS
} from 'constants/index';

const initial = { data: {}, loading: false, error: '' };

const project = (
  state = initial,
  action: { type: string; payload: { data: {} } }
) => {
  switch (action.type) {
    case 'CLEAR_ERROR':
      return { ...state, error: '' };
    case GET_PROJECT_DETAILS:
      return { ...state, loading: true };
    case GET_PROJECT_DETAILS_SUCCESS:
      return { ...state, data: action.payload.data, loading: false };
    case GET_PROJECT_DETAILS_FAIL:
      return { ...state, error: action.payload, loading: false };
    default:
      return state;
  }
};

export default project;
