export const GET_EXPENSE_CATEGORIES = 'GET_EXPENSE_CATEGORIES';
export const GET_EXPENSE_CATEGORIES_SUCCESS = 'GET_EXPENSE_CATEGORIES_SUCCESS';
export const GET_EXPENSE_CATEGORIES_FAIL = 'GET_EXPENSE_CATEGORIES_FAIL';
export const CHANGE_EXPENSE_CATEGORIES_PAGER =
  'CHANGE_EXPENSE_CATEGORIES_PAGER';
export const CHANGE_EXPENSE_CATEGORIES_PAGE = 'CHANGE_EXPENSE_CATEGORIES_PAGE';

export const DELETE_EXPENSE_CATEGORY = 'DELETE_EXPENSE_CATEGORY';
export const DELETE_EXPENSE_CATEGORY_SUCCESS =
  'DELETE_EXPENSE_CATEGORY_SUCCESS';
export const DELETE_EXPENSE_CATEGORY_FAIL = 'DELETE_EXPENSE_CATEGORY_FAIL';
