import * as Yup from 'yup';

export const acceptNSignupValidation = Yup.object({
  password: Yup.string().required().min(6).label('Password'),
  password_confirmation: Yup.string()
    .required()
    .min(6)
    .oneOf(
      [Yup.ref('password')],
      'Password and Password Confirmation must be same'
    )
    .label('Password Confirmation')
});
