import {
  ACCEPT_ORGANIZATION_REQUEST,
  REJECT_ORGANIZATION_REQUEST
} from 'constants/index';
import { apiService } from 'middleware/api';

export const onAcceptOrganizationRequest = (token: string) => {
  const endPoint = `invites/${token}/accept`;
  const method = 'get';
  return apiService(endPoint, method, ACCEPT_ORGANIZATION_REQUEST);
};

export const onRejectOrganizationRequest = (token: string) => {
  const endPoint = `invites/${token}/reject`;
  const method = 'get';
  return apiService(endPoint, method, REJECT_ORGANIZATION_REQUEST);
};
