export const GET_TASK_STATUSES = 'GET_TASK_STATUSES';
export const GET_TASK_STATUSES_SUCCESS = 'GET_TASK_STATUSES_SUCCESS';
export const GET_TASK_STATUSES_FAIL = 'GET_TASK_STATUSES_FAIL';

export const GET_SINGLE_TASK_STATUS = 'GET_SINGLE_TASK_STATUS';
export const GET_SINGLE_TASK_STATUS_SUCCESS = 'GET_SINGLE_TASK_STATUS_SUCCESS';
export const GET_SINGLE_TASK_STATUS_FAIL = 'GET_SINGLE_TASK_STATUS_FAIL';

export const UPDATE_TASKS_STATUS = 'UPDATE_TASKS_STATUS';
export const UPDATE_TASKS_STATUS_SUCCESS = 'UPDATE_TASKS_STATUS_SUCCESS';
export const UPDATE_TASKS_STATUS_FAIL = 'UPDATE_TASKS_STATUS_FAIL';

export const DELETE_TASKS_STATUS = 'DELETE_TASKS_STATUS';
export const DELETE_TASKS_STATUS_SUCCESS = 'DELETE_TASKS_STATUS_SUCCESS';
export const DELETE_TASKS_STATUS_FAIL = 'DELETE_TASKS_STATUS_FAIL';
