export const ACCEPT_ORGANIZATION_REQUEST = 'ACCEPT_ORGANIZATION_REQUEST';
export const ACCEPT_ORGANIZATION_REQUEST_SUCCESS =
  'ACCEPT_ORGANIZATION_REQUEST_SUCCESS';
export const ACCEPT_ORGANIZATION_REQUEST_FAIL =
  'ACCEPT_ORGANIZATION_REQUEST_FAIL';

export const REJECT_ORGANIZATION_REQUEST = 'REJECT_ORGANIZATION_REQUEST';
export const REJECT_ORGANIZATION_REQUEST_SUCCESS =
  'REJECT_ORGANIZATION_REQUEST_SUCCESS';
export const REJECT_ORGANIZATION_REQUEST_FAIL =
  'REJECT_ORGANIZATION_REQUEST_FAIL';
