import {
  LOGIN,
  LOGIN_SUCCESS,
  LOGOUT,
  SIGNUP,
  FORGET,
  RESET,
  ACCEPT,
  SHOW_INVITE,
  INVITED_USER,
  SET_ORG
} from 'constants/index';
import { authService } from 'middleware/auth';
import { apiService } from 'middleware/api';
import {
  AcceptInviteFormFields,
  ForgetFormFields,
  LoginFormFields,
  ResetFormFields,
  SignupFormFields
} from 'types';
import { CLEAR_SUBSCRIPTION } from 'app/Dashboard/Subscriptions/redux/constants';

export const onLogin = (data: LoginFormFields) => {
  const endPoint = 'auth/sign_in.json';
  const method = 'post';
  return authService(data, endPoint, method, LOGIN);
};

export const setCurrentUser = (payload: {}) => ({
  type: LOGIN_SUCCESS,
  payload
});

export const logout = () => {
  localStorage.clear();
  return { type: LOGOUT };
};

export const onSignup = (data: SignupFormFields) => {
  const endPoint = 'auth.json';
  const method = 'post';
  return authService(data, endPoint, method, SIGNUP);
};

export const onForgetPassword = (data: ForgetFormFields) => {
  const endPoint = 'auth/password.json';
  const method = 'post';
  return authService(data, endPoint, method, FORGET);
};

export const onResetPassword = (data: ResetFormFields, headers: {}) => {
  const endPoint = 'auth/password.json';
  const method = 'put';
  return apiService(endPoint, method, RESET, data, false, headers);
};
export const onShowInviteData = (token: string, headers = {}) => {
  const endPoint = `/invites/${token}.json`;
  const method = 'get';
  return apiService(endPoint, method, SHOW_INVITE, {}, false, headers);
};

export const onAccept = (data: AcceptInviteFormFields, token: string) => {
  const endPoint = `invites/${token}`;
  const method = 'put';
  return apiService(endPoint, method, ACCEPT, data);
};

export const onInvitedUser = (token: string | null) => {
  const endPoint = `invites?token=${token}`;
  const method = 'get';
  return apiService(endPoint, method, INVITED_USER);
};

export const onSetOrg = (payload: {}) => ({
  type: SET_ORG,
  payload
});

export const onClearCurrentSubscription = () => ({ type: CLEAR_SUBSCRIPTION });

export const onClearMsg = () => ({ type: 'CLEAR_AUTH' });
