import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useFormik } from 'formik';
import { LinearProgress, Typography } from '@material-ui/core';
import UpdateIcon from '@material-ui/icons/Update';

import AuthFormsButton from './AuthFormsButton';
import CustomAlertMsg from 'app/Common/CustomAlertMsg';
import InputField from 'app/Common/InputFields';
import { paramsToObject } from 'utils/common/paramsToObject';
import { resetValidation } from 'validate/auth/resetValidation';
import { resetPasswordFields } from 'assets/static/forms/auth/resetPasswordFields';
import { useStyles } from '../styles';
import { ResetFormProps } from '../types';

const ResetPasswordForm = ({
  resetHandle,
  loading,
  error,
  message,
  clearMsgHandler
}: ResetFormProps) => {
  const classes = useStyles();
  const { push } = useHistory();

  const { search } = useLocation();
  const headers = paramsToObject(search);

  const formik = useFormik({
    initialValues: { password: '', password_confirmation: '' },
    validationSchema: resetValidation,
    onSubmit: values => {
      resetHandle(values, headers);
    }
  });

  return (
    <div className={classes.container}>
      <Typography variant='h4' component='h2' gutterBottom align='center'>
        Reset Password
      </Typography>
      <CustomAlertMsg
        type={error.length ? 'error' : 'success'}
        message={error || message}
        onClose={() => {
          clearMsgHandler();
          message.length && push('/');
        }}
      />
      <form onSubmit={formik.handleSubmit} className={classes.form}>
        {loading && <LinearProgress />}
        <UpdateIcon
          fontSize='large'
          className={`${classes.icon}  ${classes.forgetIcon}`}
        />
        {resetPasswordFields.map(({ label, value }, index) => (
          <InputField
            key={index}
            className={classes.fields}
            label={label}
            value={value}
            type='password'
            autoFocus={index === 0}
            autoComplete='new-pass'
            formik={formik}
            InputProps={{ className: classes.input }}
          />
        ))}
        <div className={classes.authFormBottom}>
          <AuthFormsButton loading={loading} text='Reset Password' />
        </div>
      </form>
    </div>
  );
};

export default React.memo(ResetPasswordForm);
