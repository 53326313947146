import React from 'react';
import { useSelector } from 'react-redux';
import { LinearProgress } from '@material-ui/core';

import CustomAlertMsg from 'app/Common/CustomAlertMsg';

const AcceptInvite = () => {
  const { loading, error, message } = useSelector(
    ({
      acceptInvite
    }: {
      acceptInvite: { loading: boolean; error: string; message: string };
    }) => acceptInvite
  );

  if (loading) return <LinearProgress />;
  return (
    <CustomAlertMsg
      type={error.length ? 'error' : 'success'}
      message={error || message}
      onClose={() => window.location.reload()}
    />
  );
};

export default AcceptInvite;


