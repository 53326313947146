import React from 'react';
import {
  TableHead,
  TableRow,
  TableCell,
  Checkbox,
  makeStyles,
  TableSortLabel
} from '@material-ui/core';
import { CustomTableHeadProps } from '../types/customTable';

const CustomTableHead = ({
  isSelection,
  headItems,
  sortingHeadItems,
  selectedRows = [],
  rows,
  loading,
  actions,
  setCollaspe,
  changeSelectedRows,
  onSort,
  sortBy
}: CustomTableHeadProps) => {
  const classes = useStyles();
  const allRowsSelection = (e: any) => {
    if (!changeSelectedRows) return;
    if (e.target.checked) {
      changeSelectedRows(rows);
    } else {
      changeSelectedRows([]);
    }
  };

  return (
    <TableHead>
      <TableRow>
        {isSelection && (
          <TableCell padding='checkbox'>
            <Checkbox
              indeterminate={
                selectedRows.length > 0 && selectedRows.length < rows.length
              }
              checked={selectedRows.length === rows.length}
              onChange={allRowsSelection}
              disabled={loading}
            />
          </TableCell>
        )}
        {setCollaspe && <TableCell />}
        {headItems.map((title, index) => {
          return (
            (sortingHeadItems && (sortingHeadItems.some(item => item === title))) ?
              <TableCell className={classes.bold} key={'H' + index}>
                <TableSortLabel
                  active
                  direction={sortBy ? sortBy : 'desc'}
                  onClick={() => onSort && onSort(title.toString())}
                >

                  {title}
                </TableSortLabel>
              </TableCell>
              : <TableCell className={classes.bold} key={'H' + index}>
                {title}
              </TableCell>
          )
        })}
        {actions && (
          <TableCell className={classes.bold} align='center'>
            Actions
          </TableCell>
        )}
      </TableRow>
    </TableHead>
  );
};
export default React.memo(CustomTableHead);

const useStyles = makeStyles(() => ({ bold: { fontWeight: 'bold' } }));
