import {
  GET_USERS,
  DELETE_USER,
  CHANGE_USERS_PAGER,
  CHANGE_USERS_PAGE,
  UPDATE_USER_ROLE,
  CHANGE_USER_PERMISSION,
  GET_USER_BY_EMAIL,
  GET_USER_LIMIT
} from 'constants/index';
import { apiService } from 'middleware/api';
import { FORM_EDIT_DATA } from 'actions';

export const onGetUsers = (page = 1, perPage = 10, keyword = '') => {
  const endPoint = `users.json?page=${page}&per_page=${perPage}&keyword=${keyword}`;
  const method = 'get';
  return apiService(endPoint, method, GET_USERS);
};

export const onGetUser = (id: string) => {
  const endPoint = `users/${id}.json`;
  const method = 'get';
  return apiService(endPoint, method, FORM_EDIT_DATA);
};

export const onDeleteUser = (id: number) => {
  const endPoint = `users/${id}.json`;
  const method = 'delete';
  return apiService(endPoint, method, DELETE_USER);
};

export const onUpdateRole = (id: number, data: { role: string }) => {
  const endPoint = `users/${id}/assign_role.json`;
  const method = 'put';
  return apiService(endPoint, method, UPDATE_USER_ROLE, data);
};

export const onSuspendUser = (id: number) => {
  const endPoint = `users/${id}/suspend?suspended=true`;
  const method = 'put';
  return apiService(endPoint, method, CHANGE_USER_PERMISSION);
};

export const onActiveUser = (id: number) => {
  const endPoint = `users/${id}/re_activate`;
  const method = 'put';
  return apiService(endPoint, method, CHANGE_USER_PERMISSION);
};
export const onChangeUsersPerPage = (perPage: number) => ({
  type: CHANGE_USERS_PAGER,
  payload: perPage
});

export const onGetUserLimit = () => {
  const endPoint = `stripe_subscriptions/user_limit_exceeded.json`;
  const method = 'get';
  return apiService(endPoint, method, GET_USER_LIMIT);
};

export const onChangeUsersPage = (page: number) => ({
  type: CHANGE_USERS_PAGE,
  payload: page
});

export const onGetUserByEmail = (email: string) => {
  const endPoint = `users?email=${email}`;
  const method = 'get';
  return apiService(endPoint, method, GET_USER_BY_EMAIL);
};
