import React from 'react';
import Dialog from '@material-ui/core/Dialog';

import {
  FadeTransition,
  GrowTransition,
  SlideTransition,
  ZoomTransition
} from '../Transitions';
import DialogHead from './DialogHead';
import DialogBody from './DialogBody';
import DialogFooter from './DialogFooter';
import { CustomDialogProps } from '../types/customDialog';
import { LinearProgress } from '@material-ui/core';

const CustomDialog = ({
  closeHandle,
  okHandle,
  open,
  animationType = 'fade',
  maxWidth = 'sm',
  fullWidth = true,
  children,
  footer,
  okButtonText,
  cancelButtonText,
  cancelHandle,
  loading,
  noHead = false,
  heading
}: CustomDialogProps) => {
  const animations = [
    { name: 'slide', animate: SlideTransition },
    { name: 'zoom', animate: ZoomTransition },
    { name: 'grow', animate: GrowTransition },
    { name: 'fade', animate: FadeTransition }
  ];
  return (
    <Dialog
      onClose={closeHandle}
      open={open}
      maxWidth={maxWidth}
      fullWidth={fullWidth}
      TransitionComponent={
        animations.find(v => v.name === animationType)?.animate
      }
    >
      {loading && <LinearProgress />}
      {noHead ? null : (
        <DialogHead closeHandle={closeHandle} heading={heading} />
      )}
      <DialogBody children={children} />
      <DialogFooter
        closeHandle={closeHandle}
        okHandle={okHandle}
        footer={footer}
        okButtonText={okButtonText}
        cancelButtonText={cancelButtonText}
        cancelHandle={cancelHandle}
      />
    </Dialog>
  );
};

export default React.memo(CustomDialog);
