import {
  GET_PRODUCTS,
  GET_PRODUCTS_FAIL,
  GET_PRODUCTS_SUCCESS,
  DELETE_PRODUCT,
  DELETE_PRODUCT_FAIL,
  DELETE_PRODUCT_SUCCESS,
  CHANGE_PRODUCTS_PAGER,
  CHANGE_PRODUCTS_PAGE
} from 'constants/index';

const initial = {
  data: [],
  loading: false,
  error: '',
  message: '',
  page: 1,
  perPage: 10,
  count: 1
};

const products = (state = initial, action: { type: string; payload: any }) => {
  switch (action.type) {
    case GET_PRODUCTS:
      return { ...state, loading: true };
    case GET_PRODUCTS_SUCCESS:
      return {
        ...state,
        data: action.payload.data,
        count: action.payload.meta_attributes
          ? action.payload.meta_attributes.total_count
          : 1,
        loading: false
      };
    case GET_PRODUCTS_FAIL:
      return { ...state, error: action.payload, loading: false };
    case 'CLEAR_ERROR':
      return { ...state, error: '', message: '' };
    case DELETE_PRODUCT:
      return { ...state, loading: true };
    case DELETE_PRODUCT_SUCCESS:
      return { ...state, message: action.payload.message, loading: false };
    case DELETE_PRODUCT_FAIL:
      return { ...state, error: action.payload, loading: false };
    case CHANGE_PRODUCTS_PAGER:
      return { ...state, perPage: action.payload };
    case CHANGE_PRODUCTS_PAGE:
      return { ...state, page: action.payload };
    default:
      return state;
  }
};

export default products;
