import {
  DELETE_CYBERSECUTIRYFORM,
  GET_CYBERSECUTIRYFORM,
  CHANGE_CYBERSECUTIRYFORM_PAGER,
  CHANGE_CYBERSECUTIRYFORM_PAGE,
  GET_CYBERSECUTIRYFORM_QUESTIONS,
  GET_CYBERSECUTIRYFORM_LISTING,
  CREATE_FEEDBACK
} from '../constants';

import { apiService } from 'middleware/api';

export const onGetCyberForms = (page = 1, perPage = 10, keyword = '') => {
  const endPoint = `feedback_submitters?page=${page}&per_page=${perPage}&keyword=${keyword}`;
  const method = 'get';
  return apiService(endPoint, method, GET_CYBERSECUTIRYFORM_LISTING);
};

export const onGetCyberForm = (key: number) => {
  const endPoint = `feedback_submitters/${key}.json`;
  const method = 'get';
  return apiService(endPoint, method, GET_CYBERSECUTIRYFORM);
};

export const onDeleteCyberForms = (key: string) => {
  const endPoint = `feedback_submitters/${key}.json`;
  const method = 'delete';
  return apiService(endPoint, method, DELETE_CYBERSECUTIRYFORM);
};

export const onChangeCyberFormsPerPage = (perPage: number) => ({
  type: CHANGE_CYBERSECUTIRYFORM_PAGER,
  payload: perPage
});

export const onChangeCyberFormsPage = (page: number) => ({
  type: CHANGE_CYBERSECUTIRYFORM_PAGE,
  payload: page
});

export const onGetCyberSecurityQuestions = (token: string) => {
  const endPoint = `cyber_security_questions`;
  const method = 'get';
  const headers = {
    'organization-secret-token': token
  };
  return apiService(
    endPoint,
    method,
    GET_CYBERSECUTIRYFORM_QUESTIONS,
    {},
    false,
    headers
  );
};

export const onSubmitForm = (data: {}, token: string) => {
  const endPoint = `feedback_submitters`;
  const method = 'post';
  const headers = {
    'organization-secret-token': token
  };
  return apiService(endPoint, method, CREATE_FEEDBACK, data, false, headers);
};
