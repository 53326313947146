import {
  GET_QUOTES,
  GET_QUOTES_FAIL,
  GET_QUOTES_SUCCESS,
  DELETE_QUOTE,
  DELETE_QUOTE_FAIL,
  DELETE_QUOTE_SUCCESS,
  CHANGE_QUOTES_PAGER,
  CHANGE_QUOTES_PAGE,
  CHANGE_QUOTE_STATUS,
  CHANGE_QUOTE_STATUS_SUCCESS,
  CHANGE_QUOTE_STATUS_FAIL,
  GET_QUOTE,
  GET_QUOTE_SUCCESS,
  GET_QUOTE_FAIL,
  CLEAR_QUOTE
} from 'constants/index';

const initial = {
  data: [],
  loading: false,
  error: '',
  message: '',
  page: 1,
  perPage: 10,
  count: 1,
  quote: {}
};

const quotes = (state = initial, action: { type: string; payload: any }) => {
  switch (action.type) {
    case GET_QUOTES:
      return { ...state, loading: true };
    case GET_QUOTES_SUCCESS:
      return {
        ...state,
        data: action.payload.data,
        count: action.payload.meta_attributes
          ? action.payload.meta_attributes.total_count
          : 1,
        loading: false
      };
    case GET_QUOTES_FAIL:
      return { ...state, error: action.payload, loading: false };
    case 'CLEAR_ERROR':
      return { ...state, error: '', message: '' };
    case DELETE_QUOTE:
      return { ...state, loading: true };
    case DELETE_QUOTE_SUCCESS:
      return { ...state, message: action.payload.message, loading: false };
    case DELETE_QUOTE_FAIL:
      return { ...state, error: action.payload, loading: false };
    case CHANGE_QUOTE_STATUS:
      return { ...state, loading: true };
    case CHANGE_QUOTE_STATUS_SUCCESS:
      return { ...state, message: action.payload.message, loading: false };
    case CHANGE_QUOTE_STATUS_FAIL:
      return { ...state, error: action.payload, loading: false };
    case GET_QUOTE:
      return { ...state, loading: true };
    case GET_QUOTE_SUCCESS:
      return { ...state, quote: action.payload.data, loading: false };
    case GET_QUOTE_FAIL:
      return { ...state, error: action.payload, loading: false };
    case CHANGE_QUOTES_PAGER:
      return { ...state, perPage: action.payload };
    case CHANGE_QUOTES_PAGE:
      return { ...state, page: action.payload };
    case CLEAR_QUOTE:
      return { ...state, quote: {} };
    default:
      return state;
  }
};

export default quotes;
