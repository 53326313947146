export const GET_QUOTEITEMS = 'GET_QUOTEITEMS';
export const GET_QUOTEITEMS_SUCCESS = 'GET_QUOTEITEMS_SUCCESS';
export const GET_QUOTEITEMS_FAIL = 'GET_QUOTEITEMS_FAIL';
export const DELETE_QUOTEITEM = 'DELETE_QUOTEITEM';
export const DELETE_QUOTEITEM_SUCCESS = 'DELETE_QUOTEITEM_SUCCESS';
export const DELETE_QUOTEITEM_FAIL = 'DELETE_QUOTEITEM_FAIL';
export const CHANGE_QUOTEITEMS_PAGER = 'CHANGE_QUOTEITEMS_PAGER';
export const CHANGE_QUOTEITEMS_PAGE = 'CHANGE_QUOTEITEMS_PAGE';
export const PRINT_QUOTE = 'PRINT_QUOTE';
export const PRINT_QUOTE_SUCCESS = 'PRINT_QUOTE_SUCCESS';
export const PRINT_QUOTE_FAIL = 'PRINT_QUOTE_FAIL';
