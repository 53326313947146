import { FORM_EDIT_DATA } from 'actions';
import { apiService } from 'middleware/api';
import {
  CHANGE_TEMPLATE_TASKS_PAGE,
  CHANGE_TEMPLATE_TASKS_PAGER,
  GET_TEMPLATE_TASKS,
  DELETE_TEMPLATE_TASK
} from '../constants';

export const onGetTemplateTasks = (
  page = 1,
  perPage = 10,
  keyword = '',
  projectTemplateId = ''
) => {
  const endPoint = `project_task_templates.json?page=${page}&per_page=${perPage}&keyword=${keyword}&project_template_id=${projectTemplateId}`;
  const method = 'get';
  return apiService(endPoint, method, GET_TEMPLATE_TASKS);
};

export const onGetTemplateTask = (key: string) => {
  const endPoint = `project_task_templates/${key}.json`;
  const method = 'get';
  return apiService(endPoint, method, FORM_EDIT_DATA);
};

export const onDeleteTemplateTask = (key: string) => {
  const endPoint = `project_task_templates/${key}.json`;
  const method = 'delete';
  return apiService(endPoint, method, DELETE_TEMPLATE_TASK);
};

export const onChangeTemplateTaskPerPage = (perPage: number) => ({
  type: CHANGE_TEMPLATE_TASKS_PAGER,
  payload: perPage
});

export const onChangeTemplateTaskPage = (page: number) => ({
  type: CHANGE_TEMPLATE_TASKS_PAGE,
  payload: page
});
