import { createMuiTheme } from '@material-ui/core/styles';

export function customTheme(
  primary_color = localStorage.getItem('primary_color') || '#0f161c',
  secondary_color = localStorage.getItem('secondary_color') || '#282828',
  companyLogo = localStorage.getItem('logo') || ''
) {
  const backgroundColor = '#f5f5f5';
  localStorage.setItem('primary_color', primary_color);
  localStorage.setItem('secondary_color', secondary_color);
  localStorage.setItem('logo', companyLogo);
  return createMuiTheme({
    palette: {
      type: 'light',
      // base: { background: '#e9e9e9', borderColor: '#e2e2e2' },
      primary: { main: primary_color },
      secondary: { main: secondary_color },
      background: { default: backgroundColor }
    },
    typography: {
      fontFamily: `'RobotoRegular', 'Helvetica Neue', Helvetica, sans-serif`,
      button: { textTransform: 'capitalize' }
    },
    overrides: {
      // MuiAutocomplete: { option: { color: primary_color } },
      // MuiInputLabel: { root: { color: `${primary_color} !important` } }
    },
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 768,
        lg: 1280,
        xl: 1920
      }
    }
  });
}
