import {
  CHANGE_TASKS_PAGE,
  CHANGE_TASKS_PAGER,
  CHANGE_TASK_STATUS,
  CHANGE_TASK_STATUS_FAIL,
  CHANGE_TASK_STATUS_SUCCESS,
  CLEAR_TASK_DETAILS,
  DELETE_TASK,
  DELETE_TASK_FAIL,
  DELETE_TASK_SUCCESS,
  GET_CHILD_TASKS,
  GET_CHILD_TASKS_DETAILS,
  GET_CHILD_TASKS_DETAILS_FAIL,
  GET_CHILD_TASKS_DETAILS_SUCCESS,
  GET_CHILD_TASKS_FAIL,
  GET_CHILD_TASKS_SUCCESS,
  GET_TASKS,
  GET_TASKS_FAIL,
  GET_TASKS_SUCCESS,
  GET_TASK_DETAILS,
  GET_TASK_DETAILS_FAIL,
  GET_TASK_DETAILS_SUCCESS
} from 'constants/index';

const initial = {
  data: [],
  loading: false,
  error: '',
  message: '',
  page: 1,
  perPage: 10,
  count: 1,
  task: {},
  childTasks: [],
  childTask: {}
};

const tasks = (state = initial, action: { type: string; payload: any }) => {
  switch (action.type) {
    case GET_TASKS:
      return { ...state, loading: true };
    case GET_TASKS_SUCCESS:
      return {
        ...state,
        data: action.payload.data,
        count: action.payload.meta_attributes
          ? action.payload.meta_attributes.total_count
          : 1,
        loading: false
      };
    case GET_TASKS_FAIL:
      return { ...state, error: action.payload, loading: false };
    case 'CLEAR_ERROR':
      return { ...state, error: '', message: '' };
    case DELETE_TASK:
      return { ...state, loading: true };
    case DELETE_TASK_SUCCESS:
      return { ...state, message: action.payload.message, loading: false };
    case DELETE_TASK_FAIL:
      return { ...state, error: action.payload, loading: false };
    case CHANGE_TASK_STATUS:
      return { ...state, loading: true };
    case CHANGE_TASK_STATUS_SUCCESS:
      return {
        ...state,
        message: 'Task status has been changed',
        loading: false
      };
    case CHANGE_TASK_STATUS_FAIL:
      return { ...state, error: action.payload, loading: false };
    case CHANGE_TASKS_PAGER:
      return { ...state, perPage: action.payload };
    case CHANGE_TASKS_PAGE:
      return { ...state, page: action.payload };
    case GET_TASK_DETAILS:
      return { ...state, loading: true };
    case GET_TASK_DETAILS_SUCCESS:
      return { ...state, task: action.payload.data, loading: false };
    case GET_TASK_DETAILS_FAIL:
      return { ...state, error: action.payload, loading: false };
    case CLEAR_TASK_DETAILS:
      return { ...state, task: {} };
    case GET_CHILD_TASKS:
      return {
        ...state,
        loading: true
      };
    case GET_CHILD_TASKS_SUCCESS:
      return {
        ...state,
        loading: false,
        childTasks: action.payload.data
      };
    case GET_CHILD_TASKS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload
      };
    case GET_CHILD_TASKS_DETAILS:
      return { ...state, loading: true };
    case GET_CHILD_TASKS_DETAILS_SUCCESS:
      return { ...state, loading: false, childTask: action.payload.data };
    case GET_CHILD_TASKS_DETAILS_FAIL:
      return { ...state, loading: false, error: action.payload };

    default:
      return state;
  }
};

export default tasks;
