export const taskStatusItems = [
  { name: 'Todo', value: 'todo', label: 'Todo', color: '#7f8c8d' },
  {
    name: 'In progress',
    value: 'in_progress',
    label: 'In progress',
    color: '#f39c12'
  },
  { name: 'Done', value: 'done', label: 'Done', color: '#27ae60' }
];
