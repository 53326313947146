import { Button, Grid, TextField } from '@material-ui/core';
import { Add } from '@material-ui/icons';
import { Autocomplete } from '@material-ui/lab';
import ListSearcher from 'app/Common/ListSearcher';
import React from 'react';
import { TasksTopbarProps } from '../types';

const TasksTopbar = ({
  loading,
  setForm,
  setSearchText,
  mainListing,
  projectId,
  onAssignedTasks,
  isAssignedTasks,
  isCreateChildTasks,
  taskStatus,
  setTaskStatus,
  isChildTask,
  statusList,
  setStatusId
}: TasksTopbarProps) => {
  return (
    <Grid
      container
      className='mt10 mb10'
      justify='space-between'
      alignItems='flex-end'
      sm={12}>
      {!isCreateChildTasks && (
        <Grid container item alignItems='flex-end' sm={mainListing ? 7 : 6}>
          <Grid item sm={4}>
            <ListSearcher
              searchTextHandle={setSearchText}
              width150
              loading={loading}
            />
          </Grid>
          {!projectId && (
            <Grid item sm={4}>
              <Autocomplete
                disabled={false}
                id='taskStatus'
                options={statusList}
                getOptionLabel={option => option.name}
                onChange={(e, v, r) => setStatusId(v?.id as number)}
                renderInput={params => (
                  <TextField
                    {...params}
                    label={'Select Status'}
                    variant='standard'
                  />
                )}
              />
            </Grid>
          )}
          {!projectId && (
            <Grid item sm={4}>
              <Button
                size='small'
                className='ml5'
                color='primary'
                variant='contained'
                disabled={loading}
                onClick={onAssignedTasks}>
                {isAssignedTasks ? `Show All Tasks` : ` Show Assigned Tasks`}
              </Button>
            </Grid>
          )}
        </Grid>
      )}

      {(!!projectId || isCreateChildTasks) && (
        <Grid
          container
          justify={isCreateChildTasks ? 'flex-start' : 'flex-end'}
          item
          sm={6}>
          {!isChildTask && (
            <Button
              size='small'
              className={isCreateChildTasks ? '' : 'ml5'}
              color='primary'
              variant='contained'
              disabled={loading}
              onClick={() => setForm(true)}>
              <Add />
              {isCreateChildTasks ? 'Create SubTask' : 'New Task'}
            </Button>
          )}
        </Grid>
      )}
    </Grid>
  );
};

export default React.memo(TasksTopbar);
