import { Avatar, Grid, TextField } from '@material-ui/core';
import { useForm } from 'app/Common/Hooks/useForm';
import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import SingleCommentUI from './SingleCommentUI';

const TaskComments = ({ taskID }: { taskID: number }) => {
  const [username, setUsername] = useState<string>('');
  const [description, setComment] = useState<string>('');

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem('user') || '{}');
    const username = user ? `${user.first_name} ${user.last_name}` : '';
    setUsername(username);
  }, []);

  const formHook = useForm();

  const formik = useFormik({
    initialValues: {
      username: '',
      comment: '',
      task_id: taskID
    },

    onSubmit: () => {
      formHook.onSubmitPost(`tasks/${taskID}/comments`, {
        username,
        description,
        task_id: taskID
      });
    }
  });

  return (
    <div style={{ maxWidth: '600px' }}>
      <Grid container spacing={0}>
        <Grid
          item
          xs={12}
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}>
          <Avatar style={{ background: 'purple' }}>{username.charAt(0)}</Avatar>{' '}
          <form onSubmit={formik.handleSubmit} style={{ width: '100%' }}>
            <TextField
              id='outlined-full-width'
              style={{ margin: 8 }}
              placeholder='Add a comment...'
              fullWidth
              margin='normal'
              InputLabelProps={{
                shrink: true
              }}
              variant='outlined'
              value={description}
              onChange={e => setComment(e.target.value)}
            />
          </form>
        </Grid>
      </Grid>

      <SingleCommentUI />
    </div>
  );
};

export default TaskComments;
