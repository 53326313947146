import {
  CREATE_SUBSCRIPTIONS,
  GET_CARDS,
  DELETE_CARD,
  GET_SUBSCRIPTION,
  UPDATE_PLAN,
  CREATE_CARD,
  CANCEL_SUBSCRIPTION
} from '../constants';
import { apiService } from 'middleware/api';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { data } from '../../types';

export const onCreateSubscriptions = (planid: number, id: string) => {
  const endPoint = `stripe_subscriptions.json`;
  const method = 'post';
  let data = {
    plan_id: planid,
    stripe_token: id
  };
  return apiService(endPoint, method, CREATE_SUBSCRIPTIONS, data);
};

export const onGetSubscription = (id: number) => {
  const endPoint = `stripe_subscriptions/${id}.json`;
  const method = 'get';
  return apiService(endPoint, method, GET_SUBSCRIPTION);
};

export const onUpdateSubscription = (id: number, subscriptionID: number) => {
  const endpoint = `stripe_subscriptions/${subscriptionID}.json`;
  const method = 'put';
  let data = {
    plan_id: id
  };
  return apiService(endpoint, method, UPDATE_PLAN, data);
};

export const onGetCards = () => {
  const endPoint = `user_cards.json`;
  const method = 'get';
  return apiService(endPoint, method, GET_CARDS);
};

export const onCreateCard = (data: data) => {
  const endPoint = `user_cards.json`;
  const method = 'post';

  return apiService(endPoint, method, CREATE_CARD, data);
};
export const onDeleteCard = (id: number) => {
  const endPoint = `user_cards/${id}.json`;
  const method = 'delete';
  return apiService(endPoint, method, DELETE_CARD);
};

export const onCancelSubscription = (id: number) => {
  const endPoint = `stripe_subscriptions/${id}/cancel.json`;
  const method = 'get';
  return apiService(endPoint, method, CANCEL_SUBSCRIPTION);
};
