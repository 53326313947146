import { useDispatch, useSelector } from 'react-redux';
import { onClearForm, onFormSubmit } from 'actions';
import { DynamicObject } from '../types';

export function useForm() {
  const dispatch = useDispatch();
  const onClear = () => dispatch(onClearForm());
  const onSubmitPost = (url: string, data: {}, formData = false) => {
    dispatch(onFormSubmit(url, 'post', data, formData));
  };
  const onSubmitPut = (url: string, data: {}, formData = false) => {
    dispatch(onFormSubmit(url, 'put', data, formData));
  };
  const { status, message, loading, data } = useSelector(
    ({ form }: FormProps) => form
  );

  return { status, message, loading, onClear, onSubmitPost, onSubmitPut, data };
}

interface FormProps {
  form: {
    loading: boolean;
    status: 'error' | 'success';
    message: string;
    data: DynamicObject;
  };
}
