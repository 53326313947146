import {
  CHANGE_UPCOMING_TASKS_PAGE,
  CHANGE_UPCOMING_TASKS_PAGER,
  GET_ACTIVITY_LOGS,
  GET_ACTIVITY_LOGS_FAIL,
  GET_ACTIVITY_LOGS_SUCCESS,
  GET_UPCOMING_TASKS,
  GET_UPCOMING_TASKS_FAIL,
  GET_UPCOMING_TASKS_SUCCESS
} from '../constants';

const initial = {
  data: [],
  loading: false,
  error: '',
  message: '',
  page: 1,
  perPage: 10,
  count: 1,
  current: {},
  activityLogs: []
};

const upcomingTasks = (
  state = initial,
  action: { type: string; payload: any }
) => {
  switch (action.type) {
    case GET_UPCOMING_TASKS:
      return { ...state, loading: true };
    case GET_UPCOMING_TASKS_SUCCESS:
      return {
        ...state,
        data: action.payload.data,
        loading: false,
        current: action.payload.current_tenant,
        count: action.payload.meta_attributes
          ? action.payload.meta_attributes.total_count
          : 1
      };
    case GET_UPCOMING_TASKS_FAIL:
      return { ...state, error: action.payload, loading: false };
    case 'CLEAR_ERROR':
      return { ...state, error: '', message: '' };
    case CHANGE_UPCOMING_TASKS_PAGER:
      return { ...state, perPage: action.payload };
    case CHANGE_UPCOMING_TASKS_PAGE:
      return { ...state, page: action.payload };
    case GET_ACTIVITY_LOGS:
      return { ...state, loading: true };
    case GET_ACTIVITY_LOGS_SUCCESS:
      return { ...state, activityLogs: action.payload.data, loading: false };
    case GET_ACTIVITY_LOGS_FAIL:
      return { ...state, error: action.payload, loading: false };
    default:
      return state;
  }
};

export default upcomingTasks;
