import {
  GET_ORGANIZATIONS,
  CHANGE_ORGANIZATION_PAGER,
  CHANGE_ORGANIZATION_PAGE,
  UPDATE_INTEGRATION
} from '../constants';
import { apiService } from '../../../../../middleware/api';
import { FORM_EDIT_DATA } from 'actions';

export const onGetOrganizations = (page = 1, perPage = 50) => {
  const endPoint = `organizations.json?page=${page}&per_page=${perPage}`;
  const method = 'get';
  return apiService(endPoint, method, GET_ORGANIZATIONS);
};

export const onGetOrganization = (key: string) => {
  const endPoint = `organizations/${key}.json`;
  const method = 'get';
  return apiService(endPoint, method, FORM_EDIT_DATA);
};

export const onChangeOrganizationsPerPage = (perPage: number) => ({
  type: CHANGE_ORGANIZATION_PAGER,
  payload: perPage
});

export const onChangeOrganizationsPage = (page: number) => ({
  type: CHANGE_ORGANIZATION_PAGE,
  payload: page
});

export const onUpdateIntegration = () => ({ type: UPDATE_INTEGRATION });
