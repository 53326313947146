import {
  CLEAR_ALL_TASKS,
  CLEAR_LATEST_TASKS,
  CLEAR_USER_BOARD_TASKS,
  CLEAR_USER_LATEST_TASK,
  GET_ASSIGNEE_TASKS,
  GET_ASSIGNEE_TASKS_FAIL,
  GET_ASSIGNEE_TASKS_SUCCESS,
  GET_USER_BOARD_TASKS,
  GET_USER_BOARD_TASKS_FAIL,
  GET_USER_BOARD_TASKS_SUCCESS
} from 'constants/index';

const initial: InitialState = {
  allTasks: [],
  latestTasks: [],
  loading: false,
  error: ''
};

const assigneeTasks = (
  state = initial,
  action: { type: string; payload: any }
) => {
  switch (action.type) {
    case GET_ASSIGNEE_TASKS:
      return { ...state, loading: true };
    case GET_ASSIGNEE_TASKS_SUCCESS:
      // @ts-ignore
      const tasks = Object.values(action.payload.data).filter(v => v && v.id);
      const latestTasks = state.latestTasks;
      const index = state.latestTasks.findIndex(
        (element: { [name: string]: any }) =>
          element.assignee === action.payload.data.assignee_id?.toString()
      );

      if (index > -1)
        latestTasks[index] = {
          tasks,
          assignee: action.payload.data.assignee_id
        };
      else
        latestTasks.push({ tasks, assignee: action.payload.data.assignee_id });

      return {
        ...state,
        latestTasks: Array.from(new Set(latestTasks)),
        loading: false
      };
    case GET_ASSIGNEE_TASKS_FAIL:
      return { ...state, error: action.payload, loading: false };

    case CLEAR_USER_BOARD_TASKS:
      return {
        ...state,
        loading: true,
        allTasks: state.allTasks.filter(
          task => task.assignee !== action.payload
        )
      };

    case GET_USER_BOARD_TASKS:
      return { ...state, loading: true };
    case GET_USER_BOARD_TASKS_SUCCESS:
      const allTasks = state.allTasks;
      const exist = state.allTasks.findIndex(
        (element: { [name: string]: any }) =>
          element.assignee === action.payload.data.assignee_id
      );

      if (exist > -1)
        allTasks[exist] = {
          data: action.payload.data,
          assignee: action.payload.data.assignee_id
        };
      else
        allTasks.push({
          data: allTasks,
          assignee: action.payload.data.assignee_id
        });

      const data = [
        ...state.allTasks.filter(item => {
          return item.assignee !== action.payload.data?.[0]?.assignee_id;
        }),
        {
          data: action.payload.data,
          assignee: action.payload.data?.[0]?.assignee_id
        }
      ];

      return { ...state, allTasks: Array.from(new Set(data)), loading: false };
    case GET_USER_BOARD_TASKS_FAIL:
      return { ...state, error: action.payload, loading: false };
    case CLEAR_ALL_TASKS:
      return { ...state, allTasks: [] };
    case CLEAR_LATEST_TASKS:
      return { ...state, latestTasks: [] };
    case CLEAR_USER_LATEST_TASK:
      return {
        ...state,
        latestTasks: state.latestTasks.filter(
          item => item.assignee !== action.payload
        )
      };

    default:
      return state;
  }
};

export default assigneeTasks;

interface InitialState {
  allTasks: Array<{ [name: string]: any }>;
  latestTasks: Array<{ [name: string]: any }>;
  loading: boolean;
  error: string;
}
