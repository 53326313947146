export const GET_PROJECT_TEMPLATES = 'GET_PROJECT_TEMPLATES';
export const GET_PROJECT_TEMPLATES_SUCCESS = 'GET_PROJECT_TEMPLATES_SUCCESS';
export const GET_PROJECT_TEMPLATES_FAIL = 'GET_PROJECT_TEMPLATES_FAIL';

export const GET_PROJECT_TEMPLATE = 'GET_PROJECT_TEMPLATE';
export const GET_PROJECT_TEMPLATE_SUCCESS = 'GET_PROJECT_TEMPLATE_SUCCESS';
export const GET_PROJECT_TEMPLATE_FAIL = 'GET_PROJECT_TEMPLATE_FAIL';

export const DELETE_PROJECT_TEMPLATE = 'DELETE_PROJECT_TEMPLATE';
export const DELETE_PROJECT_TEMPLATE_SUCCESS =
  'DELETE_PROJECT_TEMPLATE_SUCCESS';
export const DELETE_PROJECT_TEMPLATE_FAIL = 'DELETE_PROJECT_TEMPLATE_FAIL';

export const CHANGE_PROJECT_TEMPLATES_PAGE = 'CHANGE_PROJECT_TEMPLATES_PAGE';
export const CHANGE_PROJECT_TEMPLATES_PAGER = 'CHANGE_PROJECT_TEMPLATES_PAGER';

export const CLEAR_DATA = 'CLEAR_DATA';
