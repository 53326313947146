import React from 'react';

import CustomTable from 'app/Common/CustomTable';
import { CategoriesListProps } from '../../Product-Categories/types';
import ListDeleteButton from 'app/Common/ListDeleteButton';
import ListEditButton from 'app/Common/ListEditButton';

const ExpenseCategoriesList = ({
  data,
  loading,
  onDeleteCategory,
  page,
  perPage,
  count,
  changePerPageHandle,
  changePage
}: CategoriesListProps) => (
  <CustomTable
    rows={data}
    headItems={['Name', 'Description']}
    columns={['name', 'description']}
    loading={loading}
    perPage={perPage}
    page={page}
    count={count}
    isScroller
    changePageHandle={(e, page) => changePage(page)}
    changePerPageHandle={perPage => {
      changePage(1);
      changePerPageHandle(perPage);
    }}
    actions={() => [
      <ListEditButton loading={loading} name='expenseCategory' />,
      <ListDeleteButton loading={loading} onClickHandler={onDeleteCategory} />
    ]}
  />
);

export default React.memo(ExpenseCategoriesList);
