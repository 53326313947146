import {
  GET_EXPENSE_CATEGORIES,
  GET_EXPENSE_CATEGORIES_FAIL,
  GET_EXPENSE_CATEGORIES_SUCCESS,
  CHANGE_EXPENSE_CATEGORIES_PAGER,
  CHANGE_EXPENSE_CATEGORIES_PAGE,
  DELETE_EXPENSE_CATEGORY,
  DELETE_EXPENSE_CATEGORY_SUCCESS,
  DELETE_EXPENSE_CATEGORY_FAIL
} from '../constants';

const initial = {
  data: [],
  loading: false,
  error: '',
  message: '',
  page: 1,
  perPage: 10,
  count: 1
};

const expenseCategories = (
  state = initial,
  action: { type: string; payload: any }
) => {
  switch (action.type) {
    case GET_EXPENSE_CATEGORIES:
      return { ...state, loading: true };
    case GET_EXPENSE_CATEGORIES_SUCCESS:
      return {
        ...state,
        data: action.payload.data,
        count: action.payload.meta_attributes
          ? action.payload.meta_attributes.total_count
          : 1,
        loading: false
      };
    case GET_EXPENSE_CATEGORIES_FAIL:
      return { ...state, error: action.payload, loading: false };
    case 'CLEAR_ERROR':
      return { ...state, error: '', message: '' };
    case CHANGE_EXPENSE_CATEGORIES_PAGER:
      return { ...state, perPage: action.payload };
    case CHANGE_EXPENSE_CATEGORIES_PAGE:
      return { ...state, page: action.payload };
    case DELETE_EXPENSE_CATEGORY:
      return { ...state, loading: true };
    case DELETE_EXPENSE_CATEGORY_SUCCESS:
      return { ...state, message: action.payload.message, loading: false };
    case DELETE_EXPENSE_CATEGORY_FAIL:
      return { ...state, error: action.payload, loading: false };
    default:
      return state;
  }
};

export default expenseCategories;
