import React from 'react';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { Grid, IconButton } from '@material-ui/core';
import { format, parseISO } from 'date-fns';
import ClearIcon from '@material-ui/icons/Clear';

import {
  dateYMDFormat,
  dateYMDFormatWithTimeZone
} from 'utils/common/dateYMDFormat';
import { CustomDatePickerProps } from './types';

const CustomDatePicker = ({
  value,
  setter,
  title,
  isMinDate = true,
  maxDate,
  disabled = false,
  fullWidth,
  icon,
  clearer,
  withTZ = true
}: CustomDatePickerProps) => (
  <MuiPickersUtilsProvider utils={DateFnsUtils}>
    <Grid item sm={fullWidth ? 12 : 6}>
      <DatePicker
        minDate={isMinDate ? dateYMDFormat(new Date()) : undefined}
        maxDate={maxDate}
        fullWidth
        value={value?.toString() ? parseISO(value?.toString() ?? '') : null}
        variant='inline'
        format='yyyy-MM-dd'
        label={title}
        key={title}
        onChange={date =>
          withTZ
            ? setter(dateYMDFormatWithTimeZone(date))
            : setter(format(date ?? new Date(), 'yyyy-MM-dd'))
        }
        disabled={disabled}
        autoOk={true}
        InputProps={{
          endAdornment:
            icon ||
            (clearer && (
              <IconButton className='p0' onClick={e => clearer(e)}>
                <ClearIcon fontSize='small' />
              </IconButton>
            ))
        }}
      />
    </Grid>
  </MuiPickersUtilsProvider>
);

export default React.memo(CustomDatePicker);
