import {
  GET_ORGANIZATIONS,
  GET_ORGANIZATIONS_FAIL,
  GET_ORGANIZATIONS_SUCCESS,
  CHANGE_ORGANIZATION_PAGER,
  CHANGE_ORGANIZATION_PAGE
} from '../constants';

const initial = {
  data: [],
  loading: false,
  error: '',
  message: '',
  page: 1,
  perPage: 10,
  count: 1,
  current: {}
};

const organizations = (
  state = initial,
  action: { type: string; payload: any }
) => {
  switch (action.type) {
    case GET_ORGANIZATIONS:
      return { ...state, loading: true };
    case GET_ORGANIZATIONS_SUCCESS:
      return {
        ...state,
        data: action.payload.data,
        loading: false,
        current: action.payload.current_tenant,
        count: action.payload.meta_attributes
          ? action.payload.meta_attributes.total_count
          : 1
      };
    case GET_ORGANIZATIONS_FAIL:
      return { ...state, error: action.payload, loading: false };
    case 'CLEAR_ERROR':
      return { ...state, error: '', message: '' };
    case CHANGE_ORGANIZATION_PAGER:
      return { ...state, perPage: action.payload };
    case CHANGE_ORGANIZATION_PAGE:
      return { ...state, page: action.payload };
    default:
      return state;
  }
};

export default organizations;
