import {
  GET_TAXES,
  GET_TAXES_FAIL,
  GET_TAXES_SUCCESS,
  DELETE_TAX,
  DELETE_TAX_FAIL,
  DELETE_TAX_SUCCESS,
  CHANGE_TAXES_PAGER,
  CHANGE_TAXES_PAGE,
  UPDATE_TAX_STATUS,
  UPDATE_TAX_STATUS_SUCCESS,
  UPDATE_TAX_STATUS_FAIL
} from 'constants/index';

const initial = {
  data: [],
  loading: false,
  error: '',
  message: '',
  page: 1,
  perPage: 10,
  count: 1
};

const taxes = (state = initial, action: { type: string; payload: any }) => {
  switch (action.type) {
    case GET_TAXES:
      return { ...state, loading: true };
    case GET_TAXES_SUCCESS:
      return {
        ...state,
        data: action.payload.data,
        count: action.payload.meta_attributes
          ? action.payload.meta_attributes.total_count
          : 1,
        loading: false
      };
    case GET_TAXES_FAIL:
      return { ...state, error: action.payload, loading: false };
    case 'CLEAR_ERROR':
      return { ...state, error: '', message: '' };
    case DELETE_TAX:
      return { ...state, loading: true };
    case DELETE_TAX_SUCCESS:
      return { ...state, message: action.payload.message, loading: false };
    case DELETE_TAX_FAIL:
      return { ...state, error: action.payload, loading: false };
    case UPDATE_TAX_STATUS:
      return { ...state, loading: true };
    case UPDATE_TAX_STATUS_SUCCESS:
      return { ...state, message: action.payload.message, loading: false };
    case UPDATE_TAX_STATUS_FAIL:
      return { ...state, error: action.payload, loading: false };
    case CHANGE_TAXES_PAGER:
      return { ...state, perPage: action.payload };
    case CHANGE_TAXES_PAGE:
      return { ...state, page: action.payload };
    default:
      return state;
  }
};

export default taxes;
