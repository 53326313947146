import { Button, Grid } from '@material-ui/core';
import React, { Component } from 'react';
import ReactSignatureCanvas from 'react-signature-canvas';

class SignaturePad extends Component {
  state = { sign: null, isSign: false };
  sigPad = {};
  clear = () => {
    this.setState({ isSign: false });
    this.sigPad.clear();
  };
  trim = () => {
    this.setState(
      {
        sign: this.state.isSign
          ? this.sigPad.getTrimmedCanvas().toDataURL('image/png')
          : ''
      },
      () => this.props.submitQuote(this.state.sign)
    );
  };
  render() {
    return (
      <>
        <ReactSignatureCanvas
          onBegin={() => this.setState({ isSign: true })}
          canvasProps={{ width: 580, height: '100%', className: 'sigCanvas' }}
          ref={ref => {
            this.sigPad = ref;
          }}
        />
        <Grid container justify='space-between'>
          <Button className='mr5' onClick={this.clear}>
            Clear
          </Button>
          <Grid item>
            <Button className='mr5' onClick={this.trim}>
              Submit
            </Button>
            <Button className='' onClick={this.props.closeHandle}>
              Close
            </Button>
          </Grid>
        </Grid>
      </>
    );
  }
}

export default SignaturePad;
