import React from 'react';
import { Grid, Divider } from '@material-ui/core';


const SubTasksTopbar = ({ subTaskName }: { subTaskName: string }) => {
    return (
        <>
            <Grid
                container
                className='ml10'
                justify='space-between'
                alignItems='flex-end'
                sm={12}
            >
                <h2 >{subTaskName}</h2>
            </Grid>
            <Divider className='mb10' />
        </>
    );
};

export default React.memo(SubTasksTopbar);
