import {
  GET_WORK_LOGS,
  DELETE_WORK_LOG,
  CHANGE_WORK_LOGS_PAGER,
  CHANGE_WORK_LOGS_PAGE,
  GET_REPORT,
  EXPORT_WORKLOGS
} from 'constants/index';
import { apiService } from 'middleware/api';
import { FORM_EDIT_DATA } from 'actions';

export const onGetWorkLogs = (
  startDate = '',
  endDate = '',
  page = 1,
  perPage = 10,
  keyword = '',
  taskId = '',
  projectId = '',
  companyId = '',
  viewList?: boolean
) => {
  const endPoint = `time_logs?page=${page}&per_page=${perPage}&keyword=${keyword}&task_id=${taskId}
  &start_date=${startDate}&end_date=${endDate}&project_id=${projectId}&company_id=${companyId}&viewLogs${
    viewList ? `&view_list=${viewList}` : ``
  }`;
  const method = 'get';
  return apiService(endPoint, method, GET_WORK_LOGS);
};

export const onGetWorkLog = (id: string) => {
  const endPoint = `time_logs/${id}.json`;
  const method = 'get';
  return apiService(endPoint, method, FORM_EDIT_DATA);
};

export const onDeleteWorkLog = (id: string) => {
  const endPoint = `time_logs/${id}.json`;
  const method = 'delete';
  return apiService(endPoint, method, DELETE_WORK_LOG);
};

export const onChangeWorkLogsPerPage = (perPage: number) => ({
  type: CHANGE_WORK_LOGS_PAGER,
  payload: perPage
});

export const onChangeWorkLogsPage = (page: number) => ({
  type: CHANGE_WORK_LOGS_PAGE,
  payload: page
});

export const onGetReport = (projectId: number | undefined) => {
  const endPoint = `projects/${projectId ?? ''}/generate_csv`;
  const method = `get`;
  return apiService(endPoint, method, GET_REPORT);
};
type dateType = string | Date | null;
export const onExportWorklogs = (
  projectId: number | undefined,
  startDate: dateType,
  endDate: dateType
) => {
  const endPoint = `projects/${
    projectId ?? ''
  }/worklog_export?start_date=${startDate}&end_date=${endDate}`;
  const method = `get`;
  return apiService(endPoint, method, EXPORT_WORKLOGS);
};
