import * as Yup from 'yup';

export const signupValidation = Yup.object({
  email: Yup.string().required().label('Email Address'),
  first_name: Yup.string().required().label('First Name'),
  last_name: Yup.string().required().label('Last Name'),
  password: Yup.string().required().min(6).label('Password'),
  password_confirmation: Yup.string()
    .required()
    .min(6)
    .oneOf([Yup.ref('password')], 'The both passwords do not match')
    .label('Password Confirmation')
});
