import {
  GET_TIME_CARDS,
  GET_TIME_CARDS_SUCCESS,
  GET_TIME_CARDS_FAIL,
  GET_PROJECTS_LIST,
  GET_PROJECTS_LIST_SUCCESS,
  GET_PROJECTS_LIST_FAIL,
  GET_TASKS_LIST,
  GET_TASKS_LIST_SUCCESS,
  GET_TASKS_LIST_FAIL,
  ADDING_TIME_CARD,
  ADDING_TIME_CARD_SUCCESS,
  ADDING_TIME_CARD_FAIL,
  UPDATE_TIME_CARD,
  UPDATE_TIME_CARD_SUCCESS,
  UPDATE_TIME_CARD_FAIL,
  CLEAR_MESSAGE_ERROR,
  ADD_TASK,
  ADD_TASK_SUCCESS,
  ADD_TASK_FAIL,
  GET_SERVICES_LIST,
  GET_SERVICES_LIST_SUCCESS,
  GET_SERVICES_LIST_FAIL,
  CLEAR_TASK_ID
} from '../constants';

const initial = {
  loading: false,
  data: [],
  page: 1,
  perPage: 10,
  count: 1,
  error: '',
  projectsList: [],
  tasksList: [],
  message: '',
  afterAddedCard: false,
  projectlistloading: false,
  timeUniqueDuration: null,
  afterCreatedNewTimeCard: null,
  newTaskId: null,
  servicesList: [],
  TaskName: '',
  ServiceName: ''
};

const timeCard = (state = initial, action: { type: string; payload: any }) => {
  switch (action.type) {
    case GET_TIME_CARDS:
      return { ...state, projectlistloading: true };
    case GET_TIME_CARDS_SUCCESS:
      return { ...state, projectlistloading: false, data: action.payload.data };
    case GET_TIME_CARDS_FAIL:
      return { ...state, projectlistloading: false, error: action.payload };
    case GET_PROJECTS_LIST:
      return { ...state, loading: true };
    case GET_PROJECTS_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        projectsList: action.payload.data
      };
    case GET_PROJECTS_LIST_FAIL:
      return { ...state, loading: false, error: action.payload };
    case GET_TASKS_LIST:
      return { ...state, loading: true };
    case GET_TASKS_LIST_SUCCESS:
      return { ...state, loading: false, tasksList: action.payload.data };
    case GET_TASKS_LIST_FAIL:
      return { ...state, loading: false, error: action.payload };

    case ADDING_TIME_CARD:
      return { ...state, loading: true };
    case ADDING_TIME_CARD_SUCCESS:
      return {
        ...state,
        loading: false,
        message: action.payload.message,
        afterAddedCard: true,
        afterCreatedNewTimeCard: action.payload.data.id,
        TaskName: ''
      };
    case ADDING_TIME_CARD_FAIL:
      return { ...state, loading: false, error: action.payload };
    case UPDATE_TIME_CARD:
      return { ...state, projectlistloading: true };
    case UPDATE_TIME_CARD_SUCCESS:
      return {
        ...state,
        loading: false,
        message: action.payload.message,
        timeUniqueDuration: action.payload.data.duration
      };
    case UPDATE_TIME_CARD_FAIL:
      return { ...state, loading: false, error: action.payload };
    case CLEAR_MESSAGE_ERROR:
      return { ...state, message: '', error: '' };

    case ADD_TASK:
      return { ...state, loading: true };
    case ADD_TASK_SUCCESS:
      return {
        ...state,
        newTaskId: action.payload.data.id,
        TaskName: action.payload.data.title,
        ServiceName: action.payload.data.task_service.name,
        message: action.payload.message,
        loading: false
      };
    case ADD_TASK_FAIL:
      return { ...state, loading: false };

    case GET_SERVICES_LIST:
      return { ...state, loading: true };
    case GET_SERVICES_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        servicesList: action.payload.data
      };
    case GET_SERVICES_LIST_FAIL:
      return { ...state };
    case CLEAR_TASK_ID:
      return { ...state, newTaskId: null };
    default:
      return state;
  }
};

export default timeCard;
