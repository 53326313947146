import {
  DELETE_REMINDERS,
  GET_REMINDERS,
  CHANGE_REMINDERS_PAGER,
  CHANGE_REMINDERS_PAGE
} from '../constants';

import { apiService } from 'middleware/api';
import { FORM_EDIT_DATA } from 'actions';

export const onGetReminders = (page = 1, perPage = 10, keyword = '') => {
  const endPoint = `reminders.json?page=${page}&per_page=${perPage}&keyword=${keyword}`;
  const method = 'get';
  return apiService(endPoint, method, GET_REMINDERS);
};

export const onGetReminder = (key: string) => {
  const endPoint = `reminders/${key}.json`;
  const method = 'get';
  return apiService(endPoint, method, FORM_EDIT_DATA);
};

export const onDeleteReminders = (key: string) => {
  const endPoint = `reminders/${key}.json`;
  const method = 'delete';
  return apiService(endPoint, method, DELETE_REMINDERS);
};

export const onChangeRemindersPerPage = (perPage: number) => ({
  type: CHANGE_REMINDERS_PAGER,
  payload: perPage
});

export const onChangeReminderPage = (page: number) => ({
  type: CHANGE_REMINDERS_PAGE,
  payload: page
});
