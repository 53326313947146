import CustomNotification from 'app/Common/CustomNotification';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { onAcceptOrganizationRequest, onInvitedUser, onRejectOrganizationRequest } from 'actions';
import { AuthProps } from 'types';

const AcceptOrganizationMsg = () => {
  const [orgToken, setOrgToken] = useState<null | string>(localStorage.getItem('acceptOrgToken')?.toString() ?? null);
  const { isInvitedUser, loading } = useSelector(({ auth }: AuthProps) => auth);
  const dispatch = useDispatch();

  useEffect(() => {
    if (orgToken) {
      dispatch(onInvitedUser(orgToken))
    }
  }, [orgToken, dispatch])

  const handleOrganizationReq = (onAcceptOrRejectOrg: (dispatch: any) => Promise<void>) => {
    dispatch(onAcceptOrRejectOrg)
    setOrgToken(null);
    localStorage.removeItem('acceptOrgToken');
  }
  if (!orgToken || loading || isInvitedUser) return null;
  return (
    <CustomNotification
      type='info'
      onOk={() => {
        handleOrganizationReq(onAcceptOrganizationRequest(orgToken))
      }}
      onCancel={() => {
        handleOrganizationReq(onRejectOrganizationRequest(orgToken))
      }}
      cancelText='ignore'
      okText='accept'
      description='You have new organization request.'
    />
  );
};

export default React.memo(AcceptOrganizationMsg);
