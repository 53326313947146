export const GET_INVITES = 'GET_INVITES';
export const GET_INVITES_SUCCESS = 'GET_INVITES_SUCCESS';
export const GET_INVITES_FAIL = 'GET_INVITES_FAIL';

export const DELETE_INVITE = 'DELETE_INVITE';
export const DELETE_INVITE_SUCCESS = 'DELETE_INVITE_SUCCESS';
export const DELETE_INVITE_FAIL = 'DELETE_INVITE_FAIL';

export const CHANGE_INVITES_PAGER = 'CHANGE_INVITES_PAGER';
export const CHANGE_INVITES_PAGE = 'CHANGE_INVITES_PAGE';

export const RE_INVITE = 'RE_INVITE';
export const RE_INVITE_SUCCESS = 'RE_INVITE_SUCCESS';
export const RE_INVITE_FAIL = 'RE_INVITE_FAIL';

export const GET_PENDING_INVITES = 'GET_PENDING_INVITES';
export const GET_PENDING_INVITES_SUCCESS = 'GET_PENDING_INVITES_SUCCESS';
export const GET_PENDING_INVITES_FAIL = 'GET_PENDING_INVITES_FAIL';

export const GET_INVITE_APPROVAL = 'GET_INVITE_APPROVAL';
export const GET_INVITE_APPROVAL_SUCCESS = 'GET_INVITE_APPROVAL_SUCCESS';
export const GET_INVITE_APPROVAL_FAIL = 'GET_INVITE_APPROVAL_FAIL';

export const GET_INVITE_REJECT_APPROVAL = 'GET_INVITE_REJECT_APPROVAL';
export const GET_INVITE_REJECT_APPROVAL_SUCCESS =
  'GET_INVITE_REJECT_APPROVAL_SUCCESS';
export const GET_INVITE_REJECT_APPROVAL_FAIL =
  'GET_INVITE_REJECT_APPROVAL_FAIL';

export const GET_INVITE_ACCEPT_APPROVAL = 'GET_INVITE_ACCEPT_APPROVAL';
export const GET_INVITE_ACCEPT_APPROVAL_SUCCESS =
  'GET_INVITE_ACCEPT_APPROVAL_SUCCESS';
export const GET_INVITE_ACCEPT_APPROVAL_FAIL =
  'GET_INVITE_ACCEPT_APPROVAL_FAIL';

export const SEND_MS_TOKEN = 'SEND_MS_TOKEN';
export const SEND_MS_TOKEN_SUCCESS = 'SEND_MS_TOKEN_SUCCESS';
export const SEND_MS_TOKEN_FAIL = 'SEND_MS_TOKEN_FAIL';

export const DISCONNECT_MS_TOKEN = 'DISCONNECT_MS_TOKEN';
export const DISCONNECT_MS_TOKEN_SUCCESS = 'DISCONNECT_MS_TOKEN_SUCCESS';
export const DISCONNECT_MS_TOKEN_FAIL = 'DISCONNECT_MS_TOKEN_FAIL';
