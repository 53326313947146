import {
  FORM_SUBMIT,
  FORM_SUBMIT_SUCCESS,
  FORM_SUBMIT_FAIL,
  CLEAR_FORM
} from '../../actions/Hooks/form';

const initial = {
  loading: false,
  status: '',
  message: '',
  data: {}
};

const formReducer = (state = initial, action: any) => {
  switch (action.type) {
    case FORM_SUBMIT:
      return { ...state, loading: true };
    case FORM_SUBMIT_SUCCESS:
      return {
        ...state,
        loading: false,
        status: 'success',
        message: action.payload.message || 'Success',
        data: action.payload?.data
      };
    case FORM_SUBMIT_FAIL:
      return {
        ...state,
        loading: false,
        status: 'error',
        message: action.payload
      };
    case 'CLEAR_ERROR':
      return { ...state, loading: false, message: '', status: '' };
    case CLEAR_FORM:
      return { ...state, loading: false, message: '', status: '', data: {} };
    default:
      return state;
  }
};

export default formReducer;
