import React from 'react';
import { LinearProgress, Typography, Hidden } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { useFormik } from 'formik';
import FilterVintageIcon from '@material-ui/icons/FilterVintage';

import AuthFormsButton from './AuthFormsButton';
import Footer from './Footer';
import CustomAlertMsg from 'app/Common/CustomAlertMsg';
import InputField from 'app/Common/InputFields';
import { signupValidation } from 'validate/auth/signupValidation';
import { signupFields } from 'assets/static/forms/auth/signupFields';
import { useStyles } from '../styles';
import { SignupFormProps } from '../types';

const SignupForm = ({
  signupHandle,
  loading,
  error,
  message,
  clearMsgHandler
}: SignupFormProps) => {
  const classes = useStyles();
  const { push } = useHistory();
  const formik = useFormik({
    initialValues: {
      email: '',
      first_name: '',
      last_name: '',
      password: '',
      password_confirmation: ''
    },
    validationSchema: signupValidation,
    onSubmit: values => signupHandle(values)
  });

  return (
    <div className={classes.container}>
      <Hidden mdUp>
        <Typography variant='h4' component='h2' gutterBottom align='center'>
          Next Level Technologies
        </Typography>
      </Hidden>
      <CustomAlertMsg
        type={error.length ? 'error' : 'success'}
        message={error || message}
        onClose={() => {
          clearMsgHandler();
          message.length && push('/');
        }}
      />
      <form
        onSubmit={formik.handleSubmit}
        className={`${classes.form} ${classes.signupForm}`}
      >
        {loading && <LinearProgress />}
        <FilterVintageIcon
          fontSize='large'
          className={`${classes.icon}  ${classes.signupIcon}`}
        />
        {signupFields.map(({ value, label, type }, index) => (
          <InputField
            key={index}
            formik={formik}
            label={label}
            gridSize={12}
            value={value}
            className={classes.fields}
            autoComplete='new'
            InputProps={{ className: classes.input }}
            type={type ?? 'text'}
          />
        ))}
        <div className={classes.authFormBottom}>
          <AuthFormsButton loading={loading} text='Signup' />
          <Typography
            color='error'
            gutterBottom
            align='center'
            className={classes.trial}
          >
            {' '}
            14 days free trial.{' '}
          </Typography>
          <Footer formType='signup' />
        </div>
      </form>
    </div>
  );
};

export default React.memo(SignupForm);
