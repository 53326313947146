import React from 'react';
import { Grid, makeStyles } from '@material-ui/core';

import { DialogBodyProps } from '../types/customDialog';

const DialogBody = ({ children }: DialogBodyProps) => {
  const classes = useStyles();
  return (
    <Grid container className={classes.body}>
      {children}
    </Grid>
  );
};

export default React.memo(DialogBody);

const useStyles = makeStyles(() => ({
  body: { padding: 10, overflowY: 'auto' }
}));
