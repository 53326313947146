import React from 'react';
import { Button } from '@material-ui/core';

import { useStyles } from '../styles';

const AuthFormsButton = ({ loading = false, text = '' }) => {
  const classes = useStyles();
  return (
    <Button
      type='submit'
      color='primary'
      variant='contained'
      className={classes.authBtn}
      disabled={loading}
    >
      {text}
    </Button>
  );
};

export default React.memo(AuthFormsButton);
