import { apiService } from 'middleware/api';
import { DELETE_CHILD_TASK } from '../constants.ts';

export const onDeleteChildTask = (
  subTaskId: string,
  data: { task: { remove_parent: true } }
) => {
  const endPoint = `tasks/${subTaskId}`;
  const method = 'put';
  return apiService(endPoint, method, DELETE_CHILD_TASK, data);
};

export const onAddChildTask = (
  taskId: string,
  data: { task: { parent_task_id: string | number } }
) => {
  const endPoint = `tasks/${taskId}`;
  const method = 'put';
  return apiService(endPoint, method, DELETE_CHILD_TASK, data);
};
