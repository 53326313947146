import React, { useEffect, useState } from 'react';
import { Grid, InputAdornment, Paper, TextField } from '@material-ui/core';

import { useStyles } from '../../styles';
import { useForm } from 'app/Common/Hooks/useForm';
import { QuoteTotalsProps } from '../../types';
import AppliedTaxes from './AppliedTaxes';
const QuoteTotals = ({
  total,
  subTotal,
  quoteShipping,
  reloadQuote,
  quoteTaxes
}: QuoteTotalsProps) => {
  const classes = useStyles();
  const [shipping, setShipping] = useState(quoteShipping || 0);
  const formHook = useForm();

  useEffect(() => {
    if (formHook.message) {
      formHook.onClear();
      reloadQuote();
    }
  }, [formHook, reloadQuote]);

  return (
    <Grid item md={3} sm={6}>
      <Paper className='p10'>
        <TextField
          InputProps={{
            startAdornment: <InputAdornment position='start'>$</InputAdornment>
          }}
          label='Subtotal'
          value={subTotal}
          disabled
          fullWidth
          className='mb5'
        />
        <TextField
          InputProps={{
            inputProps: { min: 0 },
            startAdornment: <InputAdornment position='start'>$</InputAdornment>
          }}
          label='Shipping'
          value={shipping}
          onChange={e => setShipping(Number(e.target.value))}
          type='number'
          fullWidth
          className='mb5 mt5'
          disabled
        />
        <AppliedTaxes quoteTaxes={quoteTaxes} />
        <Grid className={classes.total}>
          Total: <span className={classes.totalCount}>${total.toFixed(2)}</span>
        </Grid>
      </Paper>
    </Grid>
  );
};

export default React.memo(QuoteTotals);
