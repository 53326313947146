import {
  GET_PROJECT_STATUSES,
  GET_PROJECT_STATUSES_FAIL,
  GET_PROJECT_STATUSES_SUCCESS,
  DELETE_PROJECT_STATUS,
  DELETE_PROJECT_STATUS_FAIL,
  DELETE_PROJECT_STATUS_SUCCESS,
  CHANGE_PROJECT_STATUSES_PAGER,
  CHANGE_PROJECT_STATUSES_PAGE
} from 'constants/index';

const initial = {
  data: [],
  loading: false,
  error: '',
  message: '',
  page: 1,
  perPage: 10,
  count: 1
};

const projectStatus = (
  state = initial,
  action: { type: string; payload: any }
) => {
  switch (action.type) {
    case GET_PROJECT_STATUSES:
      return { ...state, loading: true };
    case GET_PROJECT_STATUSES_SUCCESS:
      return {
        ...state,
        data: action.payload.data,
        count: action.payload.meta_attributes
          ? action.payload.meta_attributes.total_count
          : 1,
        loading: false
      };
    case GET_PROJECT_STATUSES_FAIL:
      return { ...state, error: action.payload, loading: false };
    case 'CLEAR_ERROR':
      return { ...state, error: '', message: '' };
    case DELETE_PROJECT_STATUS:
      return { ...state, loading: true };
    case DELETE_PROJECT_STATUS_SUCCESS:
      return { ...state, message: action.payload.message, loading: false };
    case DELETE_PROJECT_STATUS_FAIL:
      return { ...state, error: action.payload, loading: false };
    case CHANGE_PROJECT_STATUSES_PAGER:
      return { ...state, perPage: action.payload };
    case CHANGE_PROJECT_STATUSES_PAGE:
      return { ...state, page: action.payload };
    default:
      return state;
  }
};

export default projectStatus;
