import {
  GET_CATEGORIES,
  DELETE_CATEGORY,
  CHANGE_CATEGORIES_PAGER,
  CHANGE_CATEGORIES_PAGE
} from 'constants/index';
import { apiService } from 'middleware/api';
import { FORM_EDIT_DATA } from 'actions';

export const onGetCategories = (page = 1, perPage = 10, keyword = '') => {
  const endPoint = `categories.json?page=${page}&per_page=${perPage}&keyword=${keyword}`;
  const method = 'get';
  return apiService(endPoint, method, GET_CATEGORIES);
};

export const onGetCategory = (key: string) => {
  const endPoint = `categories/${key}.json`;
  const method = 'get';
  return apiService(endPoint, method, FORM_EDIT_DATA);
};

export const onDeleteCategory = (key: string) => {
  const endPoint = `categories/${key}.json`;
  const method = 'delete';
  return apiService(endPoint, method, DELETE_CATEGORY);
};

export const onChangeCategoriesPerPage = (perPage: number) => ({
  type: CHANGE_CATEGORIES_PAGER,
  payload: perPage
});

export const onChangeCategoriesPage = (page: number) => ({
  type: CHANGE_CATEGORIES_PAGE,
  payload: page
});
