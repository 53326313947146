import { onClearEditForm } from 'actions';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { paramsToObject } from 'utils/common/paramsToObject';
import { DynamicObject } from '../types';

export function useEditForm(
  name: string,
  onLoadData: Function,
  onLoadCallBack: Function
) {
  const [editLoading, setEditLoading] = useState(false);
  const { pathname, search } = useLocation();
  const { edit: id, formname, ...searchObj } = paramsToObject(search);
  const { status, message, loading, data } = useSelector(
    ({ formData }: FormDataProps) => formData
  );
  const dispatch = useDispatch();
  const { push } = useHistory();
  const queryObj = { ...searchObj };
  const query = Object.keys(queryObj)
    .map(k => `${encodeURIComponent(k)}=${encodeURIComponent(queryObj[k])}`)
    .join('&');

  useEffect(() => {
    data.id && name === formname && onLoadCallBack();
  }, [data.id, onLoadCallBack, name, formname]);
  useEffect(() => {
    id && name === formname && dispatch(onLoadData(id));
  }, [id, dispatch, onLoadData, name, formname]);
  useEffect(() => {
    if (loading && !editLoading)
      setTimeout(() => setEditLoading(loading), 1000);
    else if (!loading && editLoading) setEditLoading(loading);
  }, [loading, editLoading]);
  const clearFormData = () => {
    dispatch(onClearEditForm());
    push(`${pathname}?${query}`);
  };

  return {
    id,
    status,
    message,
    editLoading,
    formData: data,
    clearFormData,
    name
  };
}

interface FormDataProps {
  formData: {
    data: DynamicObject;
    loading: boolean;
    status: 'error' | 'success';
    message: string;
  };
}
