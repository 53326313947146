import {
  DELETE_CYBERSECUTIRYFORM,
  GET_CYBERSECUTIRYFORM,
  GET_CYBERSECUTIRYFORM_SUCCESS,
  DELETE_CYBERSECUTIRYFORM_SUCCESS,
  DELETE_CYBERSECUTIRYFORM_FAIL,
  CHANGE_CYBERSECUTIRYFORM_PAGE,
  CHANGE_CYBERSECUTIRYFORM_PAGER,
  GET_CYBERSECUTIRYFORM_QUESTIONS_SUCCESS,
  GET_CYBERSECUTIRYFORM_QUESTIONS,
  GET_CYBERSECUTIRYFORM_QUESTIONS_FAIL,
  CREATE_FEEDBACK,
  CREATE_FEEDBACK_SUCCESS,
  CREATE_FEEDBACK_FAIL,
  GET_CYBERSECUTIRYFORM_LISTING,
  GET_CYBERSECUTIRYFORM_LISTING_SUCCESS
} from '../constants';

const initial = {
  data: [],
  loading: false,
  error: '',
  message: '',
  page: 1,
  perPage: 10,
  count: 1,
  createLoading: false,
  formData: {},
  viewFormLoading: false
};

const cyberSecurity = (
  state = initial,
  action: { type: string; payload: any }
) => {
  switch (action.type) {
    case GET_CYBERSECUTIRYFORM_LISTING:
      return { ...state, loading: true };
    case GET_CYBERSECUTIRYFORM_LISTING_SUCCESS:
      return {
        ...state,
        data: action.payload.data,
        count: action.payload.meta_attributes
          ? action.payload.meta_attributes.total_count
          : 1,
        message: '',
        loading: false
      };
    case DELETE_CYBERSECUTIRYFORM:
      return { ...state, loading: true };
    case DELETE_CYBERSECUTIRYFORM_SUCCESS:
      return { ...state, message: action.payload.message, loading: false };
    case DELETE_CYBERSECUTIRYFORM_FAIL:
      return { ...state, error: action.payload, loading: false };
    case CHANGE_CYBERSECUTIRYFORM_PAGER:
      return { ...state, perPage: action.payload };
    case CHANGE_CYBERSECUTIRYFORM_PAGE:
      return { ...state, page: action.payload };
    case GET_CYBERSECUTIRYFORM_QUESTIONS:
      return { ...state, loading: true };
    case GET_CYBERSECUTIRYFORM_QUESTIONS_SUCCESS:
      return {
        ...state,
        data: action.payload.data,
        count: action.payload.meta_attributes
          ? action.payload.meta_attributes.total_count
          : 1,
        message: '',
        loading: false
      };
    case GET_CYBERSECUTIRYFORM_QUESTIONS_FAIL:
      return { ...state, error: action.payload, loading: false };
    case CREATE_FEEDBACK:
      return { ...state, createLoading: true };
    case CREATE_FEEDBACK_SUCCESS:
      return {
        ...state,
        data: action.payload.data,
        message: action.payload.message,
        createLoading: false
      };
    case CREATE_FEEDBACK_FAIL:
      return { ...state, error: action.payload, createLoading: false };
    case GET_CYBERSECUTIRYFORM:
      return { ...state, viewFormLoading: true };
    case GET_CYBERSECUTIRYFORM_SUCCESS:
      return {
        ...state,
        formData: action.payload.data,
        count: action.payload.meta_attributes
          ? action.payload.meta_attributes.total_count
          : 1,
        message: '',
        viewFormLoading: false
      };
    default:
      return state;
  }
};

export default cyberSecurity;
