import React from 'react';
import { Button, Grid } from '@material-ui/core';
import { Add } from '@material-ui/icons';

import ListSearcher from 'app/Common/ListSearcher';
import { TemplateTasksTopbarProps } from '../types';
import AppBreadCrumbs from 'app/Common/AppBreadCrumbs';

const ProjectTemplatesTopbar = ({
    loading,
    setForm,
    setSearchText
}: TemplateTasksTopbarProps) => {
    return (
        <>
            <Grid className='mt10 mb10'>
                <AppBreadCrumbs
                    loading={loading}
                    paths={[{ link: '/admin-setup/NQ==', name: 'Templates' }]}
                    current='Template Details'
                />
            </Grid>
            <Grid
                container
                className='mt10 mb10 pr5 pl5'
                justify='space-between'
                alignItems='flex-end'
            >
                <div>
                    <ListSearcher searchTextHandle={setSearchText} loading={loading} />
                </div>
                <Button
                    color='primary'
                    variant='contained'
                    disabled={loading}
                    onClick={() => setForm(true)}
                >
                    <Add /> New Template Task
      </Button>
            </Grid></>
    );
};

export default React.memo(ProjectTemplatesTopbar);
