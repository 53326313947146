import React from 'react';

import CustomTable from 'app/Common/CustomTable';
import Heading from 'app/Common/Heading';
import { DynamicObject } from 'app/Common/types';

const ListCollaspeView = ({
    items,
    loading
}: {
    items: Array<DynamicObject>;
    loading: boolean;
}) => (
    <>
        <Heading>Subscription Items</Heading>
        <CustomTable
            rows={items}
            headItems={['Product Code', 'Product Name', 'Quantity', 'Price $', 'Unit Price $', 'Manufacturer']}
            columns={['product.internal_code', 'product.name', 'quantity', 'product.cost', 'product.unit_price', 'product.manufacturer']}
            loading={loading}
            perPage={0}
            page={1}
            count={0}
        />
    </>
);

export default ListCollaspeView;
