export const GET_BUSINESS_EMPLOYEES = 'GET_BUSINESS_EMPLOYEES';
export const GET_BUSINESS_EMPLOYEES_SUCCESS = 'GET_BUSINESS_EMPLOYEES_SUCCESS';
export const GET_BUSINESS_EMPLOYEES_FAIL = 'GET_BUSINESS_EMPLOYEES_FAIL';
export const DELETE_EMPLOYEE = 'DELETE_EMPLOYEE';
export const DELETE_EMPLOYEE_SUCCESS = 'DELETE_EMPLOYEE_SUCCESS';
export const DELETE_EMPLOYEE_FAIL = 'DELETE_EMPLOYEE_FAIL';
export const CHANGE_EMPLOYEE_PAGE = 'CHANGE_EMPLOYEE_PAGE';
export const CHANGE_EMPLOYEE_PAGER = 'CHANGE_EMPLOYEE_PAGER';

export const GET_BUSINESSES = 'GET_BUSINESSES';
export const GET_BUSINESSES_SUCCESS = 'GET_BUSINESSES_SUCCESS';
export const GET_BUSINESSES_FAIL = 'GET_BUSINESSES_FAIL';
