import { makeStyles } from '@material-ui/core/styles';

const drawerWidth = 240;
export const useStyles = makeStyles(theme => ({
  root: { display: 'flex' },
  drawer: { width: drawerWidth, flexShrink: 0 },
  drawerPaper: {
    width: drawerWidth,
    border: 'none',
    boxShadow: `0 1px 5px 0 rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14),
        0 3px 1px -2px rgba(0, 0, 0, 0.12)`,
    backgroundColor: theme.palette.secondary.main
  },
  drawerHeader: {
    ...theme.mixins.toolbar,
    maxHeight: 64,
    overflow: 'hidden',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    background: theme.palette.primary.main
  }
}));
