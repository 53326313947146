import React from 'react';
import { useHistory } from 'react-router-dom';

import CustomTable from 'app/Common/CustomTable';
import { SubscriptionsListProps } from '../types';
import ListCollaspeView from './ListCollapseView';

const SubscriptionList = ({
  data,
  loading,
  page,
  perPage,
  count,
  changePerPageHandle,
  changePage
}: SubscriptionsListProps) => {
  const { push } = useHistory();
  const [collaspe, setCollaspe] = React.useState<number | undefined>();

  return (
    <>
      <CustomTable
        rows={data}
        headItems={['No.', 'Company', 'Prepared By', 'Last Updated']}
        columns={[
          'full_number',
          'company.name',
          'user.first_name+user.last_name',
          'date:updated_at'
        ]}
        loading={loading}
        perPage={perPage}
        page={page}
        count={count}
        isScroller
        changePageHandle={(e, page) => changePage(page)}
        changePerPageHandle={perPage => {
          changePage(1);
          changePerPageHandle(perPage);
        }}
        rowClickHandle={(e, { id }) => {
          push(`/subscription/${id}`);
        }}
        collaspe={collaspe}
        collaspeView={row => (
          <ListCollaspeView loading={loading} items={row.subscription_items} />
        )}
        setCollaspe={setCollaspe}
      />
    </>
  );
};

export default React.memo(SubscriptionList);
