import { FORM_EDIT_DATA } from 'actions';
import {
  CHANGE_TASKS_PAGE,
  CHANGE_TASKS_PAGER,
  CHANGE_TASK_STATUS,
  CLEAR_ALL_TASKS,
  CLEAR_LATEST_TASKS,
  CLEAR_TASK_DETAILS,
  CLEAR_USER_BOARD_TASKS,
  CLEAR_USER_LATEST_TASK,
  DELETE_TASK,
  GET_ASSIGNEE_TASKS,
  GET_CHILD_TASKS,
  GET_CHILD_TASKS_DETAILS,
  GET_TASKS,
  GET_TASK_DETAILS,
  GET_USER_BOARD_TASKS
} from 'constants/index';
import { apiService } from 'middleware/api';
import { TaskStatusChangeData } from '../../types';

export const onGetTasks = (
  projectId?: number,
  page = 1,
  perPage = 10,
  keyword = '',
  viewList?: boolean
) => {
  const endPoint = `tasks?page=${page}&per_page=${perPage}&keyword=${keyword}&${
    projectId ? `project_id=${projectId}` : ``
  }&${viewList ? `view_list=${viewList}` : ``}`;
  const method = 'get';
  return apiService(endPoint, method, GET_TASKS);
};
export const onGetCurrentUserTasks = (
  assigneeId: string,
  page = 1,
  perPage = 10,
  keyword = '',
  projectId?: number,
  taskStatus?: string,
  sortBy?: 'asc' | 'desc',
  statusId?: number
) => {
  const endPoint = `tasks?page=${page}&per_page=${perPage}&keyword=${keyword}&assignee_id=${assigneeId}${
    projectId ? `&project_id=${projectId}` : ''
  }${statusId ? `&task_status_id=${statusId}` : ''}${
    sortBy ? `&sort=${sortBy}` : ''
  }`;
  const method = 'get';
  return apiService(endPoint, method, GET_TASKS);
};

export const onGetAssigneeTasks = (assigneeId: string) => {
  const endPoint = `users/${assigneeId}/latest_tasks`;
  const method = 'get';
  return apiService(endPoint, method, GET_ASSIGNEE_TASKS);
};

export const onGetTask = (id: string) => {
  const endPoint = `tasks/${id}.json`;
  const method = 'get';
  return apiService(endPoint, method, FORM_EDIT_DATA);
};

export const onDeleteTask = (id: string) => {
  const endPoint = `tasks/${id}.json`;
  const method = 'delete';
  return apiService(endPoint, method, DELETE_TASK);
};

export const onChangeTasksPerPage = (perPage: number) => ({
  type: CHANGE_TASKS_PAGER,
  payload: perPage
});

export const onChangeTasksPage = (page: number) => ({
  type: CHANGE_TASKS_PAGE,
  payload: page
});
export const onChangeTaskStatus = (data: TaskStatusChangeData) => {
  const endPoint = `tasks/${data.id}.json`;
  const method = 'put';
  return apiService(endPoint, method, CHANGE_TASK_STATUS, data);
};

export const onGetTaskDetails = (id: number, isChildTask = false) => {
  const endPoint = `tasks/${id}.json`;
  const method = 'get';
  const reduxConstant = isChildTask
    ? GET_CHILD_TASKS_DETAILS
    : GET_TASK_DETAILS;
  return apiService(endPoint, method, reduxConstant);
};

export const onClearTaskDetails = () => ({ type: CLEAR_TASK_DETAILS });

export const onGetChildTasks = (
  page = 1,
  perPage = 10,
  keyword = '',
  taskId = '',
  projectId = ''
) => {
  const endPoint = `tasks?project_id=${projectId}&task_id=${taskId}&page=${page}&per_page=${perPage}`;
  const method = 'get';
  return apiService(endPoint, method, GET_CHILD_TASKS);
};

export const onGetCurrentUserBoardTasks = (
  assigneeId: number,
  page = 1,
  perPage = 10,
  keyword = '',
  projectId?: number,
  taskStatus?: string,
  sortBy?: 'asc' | 'desc'
) => {
  const endPoint = `tasks?page=${page}&per_page=${perPage}&keyword=${keyword}&assignee_id=${assigneeId}${
    projectId ? `&project_id=${projectId}` : ''
  }${taskStatus ? `&status=${taskStatus}` : ''}${
    sortBy ? `&sort=${sortBy}` : ''
  }`;
  const method = 'get';
  return apiService(endPoint, method, GET_USER_BOARD_TASKS);
};

export const onClearBoardTasks = () => ({ type: CLEAR_ALL_TASKS });
export const onClearLatestTasks = () => ({ type: CLEAR_LATEST_TASKS });
export const onClearUserBoardTasks = (userId: number) => ({
  type: CLEAR_USER_BOARD_TASKS,
  payload: userId
});

export const onClearUserLatestTasks = (userId: number) => ({
  type: CLEAR_USER_LATEST_TASK,
  payload: userId
});
